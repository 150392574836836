import React from 'react';
import { Switch, Redirect } from 'react-router';
import PrivateRoute from '../PrivateRoute';
import Home from './Home';
import PatientList from './crud/patients/PatientList';
import PatientEdit from './crud/patients/PatientEdit';
import Patient from './crud/patients/Patient';
import ClientList from './crud/clients/ClientList';
import UserList from './crud/security/UserList';
import User from './crud/security/User';
import EquipmentList from './crud/product/EquipmentList';
import Equipment from './crud/product/Equipment';
import EquipmentTypeList from './crud/product/EquipmentTypeList';
import ContractList from './crud/manager/ContractList';
import Contract from './crud/manager/Contract';
import TherapyList from './crud/therapies/TherapyList';
import Therapy from './crud/therapies/Therapy';
import TherapyReport from './crud/reports/TherapyReport';
import ExamList from './crud/exams/ExamList';
import Exam from './crud/exams/Exam';
import ExamReport from './crud/reports/ExamReport';
import ProtocoloList from './crud/protocolos/ProtocoloList';
import Protocolo from './crud/protocolos/Protocolo';
import Financial from './crud/manager/Financial';
import Logs from './crud/manager/LogsList';
import Reports from './crud/manager/Reports';
import ReportsList from './crud/reports/ReportsList';
import UserProfile from './crud/security/UserProfile';
import Statistics from './crud/product/Statistics';
import DistributorList from './crud/distributor/DistributorList';
import Distributor from './crud/distributor/Distributor';
import Scheduling from './crud/scheduling/Scheduling';
import ExternalAPIs from './crud/security/ExternalAPIs';

const MainRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/paciente/novo" component={PatientEdit} />
      <PrivateRoute path="/paciente/edit/:id" component={PatientEdit} />
      <PrivateRoute path="/pacientes" component={PatientList} />
      <PrivateRoute path="/paciente/:id" component={Patient} />
      <PrivateRoute path="/terapias" component={TherapyList} />
      <PrivateRoute path="/terapia/:id" component={Therapy} />
      <PrivateRoute path="/relatorio/terapia/:id" component={TherapyReport} />
      <PrivateRoute path="/diagnosticos" component={ExamList} />
      <PrivateRoute path="/diagnostico/:id" component={Exam} />
      <PrivateRoute path="/protocolos" component={ProtocoloList} />
      <PrivateRoute path="/protocolo/:id" component={Protocolo} />
      <PrivateRoute path="/relatorio/diagnostico/:id" component={ExamReport} />
      <PrivateRoute path="/logs" component={Logs} />
      <PrivateRoute path="/clientes" component={ClientList} />
      <PrivateRoute path="/financeiro" component={Financial} />
      <PrivateRoute path="/usuarios" component={UserList} />
      <PrivateRoute path="/usuario/:id" component={User} />
      <PrivateRoute path="/perfil" component={UserProfile} />
      <PrivateRoute path="/equipamentos-tipo" component={EquipmentTypeList} />
      <PrivateRoute path="/equipamento/:id" component={Equipment} />
      <PrivateRoute path="/equipamentos" component={EquipmentList} />
      <PrivateRoute path="/contratos" component={ContractList} />
      <PrivateRoute path="/contrato/:id" component={Contract} />
      <PrivateRoute path="/relatorios" component={Reports} />
      <PrivateRoute path="/relatorios-horizontais" component={ReportsList} />
      <PrivateRoute path="/distribuidores" component={DistributorList} />
      <PrivateRoute path="/distribuidor/:id" component={Distributor} />
      <PrivateRoute path="/estatisticas/:screen/:id" component={Statistics} />
      <PrivateRoute path="/agendamento" component={Scheduling} />
      <PrivateRoute path="/apis" component={ExternalAPIs} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};
export default MainRoutes;
