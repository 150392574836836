import React from "react";
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PRIMARY_COLOR } from "../../../constants";
import CompanionService from '../../../services/security/CompanionService';

const styles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },
  textField: {
    margin: '4px 0px'
  },
});

class RemoveCompanionContent extends React.Component {
  constructor() {
    super();

    this.state = {
      removeConfirmation: '',
      saving: false,
    }
  }

  async handleRemoveCompanion() {
    const { removeConfirmation } = this.state;
    const {
      companionToEdit,
      patientData,
      getCompanions,
      handleCloseDialog,
      onRemoveCompanion,
    } = this.props;

    this.setState({ saving: true });

    try {
      if (removeConfirmation.toUpperCase() === 'EXCLUIR') {
        await CompanionService.remove(companionToEdit.id);
        if (patientData && patientData.id) {
          await CompanionService.deleteCompanionPatientRelation(companionToEdit.id, patientData.id)
        }

        this.props.enqueueSnackbar('Acompanhante excluido com sucesso!', { variant: 'success' });
      } else if (removeConfirmation.toUpperCase() === 'DESASSOCIAR') {
        if (patientData && patientData.id) {
          await CompanionService.deleteCompanionPatientRelation(companionToEdit.id, patientData.id)
        }

        this.props.enqueueSnackbar('Acompanhante desassociado com sucesso!', { variant: 'success' });
      } else {
        this.props.enqueueSnackbar('Confirmação inválida!', { variant: 'info' });
        return;
      }

      onRemoveCompanion(companionToEdit);
      getCompanions();
      handleCloseDialog();

      this.setState({ removeConfirmation: '' });
    } catch (error) {
      console.error(error);
      this.props.enqueueSnackbar('Falha ao remover acompanhante!', { variant: 'error' });
    } finally {
      this.setState({ saving: false });
    }
  }

  render() {
    const {
      classes,
      companionToEdit,
      patientData,
      handleCancel,
    } = this.props;

    const { removeConfirmation, saving } = this.state;

    return (
      <>
        <Typography variant="h6" style={{ textAlign: 'center' }} >
          Remover Acompanhante
        </Typography>

        <Typography variant="body2" >
          Confirme a ação que deseja efetuar sobre o acompanhante <b>{companionToEdit.name}</b> do paciente <b>{patientData.name}</b> :
          <br />
          <br />

          - <b>DESASSOCIAR</b>: O acompanhante continua a existir no sistema, mas não será mais associado ao paciente;
          <br />
          - <b>EXCLUIR</b>: Permanentemente excluir o acompanhante do sistema, e desassociar o acompanhante de todos os pacientes do sistema;

          <br />
          <br />
        </Typography>

        <Typography variant="body2" >
          Digite no campo abaixo a ação desejada.
        </Typography>

        <TextField
          id="model-remove"
          label="Confirmação da Ação"
          className={classes.textField}
          value={removeConfirmation || ''}
          onChange={(event) => this.setState({ removeConfirmation: event.target.value })}
          helperText="Digite DESASSOCIAR ou EXCLUIR"
        />

        <div className={classes.buttonContainer}  >
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: '16px' }}
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={saving}
            onClick={() => this.handleRemoveCompanion()}
          >
            Salvar
            {saving ? (
              <CircularProgress
                size={24}
                style={{
                  color: PRIMARY_COLOR,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            ) : null}
          </Button>
        </div>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withSnackbar(RemoveCompanionContent));

