import React from "react";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import DialogContent from "@material-ui/core/DialogContent";

import EditIcon from '@material-ui/icons/Edit';
import { PhonelinkErase } from '@material-ui/icons'
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Dialog } from "@material-ui/core";

import SchedulingDetails from "./SchedulingDetails";
import SchedulingMarkOff from "./SchedulingMarkOff";

import AuthService from '../../../services/AuthService';

const styles = (theme) => ({
  optionsButton: {
    borderRadius: '16px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px'
  },
  icon: {
    fontSize: '48px',
    marginBottom: '16px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
  },
  companionListButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },
  textField: {
    margin: '4px 0px'
  },
});

class SelectOptionScheduling extends React.Component {
  constructor() {
    super();
    this.state = {
      typeDialog: null
    }

    this.role = AuthService.getRole()
  }
  handleCloseDialog = () =>{
    this.props.onCloseCallBack()
  }
  setOpenDialog = () => {
    this.props.onSetOpenDialog(0)
  }
  handleButtonClick = (type) => {
    switch(type){
      case 'details': 
        this.setState({typeDialog: 1}) 
        break;
      case 'edit':
        this.setState({typeDialog: 2})
        this.setOpenDialog()
        break;
      case 'markOff':
        this.setState({typeDialog: 3}) 
        break;
      default:
        break;
    }
  }
  handleCloseDialogContent = () => {
    this.setState({typeDialog: 0})
  }

  handleMarkOff = () => {
    this.props.onMarkOff()
  }


  render() {
    const { classes} = this.props;

    const getDialogContentFromType = () => {
      switch(this.state.typeDialog){
        case 1:
          return <SchedulingDetails isOpen={this.state.typeDialog === 1 ? true : false}
          handleClose = {this.handleCloseDialogContent}
          id = {this.props.id}/>;
        case 3:
          return <SchedulingMarkOff isOpen={this.state.typeDialog === 3 ? true : false}
          handleClose = {this.handleCloseDialogContent} 
          handleMarkOff = {this.handleMarkOff}
          id = {this.props.id}/>;
        default:
          return '';
        }
    }

    return (
      <>
        <Dialog fullScreen={false}
          open={this.props.isOpen}
          onClose={() => this.handleCloseDialog()}>
          <DialogContent className={classes.container} >
          <Grid container>
            {this.role !== 'P' && this.role!=='AC' && (<Grid item xs={6}>
              <CardActionArea component={Button}>
                <Card className={classes.card} onClick={() => this.handleButtonClick('edit')}>
                  <EditIcon color="primary" className={classes.icon} />
                  <Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
                    Editar Agendamento
                  </Typography>
                </Card>
              </CardActionArea>
            </Grid>)}
            {this.role !== 'P' && this.role!=='AC' && ( <Grid item xs={6}>
              <CardActionArea component={Button} >
                <Card className={classes.card} onClick={() => this.handleButtonClick('markOff')}>
                  <PhonelinkErase color="primary" className={classes.icon} />
                  <Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
                    Desmarcar Agendamento
                  </Typography>
                </Card>
              </CardActionArea>
            </Grid>)}
            <Grid item xs= {this.role !== 'P' && this.role!=='AC' ? 6 : 12}>
              <CardActionArea component={Button}>
                <Card className={classes.card} onClick={() => this.handleButtonClick('details')}>
                  <ListAltIcon color="primary" className={classes.icon} />
                  <Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
                    Ver detalhes do Agendamento
                  </Typography>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>       
          </DialogContent>
        </Dialog>
        {getDialogContentFromType()}
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(SelectOptionScheduling);

