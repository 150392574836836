import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import ContractService from '../../../services/manager/ContractService';
import { withStyles } from '@material-ui/core/styles';
import ContactsIcon from '@material-ui/icons/Contacts';
import AuthService from '../../../services/AuthService';
import { formatTableDate } from '../../../helpers/formatters';
import ContractExcelService from '../../../services/excel/ContractExcelService';

const styles = (theme) => ({});

class ContractList extends ListBase {
  options = {
    description: 'Contrato',
    descriptionPlural: 'Contratos',
    icon: ContactsIcon,
    module: 'contratos',
    edit: 'manager/ContractEdit',
    service: ContractService,
    details: '/contrato',
    addColumnAction: true,
    hasNew: AuthService.getRole() === 'V' || AuthService.getRole() === 'D',
    hasDetails: AuthService.getRole() === 'V',
    hasEdit: false,
    hasRemove: true,
    hasExcel: true,
    excelService: ContractExcelService,

    columns: [
      {
        label: 'N. Contrato',
        name: 'id',
        options: {
          display: true,
          sort: true,
        },
      },
      {
        label: 'Equipamento',
        name: 'serial',
        options: {
          display: true,
          sort: true,
        },
      },
      {
        label: 'Data',
        name: 'date',
        options: {
          sort: true,
          customBodyRender: (value) => formatTableDate(value, true),
        },
      },
      {
        label: 'Status',
        name: 'statusContract',
        options: {
          sort: true,
        },
      },
      {
        label: 'Cliente',
        name: 'clientName',
        options: {
          sort: true,
          display: true,
        },
      },
    ],
  };
}

ContractList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContractList);
