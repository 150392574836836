import React from "react";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HowToRegIcon from '@material-ui/icons/HowToReg';

import { SELECTED_DIALOG_TYPE } from "./CompanionEdit.";

const styles = (theme) => ({
  optionsButton: {
    borderRadius: '16px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px'
  },
  icon: {
    fontSize: '48px',
    marginBottom: '16px'
  }
});

class NoneSelectedContent extends React.Component {

  render() {
    const { classes, handleChangeSelected } = this.props;

    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            <CardActionArea component={Button} onClick={() => handleChangeSelected(SELECTED_DIALOG_TYPE.ADD_COMPANION)}>
              <Card className={classes.card}>
                <PersonAddIcon color="primary" className={classes.icon} />
                <Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
                  Cadastrar um novo acompanhante
                </Typography>
              </Card>
            </CardActionArea>
          </Grid>
          <Grid item xs={6}>
            <CardActionArea component={Button} onClick={() => handleChangeSelected(SELECTED_DIALOG_TYPE.LIST_COMPANIONS)}>
              <Card className={classes.card}>
                <HowToRegIcon color="primary" className={classes.icon} />
                <Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
                  Selecionar um acompanhante existente
                </Typography>
              </Card>
            </CardActionArea>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(NoneSelectedContent);

