import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import logo from './../../imgs/logo.png';
import UserService from '../../services/security/UserService';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class PasswordDefinition extends Component {
  constructor() {
    super();

    this.state = {
      newPassword: '',
      confirmedPassword: '',
      currentPassword: '',
      agree: false
    };
  }

  updatePassword = (name, e) => {
    var newState = {};
    newState[name] = e.target.value;

    this.setState(newState);
  };

  handleConfirmedPasswords = () => {
    const { currentPassword, newPassword, confirmedPassword, agree } = this.state;

    if (!currentPassword) {
      this.props.enqueueSnackbar('Senha enviada por email deve ser informada!', {
        variant: 'warning',
      });
      return;
    }

    if (!newPassword) {
      this.props.enqueueSnackbar('Nova senha deve ser informada!', {
        variant: 'warning',
      });
      return;
    }

    if (!confirmedPassword) {
      this.props.enqueueSnackbar('A confirmação de senha deve ser informada!', {
        variant: 'warning',
      });
      return;
    }

    if (newPassword !== confirmedPassword) {
      this.props.enqueueSnackbar('Senhas diferentes!', {
        variant: 'warning',
      });
      return;
    }

    if (!agree) {
      this.props.enqueueSnackbar('Você deve concordar com a política de privacidade e termos de uso!', {
        variant: 'warning',
      });
      return;
    }

    this.props.enqueueSnackbar('Redefinindo senha...', {
      variant: 'info',
    });

    // chama o endpoint de mudar senha
    UserService.changePassword({ currentPassword, newPassword })
      .then(() => {
        this.props.enqueueSnackbar('Senha redefinida com sucesso!', {
          variant: 'success',
        });
        this.props.history.push('/');
      })
      .catch((error) => {
        if (error.responseJSON && error.responseJSON.hasOwnProperty('message')) {
          this.props.enqueueSnackbar(error.responseJSON.message, {
            variant: 'error',
          });
        } else {
          this.props.enqueueSnackbar('Falha ao redefinir senha!', {
            variant: 'error',
          });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { newPassword, confirmedPassword, currentPassword } = this.state;

    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.avatar}>
            <img src={logo} style={{ height: 130 }} alt="VISURI" />
          </div>
          <form className={classes.form}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                  Redefinir senha
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel> Senha atual (enviada por email) </InputLabel>
                  <Input
                    type="password"
                    id="currentPassword"
                    value={currentPassword}
                    onInput={this.updatePassword.bind(this, 'currentPassword')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.dica}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password"> Nova senha</InputLabel>
                  <Input
                    id="password"
                    type="password"
                    value={newPassword}
                    onInput={this.updatePassword.bind(this, 'newPassword')}
                  />
                  <FormHelperText id="component-helper-text">
                    Mínimo 8 caracteres com ao menos: 1 numeral, 1 caractere especial, 1 letra
                    minúscula e 1 letra maiúscula
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Confirmar senha</InputLabel>
                  <Input
                    id="confirmedPassword"
                    type="password"
                    value={confirmedPassword}
                    onInput={this.updatePassword.bind(this, 'confirmedPassword')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormGroup style={{ flexDirection: 'row' }}>
                    <FormControlLabel
                      control={
                        <Checkbox style={{ paddingRight: '2px' }} color="primary"
                          onChange={(e) => this.setState({ agree: e.target.checked })}
                        />
                      }
                      label={
                        <p style={{ fontSize: '11px' }}>Concordo com a <a href="https://www.visuri.com.br/privacity_term.pdf" target='_blank'>politica de privacidade</a> e <a href="https://www.visuri.com.br/connect_use_term.pdf" target='_blank'>termos de uso</a></p>
                      }
                    />
                </FormGroup>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleConfirmedPasswords}
                style={{ marginTop: '10px' }}
              >
                Salvar
              </Button>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  captcha: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
});

export default withStyles(styles, { withTheme: true })(withSnackbar(PasswordDefinition));
