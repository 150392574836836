import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import UserService from '../../../services/security/UserService';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import AuthService from '../../../services/AuthService';
import UserExcelService from '../../../services/excel/UserExcelService';

const styles = (theme) => ({});

class UserList extends ListBase {
  role = AuthService.getRole();

  options = {
    description: 'Usuário',
    descriptionPlural: 'Usuários',
    icon: PersonIcon,
    edit: 'security/UserEdit',
    details: '/usuario',
    service: UserService,
    module: 'usuarios',
    addColumnAction: true,
    hasNew: this.showHospital(),
    hasDetails: true,
    hasEdit: true,
    hasRemove: true,
    hasExcel: true,
    excelService: UserExcelService,
    columns: [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          sort: true,
        },
      },
      {
        label: 'Nome',
        name: 'name',
        options: {
          sort: true,
        },
      },
      {
        label: 'E-mail',
        name: 'email',
        options: {
          sort: true,
        },
      },
      {
        label: 'Perfil',
        name: 'role',
        options: {
          sort: true,
          display: this.role === 'D' ? 'excluded' : true,
        },
      },
      {
        label: 'Cliente',
        name: 'hospital_name',
        options: {
          sort: true,
          display: this.showHospital(),
        },
      },
    ],
  };

  showHospital() {
    return this.role === 'C' || this.role === 'V' || this.role === 'L' || this.role === 'D';
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UserList);
