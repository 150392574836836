import BaseCRUDService from '../BaseCRUDService';
import { ajax } from '../../helpers/ajax';
import AuthService from '../../services/AuthService';

class UserService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/security/user';
  };

  listByDistributor = (distributorId, page, pageSize, searchText, sortColumn, sortDirection) => {
    return this.list(page, pageSize, searchText, sortColumn, sortDirection, { distributorId });
  };

  getProfile() {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/security/profile',
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  changePassword(model) {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/security/profile/change-password',
        type: 'POST',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          model,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(jqXHR);
        },
        complete: () => {},
      });
    });
  }

  resetPassword(email) {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/reset-password',
        type: 'POST',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          email,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(jqXHR);
        },
        complete: () => {},
      });
    });
  }

  saveDeviceToken(deviceToken) {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/user-device',
        type: 'POST',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          deviceToken,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(jqXHR);
        },
        complete: () => {},
      });
    });
  }

  getUserClient() {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/external/client',
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}

export default new UserService();
