export const EXPERT_LABELS = Object.freeze({
  pressao: 'Pressão arterial média (mmHg)',
  frequencia: 'Frequência cardíaca (bpm)',
  temperatura: 'Temperatura < 35°C ou > 37,5°C',
  cpk: 'CPK > 1000 U/L',
  plaquetas: 'Plaquetas < 40.000 mm\u{00B3}',
  hemoglobina: 'Hemoglobinas ≤ 8g/dl',
  hipotireoidismo: 'Hipotireoidismo',
  trombose: 'Trombose venosa profunda',
  fio: 'FiO\u{2082} > 60%',
  pao: 'PaO\u{2082} ≤ 60 mmHg',
  peep: 'PEEP > 12 cmH\u{2082}O',
  ph: 'pH arterial < 7,25',
  lactato: 'Lactato > 3,0 mmol/L',
  noradrenalina: 'Noradrenalina > 30 μg/min',
  vasopressina: 'Vasopressina ≥ 0,03 U/min',
  dobutamina: 'Dobutamina > 10 μg/Kg/min',
  milrinona: 'Milrinona > 2 μg/Kg/min',
  levosimendan: 'Levosimendan > 0.1 μg/Kg/min',
});
