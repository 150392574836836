import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

import CompanionService from '../../../services/security/CompanionService';
import NoneSelectedContent from './NoneSelectedContent';
import ListCompanionsContent from './ListCompanionsContent';
import AddCompanionContent from './AddCompanionContent';
import RemoveCompanionContent from './RemoveCompanionContent';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
  },
  companionListButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },
  textField: {
    margin: '4px 0px'
  },
});

export const SELECTED_DIALOG_TYPE = {
  NONE_SELECTED: 0,
  ADD_COMPANION: 1,
  LIST_COMPANIONS: 2,
  EDIT_COMPANION: 3,
  REMOVE_COMPANION: 4,
}

class CompanionEdit extends Component {
  constructor() {
    super();

    this.state = {
      selected: SELECTED_DIALOG_TYPE.NONE_SELECTED,
      companions: [],
      loading: false,
    }
  }

  handleChangeSelected(newSelected) {
    this.setState({ selected: newSelected });
  }

  async getCompanions() {
    try {
      this.setState({ loading: true });

      const companions = await CompanionService.list(0, null, null, null, null);
      if (!companions || !Array.isArray(companions.list)) {
        throw new Error('Lista de acompanhantes não retornada')
      }

      this.setState({ companions: companions.list });
    } catch (error) {
      this.props.enqueueSnackbar(
        'Falha ao buscar acompanhantes!', {
        variant: 'error',
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    this.getCompanions()
  }

  handleCloseDialog() {
    this.props.onClose();
  }

  handleCancel() {
    if (this.props.companionToEdit) {
      this.handleCloseDialog();
      return;
    }

    this.setState({
      selected: SELECTED_DIALOG_TYPE.NONE_SELECTED,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // no momento que abrir o dialog
    if (prevProps.open === false && this.props.open === true) {
      if (this.props.companionToEdit) {
        this.setState({
          selected: this.props.isRemove ? SELECTED_DIALOG_TYPE.REMOVE_COMPANION : SELECTED_DIALOG_TYPE.EDIT_COMPANION
        });
      } else {
        this.setState({
          selected: SELECTED_DIALOG_TYPE.NONE_SELECTED
        });
      }
    }
  }

  render() {
    const { open, classes } = this.props;
    const { selected, loading } = this.state;

    const getDialogContentBySelected = () => {
      // OBS: não consegui usar context - precisa ficar passando as props manualmente para os demais componentes
      // tem como melhorar isso no futuro
      switch (selected) {
        case SELECTED_DIALOG_TYPE.NONE_SELECTED:
          return <NoneSelectedContent handleChangeSelected={this.handleChangeSelected.bind(this)} />
        case SELECTED_DIALOG_TYPE.LIST_COMPANIONS:
          return <ListCompanionsContent
            handleCancel={this.handleCancel.bind(this)}
            handleCloseDialog={this.handleCloseDialog.bind(this)}
            onConfirmCompanion={this.props.onConfirmCompanion.bind(this)}
            companions={this.state.companions}
          />
        case SELECTED_DIALOG_TYPE.ADD_COMPANION:
        case SELECTED_DIALOG_TYPE.EDIT_COMPANION:
          return <AddCompanionContent
            handleCancel={this.handleCancel.bind(this)}
            onEditCompanion={this.props.onEditCompanion.bind(this)}
            onConfirmCompanion={this.props.onConfirmCompanion.bind(this)}
            companionToEdit={this.props.companionToEdit}
            handleCloseDialog={this.handleCloseDialog.bind(this)}
            getCompanions={this.getCompanions.bind(this)}
          />
        case SELECTED_DIALOG_TYPE.REMOVE_COMPANION:
          return <RemoveCompanionContent
            companionToEdit={this.props.companionToEdit}
            patientData={this.props.patientData}
            getCompanions={this.getCompanions.bind(this)}
            handleCloseDialog={this.handleCloseDialog.bind(this)}
            onRemoveCompanion={this.props.onRemoveCompanion.bind(this)}
            handleCancel={this.handleCancel.bind(this)}
          />
        default:
          return <NoneSelectedContent handleChangeSelected={this.handleChangeSelected.bind(this)} />
      }
    }

    return (
      <Dialog
        fullScreen={false}
        open={open}
        onClose={() => this.handleCloseDialog()}
      >
        <DialogContent className={classes.container} >
          {loading ? <CircularProgress /> : getDialogContentBySelected()}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withMobileDialog()(withStyles(styles, { withTheme: true })(withSnackbar(CompanionEdit)));
