import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import LogsService from '../../../services/manager/LogsService';
import { withStyles } from '@material-ui/core/styles';
import CodeIcon from '@material-ui/icons/Code';
import { formatTableDate } from '../../../helpers/formatters';

const styles = (theme) => ({});

class Logs extends ListBase {
  options = {
    description: 'Logs',
    descriptionPlural: 'Logs',
    icon: CodeIcon,
    service: LogsService,
    module: 'logs',
    addColumnAction: false,
    hasNew: false,
    hasDetails: false,
    hasEdit: false,
    hasRemove: false,
    columns: [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: true,
          sort: true,
        },
      },
      {
        label: 'Cliente',
        name: 'clientName',
        options: {
          sort: true,
        },
      },
      {
        label: 'Serial',
        name: 'serial',
        options: {
          sort: true,
        },
      },
      {
        label: 'Data',
        name: 'date',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
      {
        label: 'Entidade',
        name: 'entity',
        options: {
          sort: true,
        },
      },
      {
        label: 'Mensagem',
        name: 'message',
        options: {
          sort: true,
        },
      },
      {
        label: 'Data do Registro',
        name: 'date_registration',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
    ],
  };
}

Logs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Logs);
