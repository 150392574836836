import ListBase from '../base/ListBase';
import PropTypes from 'prop-types';
import DistributorService from '../../../services/DistributorService';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import { withStyles } from '@material-ui/core/styles';
import DistributorExcelService from '../../../services/excel/DistributorExcelService';

const styles = (theme) => ({});

class DistributorList extends ListBase {
  options = {
    description: 'Distibuidor',
    descriptionPlural: 'Distribuidores',
    icon: LocalShippingRoundedIcon,
    edit: 'distributor/DistributorEdit',
    module: 'distribuidores',
    details: '/distribuidor',
    service: DistributorService,
    addColumnAction: true,
    hasNew: true,
    hasDetails: true,
    hasEdit: true,
    hasRemove: true,
    hasExcel: true,
    excelService: DistributorExcelService,

    columns: [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          sort: true,
        },
      },
      {
        label: 'Nome',
        name: 'name',
        options: {
          sort: true,
        },
      },
      {
        label: 'Registro',
        name: 'register',
        options: {
          sort: true,
        },
      },
      {
        label: 'Status',
        name: 'distributorStatus',
        options: {
          sort: true,
        },
      },
      {
        label: 'Número de Equipamentos',
        name: 'equipments',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
      {
        label: 'Número de Clientes',
        name: 'clients',
        options: {
          sort: true,
        },
      },
    ],
  };
}

DistributorList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DistributorList);
