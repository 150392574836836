import React from 'react';
import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import ClientService from '../../../services/ClientService';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import AuthService from '../../../services/AuthService';
import { formatTableDate } from '../../../helpers/formatters';
import ClientExcelService from '../../../services/excel/ClientExcelService';

const styles = (theme) => ({});

class ClientList extends ListBase {
  state = {
    ...this.state,
    showZero: true,
  };

  role = AuthService.getRole();

  options = {
    description: 'Cliente',
    descriptionPlural: 'Clientes',
    module: 'clientes',
    icon: LocalConvenienceStoreIcon,
    edit: 'clients/ClientEdit',
    statics: '/estatisticasCliente',
    service: ClientService,
    addColumnAction: true,
    hasNew: true,
    hasDetails: false,
    hasEdit: true,
    hasStatistics: true,
    hasRemove: this.role !== 'D',
    hasExcel: true,
    excelService: ClientExcelService,

    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'name',
        label: 'Nome',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'record',
        label: 'Registro',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'createdAt',
        label: 'Data de criação',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
      {
        name: 'equipments',
        label: 'Equipamentos',
        options: {
          filter: true,
          sort: true,
        },
      },
    ],
  };

  customHeader() {
    return this.state.loading ? (
      <React.Fragment />
    ) : (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '-16px',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(e) => this.setState({ showZero: e.target.checked })}
              color="primary"
            />
          }
          label="Exibir clientes com 0 equipamentos"
        />
      </div>
    );
  }

  getData = (page = 0, pageSize = 10) => {
    this.options.service
      .list(
        page,
        pageSize,
        this.tableState.searchText,
        this.tableState.sortColumn,
        this.tableState.sortDirection,
        { showZero: this.state.showZero }
      )
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          this.setState({
            data: data.list,
            count: data.count,
            page,
            pageSize,
            loading: false,
          });
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showZero !== this.state.showZero) {
      this.getData();
    }
  }
}

ClientList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ClientList);
