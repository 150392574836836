import BaseCRUDService from '../BaseCRUDService';
import { ajax } from '../../helpers/ajax';
import AuthService from '../AuthService';

class FinancialService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/manager/financial';
  };

  getMonth() {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/manager/financial`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}

export default new FinancialService();
