import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { LineChart, Line, XAxis, YAxis, Label } from 'recharts';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { TREATMENT_TYPES, EXPERT_LABELS, RECOVERY_LABELS } from '../../constants';
import { InformationLine } from '../layout';

import visuriLogo from '../../imgs/logo-side.png';
import { calculateGraphOperation } from '../../helpers/formatters';

class TherapyPrint extends React.PureComponent {
  getLabelFromParameterKey = (key) => {
    switch (key) {
      case 'frequency':
        return 'Frequência (Hz)';
      case 'rising':
        return 'Tempo de subida (s)';
      case 'falling':
        return 'Tempo de descida (s)';
      case 'phase':
        return 'Tempo de sustentação (s)';
      case 'intensityPercent':
        return 'Percentual de Intensidade';
      case 'protocol_type':
        return 'Tipo de protocolo';
      default:
        return '';
    }
  };

  render() {
    const { classes } = this.props;
    const { data, muscles } = this.props.data;

    const tableCellStyle = { padding: '4px', textAlign: 'center', border: '1px solid #AAAAAA77' };

    const formatElectrodeSize = (electrodeSize) => {
      if (typeof electrodeSize !== 'string') {
        return electrodeSize;
      }

      // se houver repetição de 'cm' no formato
      if (electrodeSize.indexOf('cm') !== electrodeSize.lastIndexOf('cm')) {
        electrodeSize = electrodeSize.replaceAll('cm', '');
        return electrodeSize + ' cm';
      } else {
        return electrodeSize;
      }
    };

    const getCardTitleFromType = () => {
      switch (data.type) {
        case 0:
          return 'Critérios de Segurança - Expert';
        case 1:
          return 'Dados do Protocolo';
        case 2:
          return 'Dados da Recuperação';
        default:
          return '';
      }
    };
    const getCardContentFromType = () => {
      switch (data.type) {
        case 0:
          return <ExpertCardContent />;
        case 1:
          return <ProfessionalCardContent />;
        case 2:
          return <RecoveryCardContent />;
        default:
          return '';
      }
    };

    const RecoveryCardContent = () => (
      <div className={classes.infoContainer}>
        <div className={classes.infoColumn}>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {RECOVERY_LABELS['activity']}:{' '}
            </p>
            <p className={classes.infoText}> {data.recovery.activity}</p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {RECOVERY_LABELS['minutes']}:{' '}
            </p>
            <p className={classes.infoText}> {data.recovery.minutes}</p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {RECOVERY_LABELS['recovery_date']}:{' '}
            </p>
            <p className={classes.infoText}> {data.recovery.recovery_date}</p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {RECOVERY_LABELS['pse']}:
            </p>
            <p className={classes.infoText}> {data.recovery.pse}</p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 4 }}>
              {RECOVERY_LABELS['psr']}:
            </p>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {' '}
              {data.recovery.psr}
            </p>
          </div>
        </div>
        <div className={classes.infoColumn}>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 3 }}>
              {RECOVERY_LABELS['dmt']}:{' '}
            </p>
            <p className={classes.infoText} style={{ flex: 4 }}>
              {' '}
              {data.recovery.dmt}
            </p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 4 }}>
              {RECOVERY_LABELS['psr_feedback']}:{' '}
            </p>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {' '}
              {data.recovery.psr_feedback}
            </p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 4 }}>
              {RECOVERY_LABELS['dmt_feedback']}:{' '}
            </p>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {' '}
              {data.recovery.dmt_feedback}
            </p>
          </div>
          <div className={classes.infoLine}>
            <p className={classes.infoText} style={{ flex: 2 }}>
              {RECOVERY_LABELS['recovery_type']}:
            </p>
            <p className={classes.infoText} style={{ flex: 3 }}>
              {' '}
              {data.recovery.recovery_type}
            </p>
          </div>
        </div>
      </div>
    );
    const ProfessionalCardContent = () => (
      <div>
        <p className={classes.infoTextName}>
          <b>Nome:</b> {data.protocol.protocol_name}
        </p>
        <p className={classes.infoText}>
          <b>Descrição:</b> {data.protocol.protocol_info}
        </p>
        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
          <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Duração do protocolo:{' '}
              </p>
              <p className={classes.infoText}> {data.protocol.protocol_duration}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Duração do aquecimento do protocolo:
              </p>
              <p className={classes.infoText}> {data.protocol.protocol_warmup_duration}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Duração da recuperação do protocolo:{' '}
              </p>
              <p className={classes.infoText}> {data.protocol.protocol_recovery_duration}</p>
            </div>
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Duração OFF:{' '}
              </p>
              <p className={classes.infoText}> {data.protocol.off_duration}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Número de estímulos recomendados para este protocolo:{' '}
              </p>
              <p className={classes.infoText}> {data.protocol.protocol_stimulus}</p>
            </div>
          </div>
        </div>

        <h3 className={classes.infoHeader}> Parâmetros do protocolo </h3>
        <table
          style={{
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px',
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            <tr>
              <th style={tableCellStyle}>{''}</th>
              <th style={tableCellStyle}>Aquecimento</th>
              <th style={tableCellStyle}>Recuperação</th>
              <th style={tableCellStyle}>Fase ON</th>
              <th style={tableCellStyle}>Fase OFF</th>
            </tr>
            {Object.keys(data.protocol.protocol_warmup_parameters).map((key, index) =>
              key !== 'position' ? (
                <tr key={index}>
                  <td style={{ ...tableCellStyle, textAlign: 'left' }}>
                    {this.getLabelFromParameterKey(key)}
                  </td>
                  <td style={tableCellStyle}>{data.protocol.protocol_warmup_parameters[key]}</td>
                  <td style={tableCellStyle}>{data.protocol.protocol_warmup_parameters[key]}</td>
                  {data.protocol.protocol_phase[0] ? (
                    <td style={tableCellStyle}>{data.protocol.protocol_phase[0][key]}</td>
                  ) : null}
                  {data.protocol.protocol_phase[1] ? (
                    <td style={tableCellStyle}>{data.protocol.protocol_phase[1][key]}</td>
                  ) : null}
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    );
    const ExpertCardContent = () => {
      return (
        <div className={classes.infoContainer}>
          <Grid container spacing={12}>
            {Object.keys(data.warnings).map((key, index, array) => (
              <Grid item xs={6} style={{ padding: '0px 8px' }}>
                <InformationLine
                  style={{ padding: '4px' }}
                  key={index}
                  leftSide={EXPERT_LABELS[key]}
                  rightSide={
                    key === 'pressao' || key === 'frequencia' ? (
                      data.warnings[key] === 0 ? (
                        'Não Informado'
                      ) : (
                        data.warnings[key]
                      )
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={data.warnings[key] ? true : false}
                        style={{ padding: 0, marginLeft: '8px' }}
                      />
                    )
                  }
                  divider={index < array.length - 2}
                  noMargin
                />
              </Grid>
            ))}
          </Grid>
        </div>
      );
    };

    const shouldBreakPage = (index) => index === 3 || index === 7 || index === 11 || index === 15;
    const getPageNumberFromIndex = (index) => {
      if (index === null) {
        return 1;
      } else if (index === 0) {
        return 2;
      } else if (index === 3) {
        return 3;
      } else if (index === 7) {
        return 4;
      } else if (index === 11) {
        return 5;
      } else if (index === 15) {
        return 6;
      }
    };
    const getTotalNumberOfPages = () => {
      const numberOfGraphs = muscles.length;
      if (numberOfGraphs === 0) return 1;
      else if (numberOfGraphs <= 3) {
        return 2;
      } else if (numberOfGraphs <= 7) {
        return 3;
      } else if (numberOfGraphs <= 11) {
        return 4;
      } else if (numberOfGraphs <= 15) {
        return 5;
      }
    };

    const PageHeader = ({ style, index = null }) => {
      const pageNumber = getPageNumberFromIndex(index);
      const totalNumberOfPages = getTotalNumberOfPages();

      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Paciente: {data.patientName}</p>
              <p className={classes.rightText}>Data: {data.date}</p>
              <p className={classes.rightText}>
                Página: {pageNumber} / {totalNumberOfPages}
              </p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    const isTypeValid = Object.keys(TREATMENT_TYPES).includes(String(data.type));

    return (
      <div className={classes.page}>
        <PageHeader />

        <h3 className={classes.infoHeader}>Dados gerais</h3>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                NOME DO PACIENTE:
              </p>
              <p className={classes.infoText}>{data.patientName}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 3 }}>
                DOCUMENTO DO PACIENTE:
              </p>
              <p className={classes.infoText}>{data.patientCPF}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 3 }}>
                SITUAÇÃO DO TRATAMENTO:
              </p>
              <p className={classes.infoText}>{data.status}</p>
            </div>
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>CLIENTE:</p>
              <p className={classes.infoText} style={{ flex: 2 }}>
                {data.clientName}
              </p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 1 }}>
                DATA DO TRATAMENTO:
              </p>
              <p className={classes.infoText}>{data.date}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>IMPRESSO EM:</p>
              <p className={classes.infoText} style={{ flex: 2 }}>
                {moment(Date.now()).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
          </div>
        </div>

        <hr className={classes.divider} />
        {isTypeValid && (
          <>
            <h3 className={classes.infoHeader}> {getCardTitleFromType()}</h3>
            <>{getCardContentFromType()}</>
          </>
        )}

        <hr className={classes.divider} />

        <h3 className={classes.infoHeader}>Dados do Tratamento</h3>

        <table
          style={{
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px',
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            <tr>
              <th style={tableCellStyle}>{''}</th>
              <th style={tableCellStyle}>Programado</th>
              <th style={tableCellStyle}>Realizado</th>
            </tr>
            <tr>
              <td style={{ ...tableCellStyle, textAlign: 'left' }}>Duração do Tratamento</td>
              <td style={tableCellStyle}>{data.treatment_duration}</td>
              <td style={tableCellStyle}>{data.treatment_duration_done}</td>
            </tr>
            <tr>
              <td style={{ ...tableCellStyle, textAlign: 'left' }}>Número de estímulos</td>
              <td style={tableCellStyle}>{data.treatment_stim_number}</td>
              <td style={tableCellStyle}>{data.treatment_stim_number_done}</td>
            </tr>
          </tbody>
        </table>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Modo:
              </p>
              <p className={classes.infoText}>{data.mode} </p>
            </div>
            {data.warmup_stim_number ? (
              <div className={classes.infoLine}>
                <p className={classes.infoText} style={{ flex: 2 }}>
                  Número de estímulos do aquecimento:
                </p>
                <p className={classes.infoText}>{data.warmup_stim_number}</p>
              </div>
            ) : null}
            {data.warmup_duration ? (
              <div className={classes.infoLine}>
                <p className={classes.infoText} style={{ flex: 2 }}>
                  Duração do aquecimento:
                </p>
                <p className={classes.infoText}>{data.warmup_duration}</p>
              </div>
            ) : null}
            <div className={classes.infoLine}>
              <p className={classes.infoText}>Largura de pulso:</p>
              <p className={classes.infoText}>{data.pulse_width}µs</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>Frequência:</p>
              <p className={classes.infoText}>{data.frequency}Hz</p>
            </div>
            {data.duty_cycle ? (
              <div className={classes.infoLine}>
                <p className={classes.infoText}>Taxa de trabalho:</p>
                <p className={classes.infoText}>{data.duty_cycle}%</p>
              </div>
            ) : null}
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>Tempo on:</p>
              <p className={classes.infoText}>{data.on_time}s</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Tempo de estímulo de subida:
              </p>
              <p className={classes.infoText}>{data.rise_time}s</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Tempo de sustentação:
              </p>
              <p className={classes.infoText}>{data.lift_time}s</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                Tempo de estímulo de descida:
              </p>
              <p className={classes.infoText}>{data.falling_time}s</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>Tempo off:</p>
              <p className={classes.infoText}>{data.off_time}s</p>
            </div>
          </div>
        </div>

        {/* <h3 className={classes.infoHeader}> Ocorrências </h3> */}

        <PageHeader
          style={{
            pageBreakBefore: 'always',
            marginTop: '40px',
          }}
          index={0}
        />

        <h3 className={classes.infoHeader}>Resultados</h3>

        {muscles.map((muscle, index) => (
          <React.Fragment key={index}>
            {shouldBreakPage(index) && (
              <PageHeader
                style={{
                  pageBreakBefore: 'always',
                  marginTop: '40px',
                }}
                index={index}
              />
            )}

            <div className={classes.muscleColumn}>
              {muscle.data ? (
                <div className={classes.centerGraph}>
                  <h4 className={classes.infoHeader}>{muscle.desc}</h4>

                  <LineChart
                    data={muscle.data}
                    width={490}
                    height={185}
                    margin={{ top: 5, right: 40, left: 0, bottom: 5 }}
                  >
                    <XAxis dataKey="name">
                      <Label
                        position="insideBottom"
                        style={{ textAnchor: 'middle', marginTop: '16px' }}
                        offset={-5}
                      >
                        Número de estímulos
                      </Label>
                    </XAxis>
                    <YAxis>
                      <Label
                        angle={270}
                        position="insideLeft"
                        style={{ textAnchor: 'middle' }}
                        offset={20}
                      >
                        Intensidade (mA)
                      </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="Intensidade(mA)" stroke="#000000" dot={false} />
                  </LineChart>
                </div>
              ) : null}

              <div className={classes.electrodeInfo}>
                <div className={classes.infoHeader}>
                  <h5 style={{ marginBottom: '4px' }}>Estatísticas</h5>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade máxima:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'max', 'Intensidade(mA)')} mA
                  </p>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade mínima:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'min', 'Intensidade(mA)')} mA
                  </p>
                </div>
                <div className={classes.infoLine} style={{ marginBottom: '8px' }}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade média:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'average', 'Intensidade(mA)')} mA
                  </p>
                </div>
                <div className={classes.infoHeader}>
                  <h5 style={{ marginBottom: '4px' }}>Eletrodo</h5>
                </div>
                {muscle.electrode_material !== 'Undefined' &&  ( <div className={classes.infoLine}>
                  <p className={classes.infoText}>Tipo:</p>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    {muscle.electrode_material}
                  </p>
                </div>)}
                <div className={classes.infoLine}>
                  <p className={classes.infoText}>Formato:</p>
                  <p className={classes.infoText}>{muscle.electrode_format}</p>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText}>Tamanho:</p>
                  <p className={classes.infoText}>{formatElectrodeSize(muscle.electrode_width)}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '1px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoTextName: {
    margin: '8px 0px',
    flex: 1,
    textAlign: 'center',
    fontSize: '16px'
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  infoHeader: {
    textAlign: 'center',
  },
  centerGraph: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  muscleColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
  },
  electrodeInfo: {
    flex: 2,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

TherapyPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TherapyPrint);
