import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

import DistributorService from '../../../services/DistributorService';
import ClientService from '../../../services/ClientService';
import EquipmentService from '../../../services/product/EquipmentService';
import UserService from '../../../services/security/UserService';
import AuthService from '../../../services/AuthService';
import { formatTableDate } from '../../../helpers/formatters';
import { ptBr } from '../../../helpers/MUIDataTableLabels';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class Distributor extends Component {
  id = '';
  role = AuthService.getRole();

  state = {
    value: 0,
    distributor: {},
    clients: [],
    users: [],
    equipments: [],
    loading: false,

    // clients table control
    clientsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },

    // equipments table control
    equipmentsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },

    // users table control
    usersTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getAllData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDetailsClick = (id, type) => (event) => {
    this.props.history.push(type + '/' + id);
  };

  getActionColumn = (id, type) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label="Detalhes" onClick={this.handleDetailsClick(id, type)}>
          <FindInPageOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  getDistributor = () => {
    return new Promise((resolve, reject) => {
      DistributorService.get(this.id)
        .then((data) => {
          this.setState({
            distributor: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getClients = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.clientsTable;

      ClientService.listByDistributor(
        this.id,
        page,
        pageSize,
        searchText,
        sortColumn,
        sortDirection
      )
        .then((data) => {
          this.setState({
            clients: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getEquipments = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.equipmentsTable;

      EquipmentService.listByDistributor(
        this.id,
        page,
        pageSize,
        searchText,
        sortColumn,
        sortDirection
      )
        .then((data) => {
          this.setState({
            equipments: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getUsers = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.usersTable;

      UserService.listByDistributor(this.id, page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            users: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getAllData = async () => {
    try {
      this.setState({ loading: true });

      await this.getDistributor();
      await this.getClients();
      await this.getEquipments();
      await this.getUsers();
    } catch (error) {
      this.handleRequestError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  setSortColumn = (columns, type) => {
    let sortColumn = columns.find((column) => {
      return !!column.sortDirection;
    });

    if (type === 'clients') {
      this.setState({
        clientsTable: {
          ...this.state.clientsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'equipments') {
      this.setState({
        equipmentsTable: {
          ...this.state.equipmentsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'users') {
      this.setState({
        usersTable: {
          ...this.state.usersTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    }
  };

  toggleActivate = () => {
    const isActive = this.state.distributor.status === 1;
    const newStatus = isActive ? 0 : 1;

    this.props.enqueueSnackbar(
      newStatus ? 'Ativando distribuidor...' : 'Desativando distribuidor...',
      {
        variant: 'info',
      }
    );

    DistributorService.edit(this.state.distributor.id, {
      ...this.state.distributor,
      status: newStatus,
    }).then(() => {
      this.setState((previousState) => {
        previousState.distributor.status = newStatus;
        return previousState;
      });

      this.props.enqueueSnackbar(newStatus ? 'Distribuidor ativado!' : 'Distribuidor desativado!', {
        variant: 'success',
      });
    });
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        <span
          onClick={this.handleBreadcrumb('/distribuidores')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Distribuidores
        </span>
        {' > '}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Distribuidor</span>
      </h6>
    );
  }

  addAcoes(arr, type) {
    if (arr.count) {
      for (var i = 0; i < arr.list.length; i++) {
        arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
      }
    }
    return arr;
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.clientsTable !== this.state.clientsTable) {
        this.getClients();
      }
      if (prevState.equipmentsTable !== this.state.equipmentsTable) {
        this.getEquipments();
      }
      if (prevState.usersTable !== this.state.usersTable) {
        this.getUsers();
      }
    } catch (error) {
      this.handleRequestError(error);
    }
  }

  // para evitar repetição do tratamento de erro
  handleRequestError(error) {
    console.log(error);
    this.props.enqueueSnackbar('Falha ao buscar dados do distribuidor!', {
      variant: 'error',
    });
  }

  tblClients = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'record',
      label: 'Registro',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Data de criação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'equipments',
      label: 'Equipamentos',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  tblEquipments = [
    {
      label: 'Id',
      name: 'id',
      options: {
        display: false,
        sort: true,
      },
    },
    {
      label: 'Modelo',
      name: 'equipmentTypeName',
      options: {
        sort: true,
      },
    },
    {
      label: 'Data Fabricação',
      name: 'equipmentDate',
      options: {
        sort: true,
        customBodyRender: (value) => formatTableDate(value, true),
      },
    },
    {
      label: 'Serial',
      name: 'serial',
      options: {
        sort: true,
      },
    },
    {
      name: 'hospital',
      label: 'Cliente',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'equipmentStatus',
      label: 'Status',
      options: {
        display: true,
        sort: true,
      },
    },
    {
      name: 'syncDate',
      label: 'Data de Sincronização',
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'softwareVersion',
      label: 'Versão de Software',
      options: {
        display: true,
        sort: true,
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  tblUsers = [
    {
      label: 'Id',
      name: 'id',
      options: {
        display: false,
        sort: true,
      },
    },
    {
      label: 'Nome',
      name: 'name',
      options: {
        sort: true,
      },
    },
    {
      label: 'E-mail',
      name: 'email',
      options: {
        sort: true,
      },
    },
    {
      label: 'Cliente',
      name: 'hospital_name',
      options: {
        sort: true,
        display: true,
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  render() {
    const { classes } = this.props;
    const { value, loading, distributor, clients, clientsTable, equipmentsTable, usersTable } =
      this.state;

    const equipments = this.addAcoes(this.state.equipments, '/equipamento');
    const users = this.addAcoes(this.state.users, '/usuario');

    const optionsClient = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.clients.count,
      page: clientsTable.page,
      rowsPerPage: clientsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              clientsTable: {
                ...this.state.clientsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              clientsTable: {
                ...this.state.clientsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'clients');
            break;
          default:
            break;
        }
      },
    };

    const optionsEquipment = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.equipments.count,
      page: equipmentsTable.page,
      rowsPerPage: equipmentsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              equipmentsTable: {
                ...this.state.equipmentsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              equipmentsTable: {
                ...this.state.equipmentsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'equipments');
            break;
          default:
            break;
        }
      },
    };

    const optionsUsers = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.users.count,
      page: usersTable.page,
      rowsPerPage: usersTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              usersTable: {
                ...this.state.usersTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              usersTable: {
                ...this.state.usersTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'users');
            break;
          default:
            break;
        }
      },
    };

    return (
      <div>
        {this.breadcrumb()}

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', margin: '16px 3px 16px 20px' }}
            >
              Distribuidor - {distributor.name}
            </Typography>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="standard"
                  scrollButtons="auto"
                >
                  <Tab label="Dados do Distribuidor" />
                  {<Tab label="Clientes" />}
                  {<Tab label="Equipamentos" />}
                  {<Tab label="Usuários" />}
                </Tabs>
              </AppBar>
              {value === 0 && (
                <TabContainer>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Nome: {distributor.name}</Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Registro: {distributor.register}</Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Data de cadastro: {moment(distributor.createdAt).format('DD/MM/YYYY HH:mm')}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Número de usuários: {distributor.users}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Número de equipamentos: {distributor.equipments}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Número de clientes: {distributor.clients}
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Paper className={classes.paper} style={{ marginRight: 8 }}>
                        Desativado / Ativado:{' '}
                      </Paper>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={distributor.status === 1}
                            onClick={this.toggleActivate}
                            name="status"
                          />
                        }
                        label={distributor.status ? 'Ativado' : 'Desativado'}
                      />
                    </Grid>
                  </Grid>
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  <MUIDataTable
                    title={'Clientes'}
                    data={clients.list}
                    columns={this.tblClients}
                    options={optionsClient}
                  />
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer>
                  <MUIDataTable
                    title={'Equipamentos'}
                    data={equipments.list}
                    columns={this.tblEquipments}
                    options={optionsEquipment}
                  />
                </TabContainer>
              )}
              {value === 3 && (
                <TabContainer>
                  <MUIDataTable
                    title={'Usuários'}
                    data={users.list}
                    columns={this.tblUsers}
                    options={optionsUsers}
                  />
                </TabContainer>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

Distributor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(Distributor));
