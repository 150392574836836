import { ajax } from '../helpers/ajax';
import AuthService from '../services/AuthService';

export default class BaseCRUDService {
  getBaseURL = () => {
    //Should return '~/api/module'
    throw Error('Not implemented');
  };

  list(page, pageSize, searchText, sortColumn, sortDirection, customFilters = {}) {
    return new Promise((resolve, reject) => {
      ajax({
        url: this.getBaseURL(),
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          page,
          pageSize,
          searchText,
          sortColumn,
          sortDirection,
          ...customFilters,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  get(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `${this.getBaseURL()}/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  edit(id, model) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `${this.getBaseURL()}/${id}`,
        type: 'PUT',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
          model,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          // permite que exceções customizadas da api cheguem ao snackbar
          if (jqXHR.responseJSON && jqXHR.responseJSON.hasOwnProperty('message')) {
            errorThrown = jqXHR.responseJSON;
          }

          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  save(model) {
    return new Promise((resolve, reject) => {
      ajax({
        url: this.getBaseURL(),
        type: 'POST',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          model,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          // permite que exceções customizadas da api cheguem ao snackbar
          if (jqXHR.responseJSON && jqXHR.responseJSON.hasOwnProperty('message')) {
            errorThrown = jqXHR.responseJSON;
          }

          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  remove(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: this.getBaseURL(),
        type: 'DELETE',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
        },
        success: (result, textStatus, jqXHR) => {
          resolve();
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}
