import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import TherapyService from '../../../services/TherapyService';
import { withStyles } from '@material-ui/core/styles';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import { formatTableDate } from '../../../helpers/formatters';
import TherapyExcelService from '../../../services/excel/TherapyExcelService';
import AuthService from '../../../services/AuthService';

const styles = (theme) => ({});

class TherapyList extends ListBase {
  role = AuthService.getRole();

  options = {
    description: 'Tratamento',
    descriptionPlural: 'Tratamentos',
    icon: QueuePlayNextIcon,
    edit: 'patients/PatientEdit',
    details: '/terapia',
    module: 'terapias',
    service: TherapyService,
    excelService: TherapyExcelService,
    addColumnAction: true,
    hasNew: false,
    hasDetails: true,
    hasEdit: false,
    hasRemove: false,
    hasExcel: true,
    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'patientName',
        label: 'Paciente',
        options: {
          display: this.role === 'P' ? false : true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date',
        label: 'Data/Hora',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          display: (this.role === 'P' || this.role === 'C') ? false : true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'therapyType',
        label: 'Tipo',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
    ],
  };
}

TherapyList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TherapyList);
