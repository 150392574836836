import BaseCRUDService from './BaseCRUDService';
import { ajax } from '../helpers/ajax';
import AuthService from './AuthService';

class ExamService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/exam';
  };

  getListPatient(id, page, pageSize, searchText, sortColumn, sortDirection) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/patient/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          page,
          pageSize,
          searchText,
          sortColumn,
          sortDirection,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getListUser(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/user/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getListEquipment(id, page, pageSize, searchText, sortColumn, sortDirection) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/equipment/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          page,
          pageSize,
          searchText,
          sortColumn,
          sortDirection,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getListContract(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/contract/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getListEquipmentAll(page, pageSize, searchText, sortColumn, sortDirection) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/equipment-all`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          page,
          pageSize,
          searchText,
          sortColumn,
          sortDirection,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getListEquipmentPacientAll(page, pageSize, searchText, sortColumn, sortDirection) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/exam/equipment-patient-all`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          page,
          pageSize,
          searchText,
          sortColumn,
          sortDirection,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getReport(id, firstDate = '', lastDate = '') {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/report/exam/${id}`,
        type: 'GET',
        data: { firstDate: firstDate, lastDate: lastDate },
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}

export default new ExamService();
