export default {
  //visuri
  V: [
    'pacientes',
    'usuarios',
    'clientes',
    'diagnosticos',
    'protocolos',
    'terapias',
    'equipamentos',
    'equipamentos-tipo',
    'contratos',
    'logs',
    'financeiro',
    'relatorios-horizontais',
    'curvasID',
    'curvaID',
    'estatisticas',
    'distribuidores',
    'agendamento'
  ],

  //administrativo hospital
  A: [
    'pacientes',
    'usuarios',
    'diagnosticos',
    'protocolos',
    'terapias',
    'equipamentos',
    'contratos',
    'financeiro',
    'relatorios-horizontais',
    'curvasID',
    'curvaID',
    'agendamento'
  ],

  //fisioterapeuta
  C: [
    'pacientes',
    'diagnosticos',
    'protocolos',
    'terapias',
    'paciente',
    'usuarios',
    'exame',
    'terapia',
    'relatorios-horizontais',
    'curvasID',
    'curvaID',
    'agendamento'
  ],

  //contourline
  L: ['equipamentos'],

  //distribuidor
  D: ['equipamentos', 'contratos', 'clientes', 'usuarios', 'estatisticas'],

  //paciente
  P: [
    'diagnosticos',
    'protocolos',
    'terapias',
    'exame',
    'terapia',
    'relatorios-horizontais',
    'curvasID',
    'curvaID',
    'agendamento'
  ],

  //acompanhante
  AC: [
    'diagnosticos',
    'protocolos',
    'terapias',
    'exame',
    'terapia',
    'relatorios-horizontais',
    'curvasID',
    'curvaID',
    'agendamento'
  ],
};
