import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import logo from './../../imgs/logo.png';
import config from '../../config.json';
import UserService from '../../services/security/UserService';

const captchaKey = config.captchaKey;

class PasswordRequest extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      isVerified: false,
      isEmailSent: false,
      loading: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value) {
    if (value) this.setState({ isVerified: true });
    else this.setState({ isVerified: false });
  }

  updatePassword = (name, e) => {
    var newState = {};
    newState[name] = e.target.value;

    this.setState(newState);
  };

  handleResetPassword = (event) => {
    event.preventDefault();

    const { email, isVerified } = this.state;

    if (!email) {
      this.props.enqueueSnackbar('Email deve ser informado!', {
        variant: 'warning',
      });
      return;
    }

    if (!isVerified) {
      this.props.enqueueSnackbar('Captcha não selecionado!', {
        variant: 'warning',
      });
      return;
    }

    this.setState({ loading: true });

    //chama o endpoint de esqueci senha
    UserService.resetPassword(email)
      .then(() => {
        this.props.enqueueSnackbar('Email enviado!', {
          variant: 'success',
        });
        this.setState({ isEmailSent: true })
      })
      .catch((error) => {
        if (error.responseJSON && error.responseJSON.hasOwnProperty('message')) {
          this.props.enqueueSnackbar(error.responseJSON.message, {
            variant: 'error',
          });
        } else {
          this.props.enqueueSnackbar('Email nao cadastrado no sistema!', {
            variant: 'error',
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { classes } = this.props;
    const { email, isEmailSent, loading } = this.state;

    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.avatar}>
            <img src={logo} style={{ height: 130 }} alt="VISURI" />
          </div>
          <form className={classes.form} onSubmit={this.handleResetPassword} >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                  Digite o seu email
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password"> Email </InputLabel>
                  <Input value={email || ''} onInput={this.updatePassword.bind(this, 'email')} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  className={classes.captcha}
                  sitekey={captchaKey}
                  onChange={this.handleOnChange}
                />
              </Grid>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isEmailSent}
                >
                  Confirmar
                </Button>
              )}

              {isEmailSent ? (
                <p style={{ textAlign: 'center', margin: '32px 0px 0px 0px', width: '100%' }} >
                  <a href="/login">
                    Voltar para login
                  </a>
                </p>
              ) : null}
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  captcha: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
});

export default withStyles(styles, { withTheme: true })(withSnackbar(PasswordRequest));
