import React from 'react';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import QueueIcon from '@material-ui/icons/Queue';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonIcon from '@material-ui/icons/Person';
import DeviceOtherIcon from '@material-ui/icons/DevicesOther';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import TimelineIcon from '@material-ui/icons/Timeline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BarChartIcon from '@material-ui/icons/BarChart';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';

import logo from '../imgs/logo-side-w.png';
import AuthService from '../services/AuthService';
import history from '../history';
import MainRoutes from './MainRoutes';

const DRAWER_WIDTH = 240;

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  identification: {
    fontSize: '10px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    textAlign: 'right',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    textAlign: 'right',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px 0 0',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class Main extends React.Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      isDrawerOpen: false,
    };

    PubSub.subscribe('ajax-connectionRetries', (message, connectionRetries) => {
      if (connectionRetries > 0) {
        this.props.enqueueSnackbar('Erro de conexão. Tentativas: ' + connectionRetries, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      }
    });
  }

  logout = () => {
    this.handleMenuClose();
    AuthService.logout();
    history.push('/');
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMenuPerfil = () => {
    this.setState({ anchorEl: null });
    this.setState({ mobileMoreAnchorEl: null });
    history.push('/perfil');
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleDrawerOpen = () => {
    this.setState({ isDrawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };

  render() {
    const userName = AuthService.getUserName();
    const lastSession = AuthService.getLastSession();
    const role = AuthService.getRole();

    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, theme } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { isDrawerOpen } = this.state;

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuPerfil}>Meu Perfil</MenuItem>
        <MenuItem onClick={this.logout}>Sair</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>{userName}</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: isDrawerOpen,
          })}
        >
          <Toolbar disableGutters={true}>
            <IconButton
              color="inherit"
              aria-label="Abrir Menu"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, isDrawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              <Link to="/">
                <img src={logo} style={{ width: 160, marginLeft: 10, marginTop: 8 }} alt="VISURI" />
              </Link>
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div>
                <Typography
                  className={classes.identification}
                  variant="body1"
                  style={{ marginTop: 3, fontSize: 14 }}
                >
                  {userName}
                </Typography>
                <Typography
                  className={classes.identification}
                  variant="body1"
                  style={{ fontSize: 14 }}
                >
                  {lastSession ? 'Último acesso: ' + lastSession : 'Bem-vindo!'}
                </Typography>
              </div>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <div>
                <Typography
                  className={classes.identification}
                  variant="body1"
                  style={{ marginTop: 3, fontSize: 14 }}
                >
                  {userName}
                </Typography>
                <Typography
                  className={classes.identification}
                  variant="body1"
                  style={{ fontSize: 14 }}
                >
                  {lastSession ? 'Último acesso: ' + lastSession : 'Bem-vindo!'}
                </Typography>
              </div>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Link
              to="/"
              onClick={this.handleDrawerClose}
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <ListItem button key="Home">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>

            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />

          <List>
            {role !== 'L' && role !== 'D' && role !== 'P' && role !== 'AC' && (
              <Link
                to="/pacientes"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Pacientes">
                  <ListItemIcon>
                    <AirlineSeatFlatIcon />
                  </ListItemIcon>
                  <ListItemText primary="Pacientes" />
                </ListItem>
              </Link>
            )}

            {role !== 'L' && role !== 'D' && (
              <Link
                to="/diagnosticos"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Diagnósticos">
                  <ListItemIcon>
                    <QueueIcon />
                  </ListItemIcon>
                  <ListItemText primary="Diagnósticos" />
                </ListItem>
              </Link>
            )}

            {role !== 'L' && role !== 'D' && (
              <Link
                to="/protocolos"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Protocolos">
                  <ListItemIcon>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Protocolos" />
                </ListItem>
              </Link>
            )}

            {role !== 'L' && role !== 'D' && (
              <Link
                to="/terapias"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Tratamentos">
                  <ListItemIcon>
                    <QueuePlayNextIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tratamentos" />
                </ListItem>
              </Link>
            )}

            {role !== 'L' && role !== 'D' && (
              <Link
                to="/relatorios-horizontais"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Relatórios Horizontais">
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Relatórios Horizontais" />
                </ListItem>
              </Link>
            )}

            {role !== 'C' && role !== 'L' && role !== 'P' && role !== 'AC' && (
              <Link
                to="/contratos"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Contratos">
                  <ListItemIcon>
                    <ContactsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contratos" />
                </ListItem>
              </Link>
            )}

            {role !== 'C' && role !== 'L' && role !== 'P' && role !== 'AC' && (
              <Link
                to="/usuarios"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Usuário">
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Usuários" />
                </ListItem>
              </Link>
            )}

            {(role === 'V' || role === 'D') && (
              <Link
                to="/clientes"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Clients">
                  <ListItemIcon>
                    <LocalConvenienceStoreIcon />
                  </ListItemIcon>
                  <ListItemText primary="Clientes" />
                </ListItem>
              </Link>
            )}

            {false && (
              <Link
                to="/financeiro"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Financeiro">
                  <ListItemIcon>
                    <LocalAtmIcon />
                  </ListItemIcon>
                  <ListItemText primary="Financeiro" />
                </ListItem>
              </Link>
            )}

            {role !== 'C' && role !== 'P' && role !== 'AC' && (
              <Link
                to="/equipamentos"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Equipamentos">
                  <ListItemIcon>
                    <DeviceOtherIcon />
                  </ListItemIcon>
                  <ListItemText primary="Equipamentos" />
                </ListItem>
              </Link>
            )}

            {role === 'V' && (
              <Link to="/logs" onClick={this.handleDrawerClose} style={{ textDecoration: 'none' }}>
                <ListItem button key="Logs">
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                </ListItem>
              </Link>
            )}

            {role === 'V' && (
              <Link
                to="/relatorios"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Logs">
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Relatórios" />
                </ListItem>
              </Link>
            )}

            {role === 'V' && (
              <Link
                to="/distribuidores"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Logs">
                  <ListItemIcon>
                    <LocalShippingRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Distribuidores" />
                </ListItem>
              </Link>
            )}

            {role === 'V' && (
              <Link
                to="/agendamento"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="Agendamento">
                  <ListItemIcon>
                    <LocalShippingRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Agendamento" />
                </ListItem>
              </Link>
            )}

            {(role === 'V' || this.role === 'A' || this.role === 'D' || this.role === 'C') && (
              <Link
                to="/apis"
                onClick={this.handleDrawerClose}
                style={{ textDecoration: 'none' }}
              >
                <ListItem button key="APIs Externas">
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  <ListItemText primary="APIs Externas" />
                </ListItem>
              </Link>
            )}
          </List>
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          <div className={classes.drawerHeader} />
          <MainRoutes />
        </main>
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Main);
