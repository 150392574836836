import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ContactsIcon from '@material-ui/icons/Contacts';
import MUIDataTable from 'mui-datatables';
import { ptBr } from '../../../helpers/MUIDataTableLabels';
import IconButton from '@material-ui/core/IconButton';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import EditDialog from '../base/EditDialog';
import ContractService from '../../../services/manager/ContractService';
import ExamService from '../../../services/ExamService';
import EquipmentService from '../../../services/product/EquipmentService';
import TherapyService from '../../../services/TherapyService';
import CircularProgress from '@material-ui/core/CircularProgress';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class Contract extends Component {
  options = '';
  id = '';

  state = {
    value: 0,
    isConfirmDeleteOpen: false,
    isEditOpen: false,
    selectedId: 0,
    data: '',
    equipments: '',
    exames: '',
    terapias: '',
    loading: true,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleEdit = (id) => (event) => {
    this.setState({
      selectedId: id,
      isEditOpen: true,
    });
  };

  handleCloseEdit = () => {
    this.setState({ isEditOpen: false });
    this.getDataContract();
  };

  handleDetailsClick = (id, type) => (event) => {
    this.props.history.push(type + '/' + id);
  };

  handleRemove = () => {
    this.setState({ isConfirmDeleteOpen: true });
  };

  handleAcceptConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });

    ContractService.remove(this.id).then(() => {
      this.props.history.goBack();
    });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });
  };

  getActionColumn = (id, type) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label="Detalhes" onClick={this.handleDetailsClick(id, type)}>
          <FindInPageOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  getDataContract = () => {
    ContractService.get(this.id).then((data) => {
      this.setState({
        data: data,
      });
    });
  };

  getDataEquipmentContract = () => {
    EquipmentService.getListContract(this.id).then((data) => {
      this.setState({
        equipments: data,
      });
    });
  };

  getDataExamContract = () => {
    ExamService.getListContract(this.id).then((data) => {
      this.setState({
        exames: data,
      });
    });
  };

  getDataTherapyContract = () => {
    TherapyService.getListContract(this.id).then((data) => {
      this.setState({
        terapias: data,
        loading: false,
      });
    });
  };

  getData = () => {
    this.getDataContract();
    this.getDataEquipmentContract();
    this.getDataExamContract();
    this.getDataTherapyContract();

    this.tblEquipments = [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          sort: true,
        },
      },
      {
        label: 'Modelo',
        name: 'equipmentTypeName',
        options: {
          sort: true,
        },
      },
      {
        label: 'Serial',
        name: 'serial',
        options: {
          sort: true,
        },
      },
      {
        label: 'Data Instalação',
        name: 'equipmentDate',
        options: {
          sort: true,
        },
      },
      {
        label: 'Data Expiração',
        name: 'equipmentExpireDate',
        options: {
          sort: true,
        },
      },
      {
        name: 'acoes',
        label: 'Ações',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    this.tblExames = [
      {
        name: 'date_start',
        label: 'Data Início',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date_end',
        label: 'Data Fim',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "patientName",
        label: "Paciente",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'acoes',
        label: 'Ações',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    this.tblTerapias = [
      {
        name: 'date',
        label: 'Data de Realização',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "patientName",
        label: "Paciente",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'acoes',
        label: 'Ações',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    this.options = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
    };
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp;{' '}
        <span
          onClick={this.handleBreadcrumb('/contratos')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Contratos
        </span>{' '}
        {'>'}
        &nbsp;<span style={{ color: 'rgba(152, 149, 149, 1)' }}>Contrato</span>
      </h6>
    );
  }

  addAcoes(arr, type) {
    if (arr.count) {
      for (var i = 0; i < arr.list.length; i++) {
        arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
      }
    }
    return arr;
  }

  render() {
    const { classes } = this.props;
    const { value, loading } = this.state;
    const data = this.state.data;
    const equipments = this.addAcoes(this.state.equipments, '/equipamento');
    const exames = this.addAcoes(this.state.exames, '/diagnostico');
    const terapias = this.addAcoes(this.state.terapias, '/terapia');

    return (
      <div>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
        >
          Contrato
        </Typography>
        <ContactsIcon style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />

        {loading && <CircularProgress />}

        {!loading && (
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
              >
                <Tab label="Dados do Contrato" />
                <Tab label="Equipamentos" />
                <Tab label="Tratamentos" />
                <Tab label="Diagnósticos" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Número do Contrato: {data.id}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Cliente: {data.clientName}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Data do Contrato: {data.date}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Status: {data.case}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Número de Equipamentos: {equipments.count}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Número de Tratamentos: {terapias.count}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Número de Diagnósticos: {exames.count}</Paper>
                  </Grid>
                </Grid>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <MUIDataTable
                  title={'Equipamentos'}
                  data={equipments.list}
                  columns={this.tblEquipments}
                  options={this.options}
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={'Tratamentos'}
                  data={terapias.list}
                  columns={this.tblTerapias}
                  options={this.options}
                />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <MUIDataTable
                  title={'Diagnósticos'}
                  data={exames.list}
                  columns={this.tblExames}
                  options={this.options}
                />
              </TabContainer>
            )}
          </div>
        )}

        <EditDialog
          edit="patients/ContractEdit"
          title="Editar Paciente"
          id={this.state.selectedId}
          isOpen={this.state.isEditOpen}
          onCloseCallBack={this.handleCloseEdit}
        />

        <Dialog
          open={this.state.isConfirmDeleteOpen}
          onClose={this.handleCloseConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Remover o paciente'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja realmente remover o paciente?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAcceptConfirmDelete} color="primary">
              Sim
            </Button>
            <Button onClick={this.handleCloseConfirmDelete} color="primary" autoFocus>
              Não
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Contract.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Contract);
