import React from 'react'
import MaskedInput from 'react-text-mask'

export function MaskedBirthday(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => {
        return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
      }}
      guide={false}
    />
  );
}
