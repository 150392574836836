import BaseCRUDService from '../BaseCRUDService';
import { ajax } from '../../helpers/ajax';
import AuthService from '../AuthService';

class EquipmentService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/product/equipment';
  };

  listByDistributor = (distributorId, page, pageSize, searchText, sortColumn, sortDirection) => {
    return this.list(page, pageSize, searchText, sortColumn, sortDirection, { distributorId });
  };

  getListContract(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/product/equipment/contract/${id}`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  editStatus(id, status) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/product/equipment/status/${id}`,
        type: 'PUT',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
          status,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  editBlocked(id, blocked) {
    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/product/equipment/blocked`,
        type: 'PUT',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
          blocked,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  getStatistics(deviceId, query) {
    if (!query) {
      throw new Error('Query must be defined in getStatistics');
    }

    const { firstDate, lastDate, type } = query;

    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/statistics/equipment`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          deviceId,
          firstDate,
          lastDate,
          type,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}

export default new EquipmentService();
