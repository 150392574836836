import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import withStyles from '@material-ui/core/styles/withStyles';

import { calculateGraphOperation } from '../../helpers/formatters';
import visuriLogo from '../../imgs/logo-side.png';

class TherapyReportPrint extends React.PureComponent {
  GRAPH_WIDTH = 650;
  GRAPH_HEIGHT = 250;
  GRAPH_MARGINS = { top: 5, left: 5, right: 5, bottom: 55 };

  INTENSITY_GRAPH_COLOR = '#00ff00';
  PULSE_WIDTH_GRAPH_COLOR = '#ff0000';
  WORK_RATE_GRAPH_COLOR = '#0000ff';
  FREQUENCY_GRAPH_COLOR = '#ff00ff';

  constructor() {
    super();

    this.state = {
      intensityPulseGraph: [],
      intensityTimeGraph: [],
      intensityFrequencyGraph: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.intensityGraph !== this.props.data.intensityGraph) {
      this.setState({
        intensityPulseGraph: this.joinIntesityAndPulseGraphs(),
        intensityTimeGraph: this.joinIntesityAndTimeGraphs(),
        intensityFrequencyGraph: this.joinIntesityAndFrequencyGraphs(),
      });
    }
  }

  joinIntesityAndPulseGraphs = () => {
    const { intensityGraph, pulseWidthGraph } = this.props.data;
    let result = [];

    for (const [index, muscle] of intensityGraph.entries()) {
      result.push({
        ...muscle,
        data: muscle.data.map((point, pointIndex) => ({
          name: point.name, // data do ponto no gráfico
          'Aplicações x Intensidade(mA)':
            intensityGraph[index].data[pointIndex]['Aplicações x Intensidade(mA)'],
          'Aplicações x Largura de Pulso(µs)':
            pulseWidthGraph[index].data[pointIndex]['Aplicações x Largura de Pulso(µs)'],
        })),
      });
    }

    return result;
  };

  joinIntesityAndTimeGraphs = () => {
    const { intensityGraph, timeGraph } = this.props.data;
    let result = [];

    for (const [index, muscle] of intensityGraph.entries()) {
      result.push({
        ...muscle,
        data: muscle.data.map((point, pointIndex) => ({
          name: point.name, // data do ponto no gráfico
          'Aplicações x Intensidade(mA)':
            intensityGraph[index].data[pointIndex]['Aplicações x Intensidade(mA)'],
          'Aplicações x Taxa de Trabalho(s)':
            timeGraph[index].data[pointIndex]['Aplicações x Taxa de Trabalho(s)'],
        })),
      });
    }

    return result;
  };

  joinIntesityAndFrequencyGraphs = () => {
    const { intensityGraph, frequencyGraph } = this.props.data;
    let result = [];

    for (const [index, muscle] of intensityGraph.entries()) {
      result.push({
        ...muscle,
        data: muscle.data.map((point, pointIndex) => ({
          name: point.name, // data do ponto no gráfico
          'Aplicações x Intensidade(mA)':
            intensityGraph[index].data[pointIndex]['Aplicações x Intensidade(mA)'],
          'Aplicações x Frequência(Hz)':
            frequencyGraph[index].data[pointIndex]['Aplicações x Frequência(Hz)'],
        })),
      });
    }

    return result;
  };

  render() {
    const { classes } = this.props;
    const { result, musclesList } = this.props.data;
    const { intensityPulseGraph, intensityTimeGraph, intensityFrequencyGraph } = this.state;

    const shouldBreakPage = (index) => true;
    const getPageNumberFromIndex = (index) => (index !== null ? index + 2 : 1);
    const getTotalNumberOfPages = () => musclesList.length + 1;

    const PageHeader = ({ style, index = null }) => {
      const pageNumber = getPageNumberFromIndex(index);
      const totalNumberOfPages = getTotalNumberOfPages();

      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Relatório de Tratamentos Horizontais</p>
              <p className={classes.rightText}>Paciente: {result.patient_name}</p>
              <p className={classes.rightText}>
                Página: {pageNumber} / {totalNumberOfPages}
              </p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    const CustomizedXAxisTick = ({ x, y, payload }) => (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
          style={{ fontSize: '10px' }}
        >
          {payload.value}
        </text>
      </g>
    );

    return (
      <div className={classes.page}>
        <PageHeader />

        <h3 className={classes.infoHeader}>Dados gerais</h3>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>NOME DO PACIENTE:</p>
              <p className={classes.infoGeneralText}>{result.patient_name}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText} style={{ flex: 2 }}>
                DOCUMENTO DO PACIENTE:
              </p>
              <p className={classes.infoGeneralText}>{result.patient_document}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>DATA DE NASCIMENTO:</p>
              <p className={classes.infoGeneralText}>{result.patient_birthdate}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>TOTAL DE TRATAMENTOS:</p>
              <p className={classes.infoGeneralText}>{result.total}</p>
            </div>
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>CLIENTE:</p>
              <p className={classes.infoGeneralText}>{result.client_name}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>PERÍODO DE ANÁLISE:</p>
              <p className={classes.infoGeneralText}>
                {moment(result.firstDate).format('DD/MM/YYYY HH:mm')} até{' '}
                {moment(result.lastDate).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>IMPRESSO EM:</p>
              <p className={classes.infoGeneralText}>
                {moment(Date.now()).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
          </div>
        </div>

        <h3 className={classes.infoHeader}>Músculos</h3>

        {musclesList.map((muscle, index) => (
          <React.Fragment key={index}>
            {shouldBreakPage(index) && (
              <PageHeader
                style={{
                  pageBreakBefore: 'always',
                  marginTop: '20px',
                }}
                index={index}
              />
            )}

            <h3 className={classes.muscleTitle}>{muscle}</h3>

            <div className={classes.legendContainer}>
              <div className={classes.legendItem}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.INTENSITY_GRAPH_COLOR,
                    backgroundColor: this.INTENSITY_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Intensidade (mA)
                </p>
              </div>
              <div className={classes.legendItem} style={{ flex: 1.25 }}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.PULSE_WIDTH_GRAPH_COLOR,
                    backgroundColor: this.PULSE_WIDTH_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Largura de Pulso (µs)
                </p>
              </div>
              <div className={classes.legendItem} style={{ flex: 1.25 }}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.WORK_RATE_GRAPH_COLOR,
                    backgroundColor: this.WORK_RATE_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Taxa de trabalho (s)
                </p>
              </div>
              <div className={classes.legendItem}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.FREQUENCY_GRAPH_COLOR,
                    backgroundColor: this.FREQUENCY_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Frequência (Hz)
                </p>
              </div>
            </div>

            {intensityPulseGraph.length && intensityPulseGraph[index].data && (
              <div className={classes.centerGraph}>
                <LineChart
                  data={intensityPulseGraph[index].data}
                  width={this.GRAPH_WIDTH}
                  height={this.GRAPH_HEIGHT}
                  margin={this.GRAPH_MARGINS}
                  style={{ marginTop: '36px' }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                  <YAxis yAxisId="left">
                    <Label
                      angle={270}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                      offset={10}
                    >
                      Intensidade (mA)
                    </Label>
                  </YAxis>
                  <YAxis yAxisId="right" orientation="right">
                    <Label
                      angle={270}
                      position="insideRight"
                      style={{ textAnchor: 'middle' }}
                      offset={5}
                    >
                      Largura de Pulso (µs)
                    </Label>
                  </YAxis>
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="Aplicações x Intensidade(mA)"
                    stroke={this.INTENSITY_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Aplicações x Largura de Pulso(µs)"
                    stroke={this.PULSE_WIDTH_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                </LineChart>
              </div>
            )}

            {intensityTimeGraph.length && intensityTimeGraph[index].data && (
              <div className={classes.centerGraph}>
                <LineChart
                  data={intensityTimeGraph[index].data}
                  width={this.GRAPH_WIDTH}
                  height={this.GRAPH_HEIGHT}
                  margin={this.GRAPH_MARGINS}
                  style={{ marginTop: '36px' }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                  <YAxis yAxisId="left">
                    <Label
                      angle={270}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                      offset={10}
                    >
                      Intensidade (mA)
                    </Label>
                  </YAxis>
                  <YAxis yAxisId="right" orientation="right">
                    <Label
                      angle={270}
                      position="insideRight"
                      style={{ textAnchor: 'middle' }}
                      offset={5}
                    >
                      Taxa de trabalho (s)
                    </Label>
                  </YAxis>
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="Aplicações x Intensidade(mA)"
                    stroke={this.INTENSITY_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Aplicações x Taxa de Trabalho(s)"
                    stroke={this.WORK_RATE_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                </LineChart>
              </div>
            )}

            {intensityFrequencyGraph.length && intensityFrequencyGraph[index].data && (
              <div className={classes.centerGraph}>
                <LineChart
                  data={intensityFrequencyGraph[index].data}
                  width={this.GRAPH_WIDTH}
                  height={this.GRAPH_HEIGHT}
                  margin={this.GRAPH_MARGINS}
                  style={{ marginTop: '36px' }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                  <YAxis yAxisId="left">
                    <Label
                      angle={270}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                      offset={10}
                    >
                      Intensidade (mA)
                    </Label>
                  </YAxis>
                  <YAxis yAxisId="right" orientation="right">
                    <Label
                      angle={270}
                      position="insideRight"
                      style={{ textAnchor: 'middle' }}
                      offset={5}
                    >
                      Frequência (Hz)
                    </Label>
                  </YAxis>
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="Aplicações x Intensidade(mA)"
                    stroke={this.INTENSITY_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Aplicações x Frequência(Hz)"
                    stroke={this.FREQUENCY_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                </LineChart>
              </div>
            )}

            <div className={classes.muscleHeaderInfo}>
              {intensityTimeGraph.length && (
                <div className={classes.muscleInfo}>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Número de pontos:
                    </p>
                    <p className={classes.infoText}>{intensityTimeGraph[index].data.length}</p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Data inicial:
                    </p>
                    <p className={classes.infoText}>{intensityTimeGraph[index].data[0].name}</p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Data final:
                    </p>
                    <p className={classes.infoText}>
                      {
                        intensityTimeGraph[index].data[intensityTimeGraph[index].data.length - 1]
                          .name
                      }
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Intensidade máxima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(
                        intensityTimeGraph[index].data,
                        'max',
                        'Aplicações x Intensidade(mA)'
                      )}{' '}
                      mA
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Intensidade mínima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(
                        intensityTimeGraph[index].data,
                        'min',
                        'Aplicações x Intensidade(mA)'
                      )}{' '}
                      mA
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Intensidade média:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(
                        intensityTimeGraph[index].data,
                        'average',
                        'Aplicações x Intensidade(mA)'
                      )}{' '}
                      mA
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Largura de pulso máxima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(
                        intensityPulseGraph[index].data,
                        'max',
                        'Aplicações x Largura de Pulso(µs)'
                      )}{' '}
                      µs
                    </p>
                  </div>
                </div>
              )}
              {intensityPulseGraph.length &&
                intensityTimeGraph.length &&
                intensityFrequencyGraph.length && (
                  <div className={classes.muscleInfo}>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Largura de pulso mínima:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityPulseGraph[index].data,
                          'min',
                          'Aplicações x Largura de Pulso(µs)'
                        )}{' '}
                        µs
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Largura de pulso média:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityPulseGraph[index].data,
                          'average',
                          'Aplicações x Largura de Pulso(µs)'
                        )}{' '}
                        µs
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Taxa de trabalho máxima:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityTimeGraph[index].data,
                          'max',
                          'Aplicações x Taxa de Trabalho(s)'
                        )}{' '}
                        s
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Taxa de trabalho mínima:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityTimeGraph[index].data,
                          'min',
                          'Aplicações x Taxa de Trabalho(s)'
                        )}{' '}
                        s
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Taxa de trabalho média:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityTimeGraph[index].data,
                          'average',
                          'Aplicações x Taxa de Trabalho(s)'
                        )}{' '}
                        s
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Frequência máxima:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityFrequencyGraph[index].data,
                          'max',
                          'Aplicações x Frequência(Hz)'
                        )}{' '}
                        Hz
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Frequência mínima:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityFrequencyGraph[index].data,
                          'min',
                          'Aplicações x Frequência(Hz)'
                        )}{' '}
                        Hz
                      </p>
                    </div>
                    <div className={classes.infoLine}>
                      <p className={classes.infoText} style={{ flex: 2 }}>
                        Frequência média:
                      </p>
                      <p className={classes.infoText}>
                        {calculateGraphOperation(
                          intensityFrequencyGraph[index].data,
                          'average',
                          'Aplicações x Frequência(Hz)'
                        )}{' '}
                        Hz
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '2px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoGeneralText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoHeader: {
    textAlign: 'center',
  },
  centerGraph: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-40px',
  },
  muscleTitle: {
    textAlign: 'center',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  muscleHeaderInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
  },
  muscleInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  legendContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '16px',
  },
  legendItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px',
  },
});

TherapyReportPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TherapyReportPrint);
