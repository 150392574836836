import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Person from '@material-ui/icons/Person';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserService from '../../../services/security/UserService';
import { withSnackbar } from 'notistack';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '15px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
    width: '100%',
  },
  formControl: {
    marginTop: 20,
    marginLeft: 10,
  },
  selectEmpty: {
    marginTop: 10,
    minWidth: 250,
  },
  container: {
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '800px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

class UserProfile extends Component {
  state = {
    data: '',
    isLoading: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ isLoading: true });
    UserService.getProfile()
      .then((data) => {
        this.setState({
          data: data,
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar('Erro ao buscar dados do usuário!', {
          variant: 'error',
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp; <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Perfil</span>
      </h6>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGrid: {
          container: {
            padding: 15,
            border: '1px solid #d3d3d3',
            backgroundColor: '#fff',
          },
        },
        MuiPaper: {
          root: {
            fontSize: 14,
          },
        },
        MuiButton: {
          outlinedPrimary: {
            color: '#4caf50',
            border: '1px solid rgba(76, 175, 80, 0.5)',
          },
        },
        MuiInput: {
          root: {
            marginRight: 16,
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

  render = () => {
    const { classes } = this.props;
    const profile = this.state.data;
    const { isLoading } = this.state;

    return (
      <div className={classes.root}>
        {this.breadcrumb()}

        <div className={classes.container}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
          >
            Perfil
          </Typography>
          <Person style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />

          <MuiThemeProvider theme={this.getMuiTheme()}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>Nome: {profile.name}</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>Documento: {profile.document}</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>Email: {profile.email}</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>Perfil: {profile.formattedRole}</Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>
                    {profile.role === 5 ? 'Distribuidor' : 'Hospital'}:{' '}
                    {profile.role === 5 ? profile.distributor_name : profile.hospital_name}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper className={classes.paper}>Data do cadastro: {profile.registerDate}</Paper>
                </Grid>
              </Grid>
            )}
          </MuiThemeProvider>
        </div>
      </div>
    );
  };
}

export default withStyles(styles, { withTheme: true })(withSnackbar(UserProfile));
