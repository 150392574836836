import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';

import SchedulingEdit from './SchedulingEdit';
import AuthService from '../../../services/AuthService';
import { renderSidebarEvent } from './event-utils';

import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SelectOptionScheduling from './SelectOptionScheduling';
import SchedulingDelete from './SchedulingDelete';

import AppointmentService from '../../../services/AppointmentService';

import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

class Scheduling extends Component {

  constructor() {
    super();

    this.state = {
      openDialog: null,
      dateTimeFirst: {},
      dateTimeLast: {},
      currentEvent: [],
      allEvents: [],
      id: '',
      emailCompanion: true,
      emailPatient: true
    };

    this.calendarRef = React.createRef()
    this.role = AuthService.getRole()

  }

  componentDidMount() {
    this.getAppointments()
  }


  doAfterGetDate(data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  createAppointment(data) {
    var name = data.type === 1 ? 'Consulta' : 'Avaliação'

    if (data.active === 2)
      name = name + '\n(Cancelado)'

    if (data.active) {
      const newEvent = {
        id: data.id,
        title: name,
        start: data.date_start,
        end: data.date_end,
        color: data.active === 1 ? green[500] : 'red'
      }

      let calendarApi = this.calendarRef.current.getApi()
      calendarApi.addEvent(newEvent)
    }

  }

  getAppointments() {
    AppointmentService.list(0, null, null, null, null)
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          data.list.forEach(appointment => {
            this.createAppointment(appointment)
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDateClick = (arg) => {
    if (this.role !== 'P' && this.role !== 'AC') {
      this.setState({ id: '' })
      this.setState({ openDialog: 0 })
      this.setState({ dateTimeFirst: arg.date })
      this.setState({ dateTimeLast: arg.date })
    }

  }

  handleSubmit = (data) => {

    const newEvent = {
      id: data.id,
      title: data.type === 1 ? 'Consulta' : 'Avaliação',
      start: data.date_start,
      end: data.date_end
    }

    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.addEvent(newEvent)
    this.handleCloseDialog()
  }

  handleMarkOff = () => {
    var currentEvent = this.state.currentEvent

    currentEvent.event.setProp('color', 'red' )
    currentEvent.event.mutate({
      standardProps: {
        title: currentEvent.event.title + '\n(Cancelado)',
      }
    })


    this.handleCloseDialog();
  }

  handleEdit = (data) => {
    var currentEvent = this.state.currentEvent
    currentEvent.event.setStart(data.date_start);
    currentEvent.event.setEnd(data.date_end);
    currentEvent.event.mutate({
      standardProps: { title: data.type === 1 ? 'Consulta' : 'Avaliação' }
    })
    this.handleCloseDialog();
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: null })
    this.setState({ id: '' })
  }

  handleChangeDateFirst = (value) => {
    this.setState({ dateTimeFirst: value })
  }
  handleChangeDateLast = (value) => {
    this.setState({ dateTimeLast: value })
  }

  handleEvents = (events) => {
    this.setState({
      allEvents: events
    })
  }

  handleDelete = () => {
    var currentEvent = this.state.currentEvent
    currentEvent.event.remove()
    this.handleCloseDialog()
  }

  handleEventClick = (clickInfo) => {
    this.setState({ id: clickInfo.event.id })
    this.setState({ currentEvent: clickInfo })

    if (clickInfo.event.backgroundColor === 'red') {
      this.setState({ openDialog: 2 })
    }
    else {
      this.setState({ openDialog: 1 })
    }

  }

  setOpenDialog = (value) => {
    this.setState({ openDialog: value })
  }

  render() {
    const { classes } = this.props
    const { openDialog, dateTimeFirst, dateTimeLast, id, emailCompanion, emailPatient} = this.state;
    return (
      <div className={classes.main}>
        <div>
          {this.renderSidebar()}
          {this.role !== 'P'  && this.role !== 'AC' ? this.renderStickyNotes() : null}
        </div>
        <div className={classes.container}>
          <FullCalendar
            ref={this.calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            dateClick={this.handleDateClick}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents}
            eventColor={green[500]}
          />
          <SelectOptionScheduling isOpen={openDialog === 1 ? true : false}
            id={id}
            onCloseCallBack={this.handleCloseDialog}
            onSetOpenDialog={this.setOpenDialog}
            onMarkOff={this.handleMarkOff}>
          </SelectOptionScheduling>

          <SchedulingEdit
            id={id}
            isOpen={openDialog === 0 ? true : false}
            onCloseCallBack={this.handleCloseDialog}
            onHandleSubmit={this.handleSubmit}
            onHandleChangeDateFirst={this.handleChangeDateFirst}
            onHandleChangeDateLast={this.handleChangeDateLast}
            onHandleEdit={this.handleEdit}
            dateFirst={dateTimeFirst}
            dateLast={dateTimeLast} 
            emailCompanion = {emailCompanion}
            emailPatient = {emailPatient}/>

          {this.role !== 'P' && this.role !== 'AC' && (<SchedulingDelete id={id}
            isOpen={openDialog === 2 ? true : false}
            handleClose={this.handleCloseDialog}
            handleDelete={this.handleDelete} />)}
        </div>
      </div>
    )
  }

  renderStickyNotes() {
    return (
      <div style={{ width: '100%', textAlign: 'left', marginLeft: '12px', marginRight: '12px' }}>
        <div style = {{marginTop: '20px'}}>
            <h3>Lembretes</h3>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color= "primary"
                      checked={this.state.emailPatient}
                      onChange={(e) => this.setState({ emailPatient: e.target.checked })}
                      value="emailPatient"
                    />
                  }
                  label="Enviar para e-mail do paciente"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color= "primary"
                      checked={this.state.emailCompanion}
                      onChange={(e) => this.setState({ emailCompanion: e.target.checked })}
                      value="emailCompanion"
                    />
                  }
                  label="Enviar para e-mail do acompanhante"
                />
                </FormGroup>
                </FormControl>
              </Grid>
          </Grid>
      </div>
    )
  }
  renderSidebar() {
    return (
      <div style={{
        width: '300px',
        lineHeight: '1.5',
        borderRight: '1px solid #d3e2e8',
        paddingTop: '35px'
      }}>
        <Paper style={{
          backgroundColor: '#4caf50',
          color: 'white',
          paddingTop: '12px',
          margin: '0px 12px 12px 12px',
        }}
        >
          <div style={{ width: '100%', textAlign: 'center', marginBottom: '12px', marginTop: '12px' }}>
            Eventos Futuros:
          </div>
          <div
            style={{
              fontWeight: '400',
              fontSize: '13px',

              width: '100%',
              padding: '0px 16px 5px 16px',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              color: 'black',
            }}
          >
            {this.state.allEvents.map((key, index) => renderSidebarEvent(key))}
          </div>
        </Paper>
      </div>
    );
  }
}


const styles = (theme) => ({
  main: {
    display: 'flex',
    minHeight: '340px',
    fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
    fontSize: '14px',
    width: '100%'
  },
  container: {
    flexGrow: 1,
    padding: '3em',
  }
});

export default withStyles(styles, { withTheme: true })(withSnackbar(Scheduling));
