import React from 'react';

const CustomTooltip = ({ active, payload, label }) =>
  active && payload ? (
    <div style={{ backgroundColor: '#fff', border: '1px solid #D3D3D3', padding: '5px 15px' }}>
      <span style={{ fontWeight: 600 }}>Estímulo {label}</span>
      <br />
      <span className="intro">Intensidade(mA): {payload[0].value}</span>
    </div>
  ) : null;

export default CustomTooltip;
