import React from 'react';
import Divider from '@material-ui/core/Divider';

const InformationLine = ({
  leftSide,
  rightSide,
  divider = true,
  noMargin = false,
  shouldShow = true,
  predominantRightSide = false,
  ...props
}) =>
  shouldShow ? (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...props.style,
        }}
      >
        <p
          style={{
            textAlign: 'left',
            margin: '8px 0px',
            flex: predominantRightSide ? 1 : 2,
          }}
        >
          {leftSide}
        </p>
        <p
          style={{
            textAlign: 'right',
            margin: noMargin ? 0 : '8px 0px',
            flex: predominantRightSide ? 2 : 1,
          }}
        >
          {rightSide}
        </p>
      </div>
      {divider ? <Divider /> : null}
    </>
  ) : null;

export default InformationLine;
