import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import EquipmentTypeService from '../../../services/product/EquipmentTypeService';
import { withStyles } from '@material-ui/core/styles';
import DeviceOtherIcon from '@material-ui/icons/DevicesOther';

const styles = (theme) => ({});

class EquipmentTypeList extends ListBase {
  options = {
    description: 'Modelo de Equipamento',
    descriptionPlural: 'Modelos de Equipamentos',
    icon: DeviceOtherIcon,
    edit: 'product/EquipmentTypeEdit',
    module: 'equipamentos-tipo',
    service: EquipmentTypeService,
    columns: [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          sort: true,
        },
      },
      {
        label: 'Nome',
        name: 'name',
        options: {
          sort: true,
        },
      },
    ],
  };
}

EquipmentTypeList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EquipmentTypeList);
