import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import PatientService from '../../../services/PatientService';
import { withStyles } from '@material-ui/core/styles';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import PatientExcelService from '../../../services/excel/PatientExcelService';

const styles = (theme) => ({});

class PatientList extends ListBase {
  options = {
    description: 'Paciente',
    descriptionPlural: 'Pacientes',
    icon: AirlineSeatFlatIcon,
    edit: 'patients/PatientEdit',
    details: '/paciente',
    module: 'pacientes',
    service: PatientService,
    addColumnAction: true,
    hasNew: true,
    hasDetails: true,
    hasEdit: true,
    hasRemove: true,
    hasExcel: true,
    excelService: PatientExcelService,

    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'name',
        label: 'Nome',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'identifier',
        label: 'Identificador',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'birthDate',
        label: 'Data de Nascimento',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'hospital',
        label: 'Cliente',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'companions',
        label: 'Acompanhantes',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
    ],
  };

  // componentDidMount() {
  //   this.handleEditNew()
  // }
}

PatientList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PatientList);
