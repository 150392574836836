const CID = 'Curva ID';
const TEDE = 'Teste de Eletrodiagnóstico de Estímulo';

export const DIAGNOSTIC_TYPES = Object.freeze({
  0: CID,
  1: TEDE,
});

export const DIAGNOSTIC_INSTRUMENTS = Object.freeze({
  0: 'Caneta',
  1: 'Eletrodo',
});
