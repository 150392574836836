import React from 'react'
import MaskedInput from 'react-text-mask'

const EIGHT_DIGIT_PHONE_MASk = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const NINE_DIGIT_PHONE_MASk = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export function MaskedPhoneNumber(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => rawValue.length > EIGHT_DIGIT_PHONE_MASk.length ? NINE_DIGIT_PHONE_MASk : EIGHT_DIGIT_PHONE_MASk}
      guide={false}
    />
  );
}
