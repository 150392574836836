import React from "react"
import { InformationLine } from "../../layout"
import MaskedInput from 'react-text-mask'
import moment from "moment"


export function renderSidebarEvent(event) {
  return (
    ( event.start >= new Date() ? (<InformationLine key={event.id} predominantRightSide = {true} leftSide={event.title} 
    rightSide = {`${moment(event.start).format('DD/MM/YYYY HH:mm:ss')} 
    ate ${moment(event.end).format('DD/MM/YYYY HH:mm:ss')}`}/>) : null)
  )
}


export function InputMaskCustomCEP(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
      }}
      guide={false}
    />
  );
}

