import BaseCRUDService from './BaseCRUDService';
import { ajax } from '.././helpers/ajax';
import AuthService from './AuthService';

class ClientService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/client';
  };

  getStatistics(clientId, query) {
    if (!query) {
      throw new Error('Query must be defined in getStatistics');
    }

    const { firstDate, lastDate, type } = query;

    return new Promise((resolve, reject) => {
      ajax({
        url: `~/api/statistics/client`,
        type: 'GET',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          clientId,
          firstDate,
          lastDate,
          type,
        },
        success: (data, textStatus, jqXHR) => {
          resolve(data);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }

  listByDistributor = (distributorId, page, pageSize, searchText, sortColumn, sortDirection) => {
    return this.list(page, pageSize, searchText, sortColumn, sortDirection, { distributorId });
  };
}

export default new ClientService();
