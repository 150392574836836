import $ from 'jquery';
import PubSub from 'pubsub-js';
import config from '../config.json';

export interface AjaxOptions {
  type?: string;
  url: string;
  data?: any;
  headers?: any;
  handleError?: boolean;
  handleUnauthorized?: boolean;
  success?: (data: any, textStatus: any, jqXHR: any) => void;
  error?: (jqXHR?: any, textStatus?: any, errorThrown?: any, errorMessage?: any) => void;
  unauthorized?: (jqXHR?: any, textStatus?: any, errorThrown?: any) => void;
  complete?: (jqXHR: any, textStatus: any) => void;
  exclusive?: boolean;
}

// var _apiUrl = config.api;
var _apiUrl = 'http://vision-teste.visuri.com.br';
// var _apiUrl = 'http://localhost:8090';
var _ajaxPending = 0;
var _ajaxExclusive = false;
export var connectionRetries = 0;

export function resolveUrl(url: string): string {
  return url.replace(/^~/, _apiUrl);
}

export function ajax(options: AjaxOptions) {
  if (_ajaxExclusive) {
    window.setTimeout(this.ajax.bind(this, options), 1000);
  }

  if (options.exclusive) {
    if (_ajaxPending > 0) {
      window.setTimeout(this.ajax.bind(this, options), 1000);
      return;
    }
    _ajaxExclusive = true;
  }

  _ajaxPending++;

  $.ajax({
    type: options.type || 'POST',
    url: resolveUrl(options.url),
    contentType: 'application/json; charset=utf-8',
    data: options.type != 'GET' ? (options.data ? JSON.stringify(options.data) : {}) : options.data,
    cache: false,
    headers: options.headers ? options.headers : {},
    success: (data: any, textStatus: any, jqXHR: any) => {
      connectionRetries = 0;
      PubSub.publish('ajax-connectionRetries', connectionRetries);
      if (options.success) {
        options.success.call(this, data, textStatus, jqXHR);
      }
    },
    error: (jqXHR: { status: number; responseText: string }, textStatus: any, errorThrown: any) => {
      if (jqXHR.status == 0 || jqXHR.status == 502) {
        (<any>jqXHR).handlingNoConnection = true;

        setTimeout(() => {
          connectionRetries++;
          PubSub.publish('ajax-connectionRetries', connectionRetries);
          ajax(options);
        }, 5000);

        return;
      } else {
        var errorMessage = null;

        if (jqXHR.status == 401) {
          if (options.handleUnauthorized != false) {
            var hashArray = window.location.href.split('#', 3); //Gets hash without decode
            var newHash = hashArray[1]; //Gets hash without decode
            if (!/^security\/Login/.test(newHash)) {
              let loginDialogName = 'security/Login';
              if (this.isDialogOpen(loginDialogName)) return;

              this.openDialog(loginDialogName, {
                modal: true,
                callback: () => {
                  ajax(options);
                },
              });
            }
          }

          if (options.unauthorized) options.unauthorized.call(this, jqXHR, textStatus, errorThrown);

          return;
        }

        try {
          var responseError = JSON.parse(jqXHR.responseText);
          errorMessage = responseError.errorMessage;
        } catch (err) {
          errorMessage = errorThrown;
        }

        if (options.handleError != false) {
          if (!responseError || !responseError.success) {
            if (responseError && responseError.message === 'jwt expired') {
              alert('Login expirado!');
              localStorage.removeItem('authToken');
              localStorage.removeItem('userName');
              localStorage.removeItem('role');
              localStorage.removeItem('hospital');
              localStorage.removeItem('last_session');
              window.location.href = '/';
            }

            // MessageBox.show({
            //     modal: true,
            //     center: true,
            //     type: 'error',
            //     text: errorMessage,
            //     buttons: [{
            //         text: 'OK',
            //         css: 'btn-danger'
            //     }]
            // });
          }
        }
      }

      if (options.error) options.error.call(this, jqXHR, textStatus, errorThrown, errorMessage);
    },
    complete: (jqXHR: any, textStatus: any) => {
      if (options.complete && (<any>jqXHR).handlingNoConnection !== true)
        options.complete.call(this, jqXHR, textStatus);

      _ajaxPending--;
      if (options.exclusive) {
        _ajaxExclusive = false;
      }
    },
  });
}

export default ajax;
