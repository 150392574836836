import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import EquipmentService from '../../../services/product/EquipmentService';
import { withStyles } from '@material-ui/core/styles';
import DeviceOtherIcon from '@material-ui/icons/DevicesOther';
import AuthService from '../../../services/AuthService';
import { formatTableDate } from '../../../helpers/formatters';
import EquipmentExcelService from '../../../services/excel/EquipmentExcelService';

const styles = (theme) => ({});

class EquipmentList extends ListBase {
  options = {
    description: 'Equipamento',
    descriptionPlural: 'Equipamentos',
    icon: DeviceOtherIcon,
    edit: 'product/EquipmentEdit',
    module: 'equipamentos',
    details: '/equipamento',
    statics: '/estatisticasEquipamento',
    service: EquipmentService,
    addColumnAction: true,
    hasNew: AuthService.getRole() === 'V' || AuthService.getRole() === 'L',
    hasDetails: true,
    hasStatistics: true,
    hasEdit: AuthService.getRole() === 'V',
    hasRemove: AuthService.getRole() === 'V',
    hasExcel: true,
    excelService: EquipmentExcelService,

    columns: [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
          sort: true,
        },
      },
      {
        label: 'Modelo',
        name: 'equipmentTypeName',
        options: {
          sort: true,
        },
      },
      {
        label: 'Data Fabricação',
        name: 'equipmentDate',
        options: {
          sort: true,
          customBodyRender: (value) => formatTableDate(value, true),
        },
      },
      {
        label: 'Serial',
        name: 'serial',
        options: {
          sort: true,
        },
      },
      {
        name: 'hospital',
        label: 'Cliente',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'equipmentStatus',
        label: 'Status',
        options: {
          display: true,
          sort: true,
        },
      },
      {
        name: 'syncDate',
        label: 'Data de Sincronização',
        options: {
          display: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
      {
        name: 'softwareVersion',
        label: 'Versão de Software',
        options: {
          display: true,
          sort: true,
        },
      },
      {
        name: 'appUpdateDate',
        label: 'Última atualização de software',
        options: {
          display: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
    ],
  };
}

EquipmentList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EquipmentList);
