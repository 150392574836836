const ACTION_UNDEFINED = 'Indefinido';
const ACTION_PLAY = 'Iniciado';
const ACTION_PAUSE = 'Pausado';
const ACTION_UNPAUSE = 'Continuado';
const ACTION_STOP = 'Interrompido';
const ACTION_FINISH = 'Finalizado';
const ACTION_CANCELED = 'Cancelado';
const ACTION_EMERGENCY = 'Botão de Emergência';
const ACTION_JUMP_WARM = 'Pulou Aquecimento';
const ACTION_JUMP_STIMULUS = 'Pulou Estímulo';

export const TREATMENT_STATES = Object.freeze({
  0: ACTION_UNDEFINED,
  1: ACTION_PLAY,
  2: ACTION_PAUSE,
  3: ACTION_UNPAUSE,
  4: ACTION_STOP,
  5: ACTION_FINISH,
  6: ACTION_CANCELED,
  7: ACTION_EMERGENCY,
  8: ACTION_JUMP_WARM,
  9: ACTION_JUMP_STIMULUS,
});

const EXPERT = 'Expert';
const PROFESSIONAL = 'Profissional';
const RECOVERY = 'Recuperação';

export const TREATMENT_TYPES = Object.freeze({
  0: EXPERT,
  1: PROFESSIONAL,
  2: RECOVERY,
});

const INTERRUPTED = 'Interrompido';
const CONCLUDED = 'Concluído';

export function treatment_status(status){
  return status === 2 ? CONCLUDED : INTERRUPTED
}

const MANUAL = 'Manual';
const AUTOMATIC = 'Automático';

export const TREATMENT_MODES = Object.freeze({
  0: MANUAL,
  1: AUTOMATIC,
});
