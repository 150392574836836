import { ajax } from '../helpers/ajax';

class AuthService {
  isLoggedIn() {
    return (
      !!localStorage.getItem('authToken') &&
      !!localStorage.getItem('userName') &&
      !!localStorage.getItem('role') &&
      !!localStorage.getItem('hospital')
    );
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/login',
        data: {
          email,
          password,
        },
        handleUnauthorized: false,
        success: (userInfo) => {
          localStorage.setItem('userName', userInfo.name);
          localStorage.setItem('role', userInfo.role);
          localStorage.setItem('authToken', userInfo.authToken);
          localStorage.setItem('id', userInfo.id);
          if (userInfo.role === 0) {
            userInfo.hospital = 'Administrador Visuri';
          }
          if (userInfo.role === 4) {
            userInfo.hospital = 'ContourLine';
          }
          if (userInfo.role === 5) {
            userInfo.hospital = 'Distribuidor';
          }
          if (userInfo.role === 7) {
            userInfo.hospital = 'Acompanhante';
          }
          localStorage.setItem('hospital', userInfo.hospital);
          localStorage.setItem('last_session', userInfo.last_login);

          resolve(userInfo);
        },
        unauthorized: (jqXHR, textStatus, errorThrown) => {
          reject(jqXHR);
        },
        complete: () => { },
      });
    });
  }

  logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('hospital');
    localStorage.removeItem('last_session');
    localStorage.removeItem('id');
  };

  rememberMe = (email) => {
    localStorage.setItem('lastLogin', email);
  };

  forgotMe = () => {
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('hospital');
    localStorage.removeItem('last_session');
    localStorage.removeItem('id');
  };

  getLastLogin = () => {
    return localStorage.getItem('lastLogin');
  };

  getUserName = () => {
    return localStorage.getItem('userName');
  };

  getRole = () => {
    let role = localStorage.getItem('role');
    let strRole = '';

    switch (role) {
      case '0':
        // USUÁRIO VISURI
        strRole = 'V';
        break;
      case '1':
        // USUÁRIO MANUTENÇÃO
        strRole = 'M';
        break;
      case '2':
        // USUÁRIO PROFISSIONAL DA SAÚDE
        strRole = 'C';
        break;
      case '3':
        // USUÁRIO ADMINISTRATIVO
        strRole = 'A';
        break;
      case '4':
        // USUÁRIO CONTOURLINE
        strRole = 'L';
        break;
      case '5':
        // USUÁRIO DISTRIBUIDOR
        strRole = 'D';
        break;
      case '6':
        // USUÁRIO PACIENTE
        strRole = 'P';
        break;
      case '7':
        // USUÁRIO ACOMPANHANTE
        strRole = 'AC';
        break;
      default:
        break;
    }

    return strRole;
  };

  getHospitalName = () => {
    return localStorage.getItem('hospital');
  };

  getLastSession = () => {
    return localStorage.getItem('last_session');
  };

  getToken = () => {
    return localStorage.getItem('authToken');
  };

  getId = () => {
    return localStorage.getItem('id');
  };
}

export default new AuthService();
