import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TimerIcon from '@material-ui/icons/Timer';
import DeviceOtherIcon from '@material-ui/icons/DevicesOther';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FinancialService from '../../../services/manager/FinancialService';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  table: {
    borderStyle: 'double',
    backgroundColor: '#fff',
    marginBottom: 5,
  },
});

class Financial extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      data: {
        time: '',
        total: '',
        contract_id: '',
        range: '',
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    FinancialService.getMonth().then((data) => {
      this.setState({
        data: data,
        loading: false,
      });
    });
  }

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp; <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Financeiro</span>
      </h6>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MuiTableCell: {
          root: {
            borderBottomStyle: 'dashed',
          },
        },
      },
    });

  render = () => {
    const { classes } = this.props;
    const { loading } = this.state;
    const data = this.state.data;

    return (
      <div className={classes.root}>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 8, margin: '0 3px 0 20px' }}
        >
          Financeiro
        </Typography>
        <LocalAtmIcon style={{ float: 'left', margin: '6px 10px 10px 0', fontSize: 30 }} />

        {loading && <CircularProgress />}

        {!loading && (
          <Grid container>
            <Grid item xs={4} style={{ padding: 15 }}>
              <GridList cellHeight={90} className={classes.gridList}>
                <GridListTile
                  key="icon"
                  style={{
                    width: '30%',
                    backgroundColor: '#4caf50',
                    textAlign: 'center',
                    paddingTop: '24px',
                  }}
                >
                  <AttachMoneyIcon style={{ fontSize: '50px', color: '#fff' }} />
                </GridListTile>
                <GridListTile
                  key="icon2"
                  style={{
                    width: '70%',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    border: '1px solid #f3f3f3',
                    borderLeft: 'none',
                    paddingRight: '12px',
                    paddingTop: '15px',
                  }}
                >
                  <Typography variant="h5" component="h5" style={{ fontSize: '37px' }}>
                    {data.total_contracts.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: '14px', color: '#737373', marginTop: '-5px' }}
                  >
                    reais
                  </Typography>
                </GridListTile>
              </GridList>
            </Grid>

            <Grid item xs={4} style={{ padding: 15 }}>
              <GridList cellHeight={90} className={classes.gridList}>
                <GridListTile
                  key="icon"
                  style={{
                    width: '30%',
                    backgroundColor: '#4caf50',
                    textAlign: 'center',
                    paddingTop: '24px',
                  }}
                >
                  <TimerIcon style={{ fontSize: '50px', color: '#fff' }} />
                </GridListTile>
                <GridListTile
                  key="icon2"
                  style={{
                    width: '70%',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    border: '1px solid #f3f3f3',
                    borderLeft: 'none',
                    paddingRight: '12px',
                    paddingTop: '15px',
                  }}
                >
                  <Typography variant="h5" component="h5" style={{ fontSize: '37px' }}>
                    {data.time}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: '14px', color: '#737373', marginTop: '-5px' }}
                  >
                    horas
                  </Typography>
                </GridListTile>
              </GridList>
            </Grid>

            <Grid item xs={4} style={{ padding: 15 }}>
              <GridList cellHeight={90} className={classes.gridList}>
                <GridListTile
                  key="icon"
                  style={{
                    width: '30%',
                    backgroundColor: '#4caf50',
                    textAlign: 'center',
                    paddingTop: '24px',
                  }}
                >
                  <DeviceOtherIcon style={{ fontSize: '50px', color: '#fff' }} />
                </GridListTile>
                <GridListTile
                  key="icon2"
                  style={{
                    width: '70%',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    border: '1px solid #f3f3f3',
                    borderLeft: 'none',
                    paddingRight: '12px',
                    paddingTop: '15px',
                  }}
                >
                  <Typography variant="h5" component="h5" style={{ fontSize: '37px' }}>
                    {data.equipments}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: '14px', color: '#737373', marginTop: '-5px' }}
                  >
                    MioS
                  </Typography>
                </GridListTile>
              </GridList>
            </Grid>
          </Grid>
        )}

        {!loading && (
          <Grid container>
            <Grid item xs={12} style={{ padding: 15 }}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                {data.contracts.map((row, index) => (
                  <Table key={index} className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Contrato</TableCell>
                        <TableCell>Intervalo de Horas</TableCell>
                        <TableCell align="center">Valor/Hora</TableCell>
                        <TableCell align="center">Horas utilizadas</TableCell>
                        <TableCell align="center">Subtotal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.range.map((row2, index2) => (
                        <TableRow key={index2}>
                          <TableCell align="center">{row.contract_id}</TableCell>
                          <TableCell component="th" scope="row2">
                            De {row2.hours_min} a {row2.hours_max} horas
                          </TableCell>
                          <TableCell align="center">
                            {row2.price.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="center">{row2.hours_quantity}</TableCell>
                          <TableCell align="center">
                            {row2.subtotal.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="teste3">
                        <TableCell style={{ border: 'none' }}></TableCell>
                        <TableCell style={{ border: 'none' }}></TableCell>
                        <TableCell align="center" style={{ fontWeight: 700, border: 'none' }}>
                          Total
                        </TableCell>
                        <TableCell align="center" style={{ border: 'none' }}>
                          {row.total_hours}
                        </TableCell>
                        <TableCell align="center" style={{ fontWeight: 700, border: 'none' }}>
                          {row.total.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </MuiThemeProvider>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };
}

export default withStyles(styles, { withTheme: true })(Financial);
