import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withSnackbar } from 'notistack';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Person from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import EditDialog from '../base/EditDialog';
import PatientService from '../../../services/PatientService';
import ExamService from '../../../services/ExamService';
import TherapyService from '../../../services/TherapyService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatTableDate } from '../../../helpers/formatters';
import { ptBr } from '../../../helpers/MUIDataTableLabels';
import { formatStringToCpfCnpj, formatStringToPhone } from '../../../helpers/formatters';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class Patient extends Component {
  id = '';

  state = {
    value: 0,
    isConfirmDeleteOpen: false,
    isEditOpen: false,
    selectedId: 0,
    patientData: {},
    exams: [],
    therapies: [],
    loading: true,

    // exams table control
    examsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },

    // therapy table control
    therapyTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
  };

  tblExams = [
    {
      name: 'date_start',
      label: 'Data Início',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'date_end',
      label: 'Data Fim',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'clientName',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => this.formatType(value),
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  tblTherapies = [
    {
      name: 'date',
      label: 'Data de Realização',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'clientName',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'therapyType',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleEdit = (id) => (event) => {
    this.setState({
      selectedId: id,
      isEditOpen: true,
    });
  };

  handleCloseEdit = () => {
    this.setState({ isEditOpen: false });
    try {
      this.getDataPatient();
    } catch (error) {
      this.handleRequestError(error);
    }
  };

  handleDetailsClick = (id, type) => (event) => {
    this.props.history.push(type + '/' + id);
  };

  handleRemove = () => {
    this.setState({ isConfirmDeleteOpen: true });
  };

  handleAcceptConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });

    PatientService.remove(this.id).then(() => {
      this.props.history.goBack();
    });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });
  };

  // para evitar repetição do tratamento de erro
  handleRequestError(error) {
    console.log(error);
    this.props.enqueueSnackbar('Falha ao buscar dados do paciente!', {
      variant: 'error',
    });
  }

  formatType(value) {
    if (!value) return 'CurvaID'
    else return 'T.E.D.E'
  }

  getActionColumn = (id, type) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label="Detalhes" onClick={this.handleDetailsClick(id, type)}>
          <FindInPageOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  getDataPatient = () => {
    return new Promise((resolve, reject) => {
      PatientService.get(this.id)
        .then((data) => {
          // formata os acompanhantes para string
          const { companions } = data;
          let companionsString = '';

          if (companions && Array.isArray(companions)) {
            for (const [index, companion] of companions.entries()) {
              companionsString += companion.name;

              if (index !== companions.length - 1) {
                companionsString += ', ';
              }
            }
          }

          this.setState({
            patientData: { ...data, companionsString },
          });

          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getDataExamPatient = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.examsTable;

      ExamService.getListPatient(this.id, page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            exams: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getDataTherapyPatient = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.therapyTable;

      TherapyService.getListPatient(this.id, page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            therapies: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getData = async () => {
    try {
      this.setState({ loading: true });

      await this.getDataPatient();
      await this.getDataExamPatient();
      await this.getDataTherapyPatient();
    } catch (error) {
      this.handleRequestError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  setSortColumn = (columns, type) => {
    let sortColumn = columns.find((column) => {
      return !!column.sortDirection;
    });

    if (type === 'therapy') {
      this.setState({
        therapyTable: {
          ...this.state.therapyTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'exam') {
      this.setState({
        examsTable: {
          ...this.state.examsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    }
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        <span
          onClick={this.handleBreadcrumb('/pacientes')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Pacientes
        </span>
        {' > '}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Paciente</span>
      </h6>
    );
  }

  addAcoes(arr, type, checkType) {
    if (arr.count) {
      for (var i = 0; i < arr.list.length; i++) {
        if (checkType) {
          if (arr.list[i].type === 0) {
            arr.list[i].acoes = this.getActionColumn(arr.list[i].id, '/curvaID');
          } else {
            arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
          }
        } else {
          arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
        }
      }
    }
    return arr;
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.examsTable !== this.state.examsTable) {
        this.getDataExamPatient();
      }
      if (prevState.therapyTable !== this.state.therapyTable) {
        this.getDataTherapyPatient();
      }
    } catch (error) {
      this.handleRequestError(error);
    }
  }

  render = () => {
    const { classes } = this.props;
    const { value, loading, patientData, examsTable, therapyTable } = this.state;
    const id = this.id;
    const exams = this.addAcoes(this.state.exams, '/diagnostico', true);
    const therapies = this.addAcoes(this.state.therapies, '/terapia');

    const optionsExam = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.exams.count,
      page: examsTable.page,
      rowsPerPage: examsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              examsTable: {
                ...this.state.examsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              examsTable: {
                ...this.state.examsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'exam');
            break;
          default:
            break;
        }
      },
    };

    const optionsTherapy = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.therapies.count,
      page: therapyTable.page,
      rowsPerPage: therapyTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              therapyTable: {
                ...this.state.therapyTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              therapyTable: {
                ...this.state.therapyTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'therapy');
            break;
          default:
            break;
        }
      },
    };

    return (
      <div>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
        >
          Paciente - {patientData.name}
        </Typography>
        <Person style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />
        <Fab
          color="default"
          size="small"
          onClick={this.handleRemove}
          style={{ float: 'right', marginRight: 10, marginTop: 8 }}
        >
          <DeleteIcon />
        </Fab>
        <Fab
          color="default"
          size="small"
          onClick={this.handleEdit(this.id)}
          style={{ float: 'right', marginRight: 10, marginTop: 8 }}
        >
          <EditIcon />
        </Fab>

        {loading && <CircularProgress />}

        {!loading && (
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
              >
                <Tab label="Dados Pessoais" />
                <Tab label="Tratamentos" />
                <Tab label="Diagnósticos" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Nome: {patientData.name}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Cliente: {patientData.clientName}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Identificador: {patientData.identifier}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Data de Nascimento: {patientData.birthDate}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Data do cadastro: {patientData.registerDate}
                    </Paper>
                  </Grid>
                  {patientData.email ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Email: {patientData.email}</Paper>
                    </Grid>
                  ) : null}
                  {patientData.document ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Documento: {formatStringToCpfCnpj(patientData.document)}</Paper>
                    </Grid>
                  ) : null}
                  {patientData.phone ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Telefone: {formatStringToPhone(patientData.phone)}</Paper>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Sexo: {patientData.sex === 'M' ? 'Masculino' : 'Feminino'}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Possui marca-passo? {patientData.pacemaker ? 'Sim' : 'Não'}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      É epilético? {patientData.epileptic ? 'Sim' : 'Não'}
                    </Paper>
                  </Grid>
                  {patientData.sex === 'F' && (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Está grávida? {patientData.pregnant ? 'Sim' : 'Não'}
                      </Paper>
                    </Grid>
                  )}
                  {patientData.healthy_plan ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Plano de saúde: {patientData.healthy_plan}
                      </Paper>
                    </Grid>
                  ) : null}
                  {patientData.ventilation_start ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Início da ventilação: {patientData.ventilation_start}
                      </Paper>
                    </Grid>
                  ) : null}
                  {patientData.ventilation_end ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Fim da ventilação: {patientData.ventilation_end}
                      </Paper>
                    </Grid>
                  ) : null}
                  {patientData.uti_entry ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Entrada na UTI: {patientData.uti_entry}
                      </Paper>
                    </Grid>
                  ) : null}
                  {patientData.uti_reason ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Razão de entrada na UTI: {patientData.uti_reason}
                      </Paper>
                    </Grid>
                  ) : null}
                  {patientData.record ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Prontuário: {patientData.record}</Paper>
                    </Grid>
                  ) : null}
                  {patientData.notes ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Observação: {patientData.notes}</Paper>
                    </Grid>
                  ) : null}
                  {patientData.companionsString ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Acompanhantes: {patientData.companionsString}</Paper>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Número de Tratamentos: {therapies.count}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Número de Diagnósticos: {exams.count}</Paper>
                  </Grid>
                </Grid>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <MUIDataTable
                  title={
                    therapies.count ? (
                      <div>
                        Tratamento -{' '}
                        <a href={`/relatorio/terapia/${id}`}>ver relatório horizontal</a>
                      </div>
                    ) : (
                      <div>Tratamento</div>
                    )
                  }
                  data={therapies.list}
                  columns={this.tblTherapies}
                  options={optionsTherapy}
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={
                    exams.count ? (
                      <div>
                        Diagnósticos -{' '}
                        <a href={`/relatorio/diagnostico/${id}`}>ver relatório horizontal</a>
                      </div>
                    ) : (
                      <div>Diagnósticos</div>
                    )
                  }
                  data={exams.list}
                  columns={this.tblExams}
                  options={optionsExam}
                />
              </TabContainer>
            )}
          </div>
        )}

        <EditDialog
          edit="patients/PatientEdit"
          title="Editar Paciente"
          id={this.state.selectedId}
          isOpen={this.state.isEditOpen}
          onCloseCallBack={this.handleCloseEdit}
        />

        <Dialog
          open={this.state.isConfirmDeleteOpen}
          onClose={this.handleCloseConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Remover o paciente'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja realmente remover o paciente?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAcceptConfirmDelete} color="primary">
              Sim
            </Button>
            <Button onClick={this.handleCloseConfirmDelete} color="primary" autoFocus>
              Não
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

Patient.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(Patient));
