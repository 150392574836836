export default {
  diagnosticos: {
    P: "Visualizar diagnósticos",
    default: "Visualizar e gerenciar diagnósticos dos pacientes"
  },
  protocolos: {
    P: "Visualizar protocolos",
    default: "Visualizar e gerenciar protocolos dos pacientes"
  },
  tratamentos: {
    P: "Visualizar tratamentos",
    default: "Visualizar e gerenciar os tratamentos dos pacientes"
  },
  relatorios: {
    P: "Visualizar Relatórios Horizontais",
    default: "Visualizar e gerenciar os Relatórios Horizontais"
  },
  agendamentos: {
    P: "Visualizar e acompanhar os agendamentos de atendimento",
    default: "Visualizar e gerenciar os agendamentos de atendimento."
  },
}
