import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ptBr } from '../../../helpers/MUIDataTableLabels';
import ExamService from '../../../services/ExamService';
import TherapyService from '../../../services/TherapyService';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class Reports extends Component {
  state = {
    value: 0,
    terapias_equipments_all: [],
    terapias_equipments_pacients_all: [],
    exames_equipments_all: [],
    exames_equipments_pacients_all: [],
    loading: true,

    therapyEquipmentsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
    examEquipmentsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
    therapyPatientsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
    examPatientsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
  };

  tblEquipments = [
    {
      name: 'serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastdays',
      label: 'Total nos últimos 7 dias',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  tblPatients = [
    {
      name: 'name',
      label: 'Paciente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'hospital',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  componentDidMount() {
    this.getData();
  }

  getDataTherapy = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } =
        this.state.therapyEquipmentsTable;

      TherapyService.getListEquipmentAll(page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            terapias_equipments_all: data,
          });
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  getDataTherapyPatient = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } =
        this.state.therapyPatientsTable;

      TherapyService.getListEquipmentPacientAll(
        page,
        pageSize,
        searchText,
        sortColumn,
        sortDirection
      )
        .then((data) => {
          this.setState({
            terapias_equipments_pacients_all: data,
          });
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  getDataExam = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } =
        this.state.examEquipmentsTable;

      ExamService.getListEquipmentAll(page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            exames_equipments_all: data,
          });
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  getDataExamPatient = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } =
        this.state.examPatientsTable;

      ExamService.getListEquipmentPacientAll(page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            exames_equipments_pacients_all: data,
          });
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  getData = async () => {
    try {
      this.setState({ loading: true });

      await this.getDataTherapy();
      await this.getDataTherapyPatient();
      await this.getDataExam();
      await this.getDataExamPatient();
    } catch (error) {
      this.handleRequestError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Relatórios</span>
      </h6>
    );
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  setSortColumn = (columns, type) => {
    let sortColumn = columns.find((column) => {
      return !!column.sortDirection;
    });

    if (type === 'therapyEquipments') {
      this.setState({
        therapyEquipmentsTable: {
          ...this.state.therapyEquipmentsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'examEquipments') {
      this.setState({
        examEquipmentsTable: {
          ...this.state.examEquipmentsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'therapyPatients') {
      this.setState({
        therapyPatientsTable: {
          ...this.state.therapyPatientsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'examPatients') {
      this.setState({
        examPatientsTable: {
          ...this.state.examPatientsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    }
  };

  handleRequestError(error) {
    console.log(error);
    this.props.enqueueSnackbar('Falha ao buscar dados dos relatórios!', {
      variant: 'error',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.therapyEquipmentsTable !== this.state.therapyEquipmentsTable) {
        this.getDataTherapy();
      }
      if (prevState.examEquipmentsTable !== this.state.examEquipmentsTable) {
        this.getDataExam();
      }
      if (prevState.therapyPatientsTable !== this.state.therapyPatientsTable) {
        this.getDataTherapyPatient();
      }
      if (prevState.examPatientsTable !== this.state.examPatientsTable) {
        this.getDataExamPatient();
      }
    } catch (error) {
      this.handleRequestError(error);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      value,
      loading,
      exames_equipments_all,
      terapias_equipments_all,
      exames_equipments_pacients_all,
      terapias_equipments_pacients_all,
      therapyEquipmentsTable,
      examEquipmentsTable,
      therapyPatientsTable,
      examPatientsTable,
    } = this.state;

    const optionsTherapyEquipment = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.terapias_equipments_all.count,
      page: therapyEquipmentsTable.page,
      rowsPerPage: therapyEquipmentsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              therapyEquipmentsTable: {
                ...this.state.therapyEquipmentsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              therapyEquipmentsTable: {
                ...this.state.therapyEquipmentsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'therapyEquipments');
            break;
          default:
            break;
        }
      },
    };

    const optionsExamEquipment = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.exames_equipments_all.count,
      page: examEquipmentsTable.page,
      rowsPerPage: examEquipmentsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              examEquipmentsTable: {
                ...this.state.examEquipmentsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              examEquipmentsTable: {
                ...this.state.examEquipmentsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'examEquipments');
            break;
          default:
            break;
        }
      },
    };

    const optionsTherapyPatient = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.terapias_equipments_pacients_all.count,
      page: therapyPatientsTable.page,
      rowsPerPage: therapyPatientsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              therapyPatientsTable: {
                ...this.state.therapyPatientsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              therapyPatientsTable: {
                ...this.state.therapyPatientsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'therapyPatients');
            break;
          default:
            break;
        }
      },
    };

    const optionsExamPatient = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.exames_equipments_pacients_all.count,
      page: examPatientsTable.page,
      rowsPerPage: examPatientsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              examPatientsTable: {
                ...this.state.examPatientsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              examPatientsTable: {
                ...this.state.examPatientsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'examPatients');
            break;
          default:
            break;
        }
      },
    };

    return (
      <div>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
        >
          Relatórios
        </Typography>
        <BarChartIcon style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />

        {loading && <CircularProgress />}

        {!loading && (
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
              >
                <Tab label="Tratamentos por equipamento" />
                <Tab label="Tratamentos por paciente" />
                <Tab label="Diagnósticos por equipamento" />
                <Tab label="Diagnósticos por paciente" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <MUIDataTable
                  title={'Tratamentos'}
                  data={terapias_equipments_all.list}
                  columns={this.tblEquipments}
                  options={optionsTherapyEquipment}
                />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <MUIDataTable
                  title={'Tratamentos'}
                  data={terapias_equipments_pacients_all.list}
                  columns={this.tblPatients}
                  options={optionsTherapyPatient}
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={'Diagnósticos'}
                  data={exames_equipments_all.list}
                  columns={this.tblEquipments}
                  options={optionsExamEquipment}
                />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <MUIDataTable
                  title={'Diagnósticos'}
                  data={exames_equipments_pacients_all.list}
                  columns={this.tblPatients}
                  options={optionsExamPatient}
                />
              </TabContainer>
            )}
          </div>
        )}
      </div>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(Reports));
