import { createMuiTheme } from '@material-ui/core/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../constants';

export const formTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        width: '100%',
      },
    },
    MuiFormLabel: {
      root: {
        padding: '0px 6px',
      }
    },
    MuiFormControl: {
      root: {
        margin: '4px 0px',
        padding: '0px 6px',
        width: '100%',
      },
    },
    MuiSwitch: {
      bar: {
        '$checked$checked + &': {
          opacity: 0.5,
          backgroundColor: PRIMARY_COLOR,
        },
      },
      iconChecked: {
        color: PRIMARY_COLOR,
      },
    },
  },
});
