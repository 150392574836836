import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

import ClientService from '../../../services/ClientService';
import DistributorService from '../../../services/DistributorService';
import AuthService from '../../../services/AuthService';
import Roles from '../../../helpers/roles';
import EquipmentTypeService from '../../../services/product/EquipmentTypeService';
import EquipmentService from '../../../services/product/EquipmentService';
import PatientService from '../../../services/PatientService';

class BaseEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      isDialog: false,
      isLoading: false,
      bodyLoading: false,
      model: {
        id: null,
      },
      clients: [],
      equipments: [],
      selectedEquipments: [],
      distributors: [],
      equipmentTypes: [],
      patients: []
    };
  }

  _isMounted = false;

  options = {
    description: '',
    service: null,
  };

  async componentWillMount() {
    if (this._isMounted) return;

    let id = null;

    if (this.props && this.props.match) {
      id = this.props.match.params.id;

      this.setState({
        id,
        isDialog: false,
      });
    } else if (typeof this.props.id !== 'undefined') {
      id = this.props.id;
      this.setState({
        id,
        isDialog: true,
      });
    }

    this.setState({ bodyLoading: true });

    let clients = [];
    let equipments = [];
    let distributors = [];
    let equipmentTypes = [];
    let patients = [];

    if (id) this.get(id);

    if (this.state.getClients) {
      clients = await this.getListData(ClientService);
    }

    if (this.state.getDistributors) {
      distributors = await this.getListData(DistributorService);
    }

    if (this.state.getEquipments) {
      equipments = await this.getListData(EquipmentService);
    }

    if (this.state.getEquipmentsType) {
      equipmentTypes = await this.getListData(EquipmentTypeService);
    }

    if (this.state.getPatients ){
      patients = await this.getListData(PatientService)
    }

    this.setState({
      clients,
      equipments,
      distributors,
      equipmentTypes,
      patients,
      bodyLoading: false
    })
  }

  controlRole(role) {
    if (Roles[role].indexOf(this.options.module) === -1) {
      this.props.history.push('/');
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    if (this.controlRole(AuthService.getRole())) {
      if (this.props.onRef) this.props.onRef(this);
    }

    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
    this._isMounted = false;
  }

  getListData = (service) => {


    return new Promise((resolve, reject) => {
      let page = 0;
      let pageSize = null; // puxa todos, sem paginação
      let tableState = {
        searchText: null,
        sortColumn: null,
        sortDirection: null,
      };

      service.list(
        page,
        pageSize,
        tableState.searchText,
        tableState.sortColumn,
        tableState.sortDirection
      ).then((data) => {
        this.doAfterGetListData(data).then(() => {
          resolve(data.list)
        });
      }).catch((err) => reject(err));
    });
  }

  doAfterGetListData(data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  //doRender() {
  //    throw new Error("Not implemented exception");
  //}

  doBeforeSave() {
    return new Promise((resolve, reject) => {
      resolve(this.state.model);
    });
  }

  doAfterLoadData(message, type) { }

  doAfterGet(data) { }

  get = (id) => {
    this.setState({
      isLoading: true,
    });

    this.options.service.get(id).then((data) => {
      this.setState({
        model: data,
      });

      this.doAfterGet(data);
    }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  save = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isLoading: true,
      });

      this.doBeforeSave()
        .then((model) => {
          if (this.state.id) {
            this.options.service
              .edit(this.state.id, model)
              .then((data) => {
                this.setState({
                  model: data,
                  isLoading: false,
                });

                this.doAfterLoadData('Alterado com sucesso...', 'success');

                resolve();
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                });

                if (error.hasOwnProperty('message')) {
                  this.doAfterLoadData(error.message, 'error');
                } else {
                  this.doAfterLoadData('Erro ao alterar os dados!', 'error');
                }

                if (this.props.history && this.props.history.goBack) {
                  this.props.history.goBack();
                }

                reject(error);
              });
          } else {
            this.options.service
              .save(model)
              .then((data) => {
                this.setState({
                  model: data,
                  isLoading: false,
                });

                this.doAfterLoadData('Salvo com sucesso...', 'success');

                resolve();
              })
              .catch((error) => {

                this.setState({
                  isLoading: false,
                });

                if (error.hasOwnProperty('message')) {
                  this.doAfterLoadData(error.message, 'error');
                } else {
                  this.doAfterLoadData('Erro ao salvar os dados!', 'error');
                }

                reject(error);
              });
          }
        })
        .catch(reject)
        .finally(() => this.setState({ isLoading: false }));
    });
  };

  handleSaveEdit = () => {
    this.save();
  };

  handleBackEdit = () => {
    this.props.history.goBack();
  };

  buttons = () => {
    const { isLoading } = this.state;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: '8px', position: 'relative' }}>
          <Button onClick={this.handleSaveEdit} color="primary" disabled={isLoading}>
            Salvar
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </div>
        <div style={{ margin: '8px', position: 'relative' }}>
          <Button autoFocus onClick={this.handleBackEdit} color="primary" disabled={isLoading}>
            Voltar
          </Button>
        </div>
      </div>
    );
  };

  render = () => {
    const EditRender = this.doRender;
    const EditButtons = this.buttons;
    const { bodyLoading } = this.state;

    return (
      <div>
        {bodyLoading ? (
          <CircularProgress />
        ) : (
          <>
            <EditRender />
            {!this.state.isDialog && <EditButtons />}
          </>
        )}
      </div>
    );
  };
}

export default BaseEdit;
