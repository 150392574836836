import React from "react";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },
});

class ListCompanionsContent extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedCompanionId: ''
    }
  }

  handleSelectCompanion(event) {
    this.setState({
      selectedCompanionId: event.target.value
    })
  }

  handleConfirmCompanion() {
    if (!this.state.selectedCompanionId) {
      return;
    }

    const {
      companions,
      handleCloseDialog,
      onConfirmCompanion,
    } = this.props;

    // puxa os dados do acompanhante e retorna para o componente pai
    const companionData = companions.find(
      (companion) => companion.id === this.state.selectedCompanionId
    );

    handleCloseDialog();
    onConfirmCompanion(companionData);
  }

  render() {
    const {
      classes,
      handleCancel,
      companions,
    } = this.props;

    const { selectedCompanionId } = this.state;

    return (
      <>
        <Typography variant="h6" style={{ textAlign: 'center' }} >
          Selecionar Acompanhante
        </Typography>
        <FormControl className={classes.formControl} style={{ marginTop: 16 }}>
          <InputLabel shrink htmlFor="companion-label-placeholder">
            Acompanhante
          </InputLabel>
          <Select
            value={selectedCompanionId || ''}
            onChange={(event) => this.handleSelectCompanion(event)}
            inputProps={{
              name: 'companionId',
              id: 'companion-label-placeholder',
            }}
            className={classes.selectEmpty}
          >
            {companions.map((companion) => (
              <MenuItem key={companion.id} value={companion.id}>
                {companion.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.buttonContainer}>
          <Button color="primary" variant="outlined" style={{ marginRight: '16px' }} onClick={() => handleCancel()}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={() => this.handleConfirmCompanion()} >
            Confirmar
          </Button>
        </div>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ListCompanionsContent);

