import React from 'react';
import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import PatientService from '../../../services/PatientService';
import { withStyles } from '@material-ui/core/styles';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import IconButton from '@material-ui/core/IconButton';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

const styles = (theme) => ({});

class ReportsList extends ListBase {
  options = {
    description: 'Paciente - Relatório Horizontal',
    descriptionPlural: 'Pacientes - Relatórios Horizontais',
    icon: AirlineSeatFlatIcon,
    edit: 'patients/PatientEdit',
    details: '/paciente',
    module: 'relatorios-horizontais',
    service: PatientService,
    addColumnAction: true,
    hasNew: false,
    hasDetails: true,
    hasEdit: false,
    hasRemove: false,
    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'name',
        label: 'Nome',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'identifier',
        label: 'Identificador',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'birthDate',
        label: 'Data de Nascimento',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'hospital',
        label: 'Cliente',
        options: {
          display: true,
          filter: true,
          sort: true,
        },
      },
    ],
  };

  getActionColumn = () => {
    const labelStyle = { margin: '0px 2px', fontSize: '12px' };
    const iconButtonContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '-8px',
      marginTop: '-8px',
    };

    return {
      name: 'id',
      label: 'Relatórios Horizontais',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
              <IconButton
                aria-label="Detalhes"
                className={this.props.classes.button}
                onClick={() =>
                  this.props.history.push('/relatorio/terapia/' + tableMeta.rowData[0])
                }
              >
                <div style={iconButtonContainerStyle}>
                  <FindInPageOutlinedIcon />
                  <p style={labelStyle}>Tratamentos</p>
                </div>
              </IconButton>
              <IconButton
                aria-label="Detalhes"
                className={this.props.classes.button}
                onClick={() =>
                  this.props.history.push('/relatorio/diagnostico/' + tableMeta.rowData[0])
                }
              >
                <div style={iconButtonContainerStyle}>
                  <FindInPageOutlinedIcon />
                  <p style={labelStyle}>Diagnósticos</p>
                </div>
              </IconButton>
            </div>
          );
        },
        setCellProps: (value) => {
          return {
            width: 100,
            'text-align': 'center',
          };
        },
      },
    };
  };
}

ReportsList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ReportsList);
