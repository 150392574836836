import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import ReactToPrint from 'react-to-print';
import MUIDataTable from 'mui-datatables';

import Typography from '@material-ui/core/Typography';
import TimelineIcon from '@material-ui/icons/Timeline';
import InfoIcon from '@material-ui/icons/Info';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip2 from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExamService from '../../../services/ExamService';
import IDCurvePrint from '../../print/IDCurvePrint';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip2);

class Protocolo extends Component {
  id = '';

  constructor() {
    super();

    this.state = {
      value: 0,
      loading: false,
      data: {
        patientName: '',
        patientCPF: '',
        patientAge: 0,
        userName: '',
        date_start: '',
        date_end: '',
        clientName: '',
        data: {
          muscles: [],
        },
      },
    };
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  async getData() {
    try {
      this.setState({ loading: true });
      const data = await ExamService.get(this.id);
      const muscles = data.data.muscles;

      if (!Array.isArray(muscles)) {
        throw new Error('muscles must be an array!');
      }

      data.patientAge = this.getAgeFromBirthDate(data.patientBirthDate);

      for (const muscle of muscles) {
        if (!Array.isArray(muscle.points)) {
          throw new Error('muscle.points must be an array!');
        }

        const sortedPoints = muscle.points.sort((p1, p2) => (p1.duration >= p2.duration ? -1 : 1));
        muscle.points = sortedPoints;
      }

      this.setState({ data });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  getStyle(alert) {
    if (alert) {
      return { backgroundColor: 'rgba(187,17,5,0.5)' };
    }
  }

  handleClick(event) {
    event.preventDefault();
  }

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  getAgeFromBirthDate = (birthDateString) => {
    if (typeof birthDateString !== 'string') {
      return '';
    }

    const today = new Date();
    const birthDate = new Date(birthDateString);
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (birthDate instanceof Date && !isNaN(birthDate)) {
      // valid Date
      let age = today.getFullYear() - birthDate.getFullYear();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age + ' Anos';
    } else {
      return '';
    }
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        <span
          onClick={this.handleBreadcrumb('/curvasID')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Curvas ID
        </span>
        {' > '}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Curva ID</span>
      </h6>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          textColorInherit: {
            minWidth: 100,
            '&$selected': {
              color: '#4caf50',
            },
          },
        },
        MuiTabs: {
          indicator: {
            backgroundColor: '#4caf50',
          },
        },
        MUIDataTableBodyCell: {
          root: {
            textAlign: 'center',
          },
        },
        MuiToolbar: {
          regular: {
            minHeight: '8px',
            backgroundColor: 'rgba(223, 223, 223, 1)',
            '@media (min-width: 600px)': {
              minHeight: '8px',
            },
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: 'rgba(223, 223, 223, 1)',
            padding: '0px 0px',
            '&:last-child': {
              padding: '0px 0px',
            },
          },
          root: {
            textAlign: 'center',
            padding: '0px 0px',
            '&:last-child': {
              textAlign: 'center',
              padding: '0px 0px',
            },
          },
        },
        MuiTableCell: {
          head: {
            fontWeight: 600,
          },
          root: {
            paddingRight: '0px',
            paddingLeft: '0px',
            '&:last-child': {
              textAlign: 'center',
              paddingRight: '0px',
              paddingLeft: '0px',
            },
          },
        },
        MUIDataTableBodyRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#f5f5f5',
            },
            textAlign: 'center',
            padding: '4px 0px',
            '&:last-child': {
              textAlign: 'center',
              padding: '4px 0px',
            },
          },
        },
        MUIDataTableResponsiveScroll: {
          maxHeight: 'none',
        },
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: '300px',
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

  render = () => {
    const { value, loading } = this.state;
    const { styleP } = { marginTop: '10px', marginBottom: '10px' };
    const result = this.state.data;

    let exam = [];

    for (var i = 0; i < result.data.muscles.length; i++) {
      let exam_data = {};
      exam_data['column'] = result.data.muscles[i].muscle_initials;
      exam_data['desc'] = result.data.muscles[i].muscle_name;
      exam_data['electrode_material'] = result.data.muscles[i].electrode_material;
      exam_data['electrode_format'] = result.data.muscles[i].electrode_format;
      exam_data['electrode_width'] = result.data.muscles[i].electrode_size;
      exam_data['data'] = [];

      if (result.data.muscles[i].hasOwnProperty('points')) {
        for (var j = 0; j < result.data.muscles[i].points.length; j++) {
          exam_data['data'].push({
            'Duração (μs)': result.data.muscles[i].points[j].pulse_width,
            'Intensidade (mA)': result.data.muscles[i].points[j].intensity,
          });
        }
      }

      exam.push(exam_data);
    }

    const columns = exam;

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload) {
        return (
          <div
            style={{ backgroundColor: '#fff', border: '1px solid #D3D3D3', padding: '5px 15px' }}
          >
            <span className="intro">Intensidade (mA): {payload[0].value} </span>
            <br />
            <span className="intro">Duração (μs): {label}</span>
          </div>
        );
      }

      return null;
    };

    const tableOptions = {
      download: false,
      filter: false,
      pagination: false,
      print: false,
      rowsPerPageOptions: [],
      search: false,
      sort: false,
      selectableRows: false,
      viewColumns: false,
      responsive: 'scroll',
    };

    const tableColumns = [
      {
        name: 'Duração (μs)',
        label: 'Duração (μs)',
      },
      {
        name: 'Intensidade (mA)',
        label: 'Intensidade (mA)',
      },
    ];

    const pdfIDCurveData = {
      result: result,
      muscles: columns,
    };

    return (
      <div>
        {this.breadcrumb()}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', paddingTop: 8, margin: '0 3px 0 20px' }}
            >
              Diagnóstico - Curva Intensidade Duração
            </Typography>
            <TimelineIcon style={{ float: 'left', margin: '6px 10px 10px 0', fontSize: 30 }} />
          </div>

          {loading ? (
            <CircularProgress />
          ) : (
            <ReactToPrint
              trigger={() => (
                <IconButton
                  style={{
                    borderRadius: '32px',
                    marginRight: '16px',
                    padding: '0px 8px',
                    border: '1px solid #FF0000',
                  }}
                >
                  <PictureAsPdfIcon
                    style={{ marginRight: '8px', fontSize: 22, color: '#FF0000' }}
                  />
                  <p style={{ fontSize: 14, margin: '8px 0px', color: '#FF0000' }}>Exportar PDF</p>
                </IconButton>
              )}
              content={() => this.componentRef}
              documentTitle={'Curva ID - ' + pdfIDCurveData.result.patientName}
            />
          )}
        </div>

        <Grid container>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: '15px 0 0 0',
                color: '#fff',
                fontWeight: '500',
                margin: '0 10px 10px 0',
                textAlign: 'center',
                backgroundColor: '#4caf50',
                fontSize: '14px',
              }}
            >
              DADOS GERAIS
              <div
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  fontWeight: '400',
                  fontSize: '13px',
                }}
              >
                <p style={{ paddingTop: '15px' }}>Paciente: {result.patientName}</p>
                <Divider />
                <p>Idade: {result.patientAge}</p>
                <Divider />
                <p style={styleP}>Documento: {result.patientCPF}</p>
                <Divider />
                <p style={{ paddingBottom: '15px' }}>Data: {result.date_start}</p>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Paper style={{ width: '100%' }}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="standard"
                scrollButtons="auto"
                centered
              >
                {columns.map((column) => (
                  <Tab key={column.column} label={column.column} />
                ))}
              </Tabs>
            </AppBar>
            {columns.map(
              (column, index) =>
                value === index && (
                  <TabContainer key={index} style={{ width: '100%' }}>
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                      {column.desc}{' '}
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              <b>Eletrodo Utilizado</b>
                            </Typography>
                            <Typography color="inherit">
                              Tipo: {column.electrode_material}
                            </Typography>
                            <Typography color="inherit">
                              Formato: {column.electrode_format}
                            </Typography>
                            <Typography color="inherit">
                              Tamanho: {column.electrode_width}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            data={column.data}
                            columns={tableColumns}
                            options={tableOptions}
                            style={{ maxHeight: '300px' }}
                          />
                        </MuiThemeProvider>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <ResponsiveContainer width="100%" minHeight="300px">
                          <LineChart
                            data={column.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Duração (μs)" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={36} />
                            <Line type="monotone" dataKey="Intensidade (mA)" stroke="#344feb" />
                          </LineChart>
                        </ResponsiveContainer>
                      </Grid>
                    </Grid>
                  </TabContainer>
                )
            )}
          </Paper>
        </MuiThemeProvider>

        {/* componente de PDF da curva ID */}
        <div style={{ display: 'none' }}>
          <IDCurvePrint ref={(el) => (this.componentRef = el)} data={pdfIDCurveData} />
        </div>
      </div>
    );
  };
}

export default Protocolo;
