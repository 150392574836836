import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import logo from './../imgs/logo.png';
import AuthService from '../services/AuthService';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: AuthService.getLastLogin(),
      password: '',
      rememberMe: false,
      isLoading: false,
      connectionRetries: 0,
    };

    if (this.state.email) this.state.rememberMe = true;

    PubSub.subscribe('ajax-connectionRetries', (message, connectionRetries) => {
      if (connectionRetries > 0) {
        this.props.enqueueSnackbar('Erro de conexão. Tentativas: ' + connectionRetries, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      }
    });
  }

  componentWillMount = () => {};

  handlePasswordDefinition = () => {
    this.props.history.push('password-definition');
  };

  updateValue = (name, e) => {
    var newState = {};
    newState[name] = e.target.value;

    this.setState(newState);
  };

  updateChecked = (name, e) => {
    var newState = {};
    newState[name] = e.target.checked;

    this.setState(newState);
  };

  login = (e) => {
    e.preventDefault();

    if (this.state.isLoading) return;

    this.setState({ isLoading: true });

    AuthService.login(this.state.email, this.state.password)
      .then((userInfo) => {
        // se for senha padrao, redireciona para a tela de redefinir senha
        if (userInfo.password_default) {
          this.props.history.push('/password-definition');

          this.props.enqueueSnackbar(
            `Bem-vindo ${userInfo.name}! Defina uma nova senha para prosseguir.`,
            {
              variant: 'info',
            }
          );

          return;
        }

        this.props.history.push('/');

        this.props.enqueueSnackbar(`Bem-vindo a Visuri ${userInfo.name}!!!`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        const errorMessage =
          error && error.responseJSON && error.responseJSON.message
            ? error.responseJSON.message
            : 'E-mail ou Senha incorretos';

        this.props.enqueueSnackbar(errorMessage, {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />

        <Paper className={classes.paper}>
          <LinearProgress
            style={{ width: '100%', visibility: this.state.isLoading ? 'visible' : 'hidden' }}
          />
          <div className={classes.avatar}>
            <img src={logo} style={{ height: 130 }} alt="VISURI" />
          </div>

          <form className={classes.form} onSubmit={this.login}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email || ''}
                onInput={this.updateValue.bind(this, 'email')}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Senha</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onInput={this.updateValue.bind(this, 'password')}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
            <p style={{ textAlign: 'center', margin: '32px 0px 0px 0px' }} >
              <a href="/password-request">Esqueci Minha Senha</a>
            </p>
          </form>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
