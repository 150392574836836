import BaseCRUDService from './BaseCRUDService';
import AuthService from './AuthService';
import ajax from '../helpers/ajax';

class AppointmentService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/appointment';
  };

  uncheckedAppointment(id) {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/appointment-unchecked',
        type: 'DELETE',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          id,
        },
        success: (result, textStatus, jqXHR) => {
          resolve();
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  }
}

export default new AppointmentService();
