import BaseCRUDService from '../BaseCRUDService';
import { ajax } from '../../helpers/ajax';
import AuthService from '../../services/AuthService';
class EquipmentService extends BaseCRUDService {
  getBaseURL = () => {
    return '~/api/companion';
  };

  deleteCompanionPatientRelation = (companionId, patientId) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/companion-patient',
        type: 'DELETE',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          companionId,
          patientId,
        },
        success: (result, textStatus, jqXHR) => {
          resolve();
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => { },
      });
    });
  }

  createCompanionPatientRelation = (companionId, patientId) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: '~/api/companion-patient',
        type: 'POST',
        headers: { 'x-access-token': AuthService.getToken() },
        data: {
          companionId,
          patientId,
        },
        success: (result, textStatus, jqXHR) => {
          resolve();
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          reject(errorThrown);
        },
        complete: () => { },
      });
    });
  }
}

export default new EquipmentService();
