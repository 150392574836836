import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Code from '@material-ui/icons/Code';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import UserService from '../../../services/security/UserService';
import { withSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '15px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
  title: {
    padding: '15px',
    'font-size': '18px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
    width: '100%',
  },
  formControl: {
    marginTop: 20,
    marginLeft: 10,
  },
  selectEmpty: {
    marginTop: 10,
    minWidth: 250,
  },
  container: {
    alignSelf: 'center',
  },
});

class ExternalAPIs extends Component {
  state = {
    data: '',
    isLoading: false,
    value: 0
  };

  componentDidMount() {
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getData() {
    this.setState({ isLoading: true });
    UserService.getUserClient()
      .then((data) => {
        this.setState({
          data: data,
        });
      })
      .catch((err) => {
        this.props.enqueueSnackbar('Erro ao buscar dados da API!', {
          variant: 'error',
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp; <span style={{ color: 'rgba(152, 149, 149, 1)' }}>APIs Externas</span>
      </h6>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGrid: {
          container: {
            padding: 15,
            border: '1px solid #d3d3d3',
            backgroundColor: '#fff',
            marginBottom: 20,
            marginTop: 10,
          },
        },
        MuiPaper: {
          root: {
            fontSize: 14,
          },
        },
        MuiButton: {
          outlinedPrimary: {
            color: '#4caf50',
            border: '1px solid rgba(76, 175, 80, 0.5)',
          },
        },
        MuiInput: {
          root: {
            marginRight: 16,
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

  render = () => {
    const { classes } = this.props;
    const profile = this.state.data;
    const { value, isLoading } = this.state;
    const arrayExamplePatient = [
      {
          "id": "105e4714-da66-45c8-b11e-65657e675368",
          "name": "Paciente Teste 1",
          "identifier": "01",
          "birthDate": "15/11/1980",
          "registerDate": "15/04/2021",
          "sex": "F",
          "pregnant": true,
          "pacemaker": false,
          "epileptic": false,
          "healthy_plan": null,
          "ventilation_start": null,
          "ventilation_end": null,
          "uti_entry": null,
          "uti_reason": null,
          "record": null,
          "notes": null,
          "email": null,
          "document": null,
          "phone": null
      },
      {
          "id": "59dd24f0-1a2e-419d-9b4d-d35950164cb8",
          "name": "Paciente Teste 2",
          "identifier": "01111",
          "birthDate": "15/04/1989",
          "registerDate": "19/04/2021",
          "sex": "F",
          "pregnant": true,
          "pacemaker": true,
          "epileptic": true,
          "healthy_plan": null,
          "ventilation_start": null,
          "ventilation_end": null,
          "uti_entry": null,
          "uti_reason": null,
          "record": null,
          "notes": null,
          "email": null,
          "document": null,
          "phone": null
      }
    ];

    const arrayExampleTreatment = [
      {
          "id": "6f12198c-8dd5-4e73-b775-f8821ae6fabf",
          "date": "02/05/2021 19:20",
          "therapyType": "Não se aplica",
          "patientId": "26194ec4-944d-44af-b7bb-fc175bf2e8e1",
          "patientName": "Teste Terapia",
          "patientCPF": "12842701",
          "data": {
              "muscles": [
                  {
                      "id": 37,
                      "channel": 1,
                      "muscle_name": "Vasto Lateral Direito",
                      "muscle_initials": "VLE",
                      "pulse_width": 350,
                      "initial_intensity": 18,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          12,
                          12,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18,
                          18
                      ]
                  },
                  {
                      "id": 38,
                      "channel": 2,
                      "muscle_name": "Vasto Lateral Esquerdo",
                      "muscle_initials": "VLD",
                      "pulse_width": 350,
                      "initial_intensity": 22,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          16,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22,
                          22
                      ]
                  },
                  {
                      "id": 39,
                      "channel": 3,
                      "muscle_name": "Vasto Medial Direito",
                      "muscle_initials": "VME",
                      "pulse_width": 350,
                      "initial_intensity": 21,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          12,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21
                      ]
                  },
                  {
                      "id": 40,
                      "channel": 4,
                      "muscle_name": "Vasto Medial Esquerdo",
                      "muscle_initials": "VMD",
                      "pulse_width": 350,
                      "initial_intensity": 19,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          10,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19
                      ]
                  },
                  {
                      "id": 41,
                      "channel": 5,
                      "muscle_name": "Tibial Anterior Direito",
                      "muscle_initials": "TAE",
                      "pulse_width": 350,
                      "initial_intensity": 14,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14
                      ]
                  },
                  {
                      "id": 42,
                      "channel": 6,
                      "muscle_name": "Tibial Anterior Esquerdo",
                      "muscle_initials": "TAD",
                      "pulse_width": 350,
                      "initial_intensity": 21,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          12,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21
                      ]
                  },
                  {
                      "id": 43,
                      "channel": 7,
                      "muscle_name": "Isquiotibiais Direito",
                      "muscle_initials": "ISE",
                      "pulse_width": 350,
                      "initial_intensity": 16,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16,
                          16
                      ]
                  },
                  {
                      "id": 44,
                      "channel": 8,
                      "muscle_name": "Isquiotibiais Esquerdo",
                      "muscle_initials": "ISD",
                      "pulse_width": 350,
                      "initial_intensity": 20,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20,
                          20
                      ]
                  },
                  {
                      "id": 45,
                      "channel": 9,
                      "muscle_name": "Tríceps Sural Direito",
                      "muscle_initials": "TSE",
                      "pulse_width": 350,
                      "initial_intensity": 21,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21
                      ]
                  },
                  {
                      "id": 46,
                      "channel": 10,
                      "muscle_name": "Tríceps Sural Esquerdo",
                      "muscle_initials": "TSD",
                      "pulse_width": 350,
                      "initial_intensity": 19,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19
                      ]
                  }
              ]
          },
          "frequency": 120,
          "mode": null,
          "treatment_stim_number": null,
          "treatment_stim_number_done": 55,
          "off_time": null,
          "rise_time": 5000,
          "lift_time": 5000,
          "falling_time": 5000,
          "on_time": 15000,
          "duty_cycle": "10,00",
          "status": null,
          "warmup_stim_number": null,
          "warmup_duration": null,
          "treatment_duration": null,
          "treatment_duration_done": null,
          "stim_time": null,
          "stim_time_done": 550000,
          "pulse_width": 350,
          "app_version": null,
          "states": null,
          "warnings": {
              "pressao": null,
              "frequencia": null,
              "temperatura": null,
              "cpk": null,
              "plaquetas": null,
              "hemoglobina": null,
              "hipotireoidismo": null,
              "trombose": null,
              "fio": null,
              "pao": null,
              "peep": null,
              "ph": null,
              "lactato": null,
              "noradrenalina": null,
              "vasopressina": null,
              "dobutamina": null,
              "milrinona": null,
              "levosimendan": null
          },
          "protocol": {
              "protocol_id": null,
              "protocol_info": null,
              "protocol_name": null,
              "protocol_warmup_duration": null,
              "protocol_recovery_duration": null,
              "protocol_duration": null,
              "protocol_interval": null,
              "off_duration": null,
              "protocol_type": null,
              "protocol_stimulus": null,
              "protocol_warmup_parameters": null,
              "protocol_recovery_parameters": null,
              "protocol_phase": null
          },
          "recovery": {
              "activity": null,
              "minutes": null,
              "recovery_date": null,
              "pse": null,
              "psr": null,
              "dmt": null,
              "psr_feedback": null,
              "dmt_feedback": null,
              "recovery_type": null
          }
      },
      {
          "id": "71face54-2ac7-42dc-a951-ed3f4afac0b9",
          "date": "30/04/2021 01:27",
          "therapyType": "Não se aplica",
          "patientId": "59dd24f0-1a2e-419d-9b4d-d35950164cb8",
          "patientName": "Visuri Teste",
          "patientCPF": "01111",
          "data": {
              "muscles": [
                  {
                      "id": 13,
                      "channel": 1,
                      "muscle_name": "Vasto Lateral Direito",
                      "muscle_initials": "VLE",
                      "pulse_width": 350,
                      "initial_intensity": 27,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27,
                          27
                      ]
                  },
                  {
                      "id": 14,
                      "channel": 2,
                      "muscle_name": "Bíceps Braquial Esquerdo",
                      "muscle_initials": "BBD",
                      "pulse_width": 350,
                      "initial_intensity": 14,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14
                      ]
                  },
                  {
                      "id": 15,
                      "channel": 3,
                      "muscle_name": "Vasto Medial Direito",
                      "muscle_initials": "VME",
                      "pulse_width": 350,
                      "initial_intensity": 24,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24,
                          24
                      ]
                  },
                  {
                      "id": 16,
                      "channel": 4,
                      "muscle_name": "Grande Dorsal Esquerdo",
                      "muscle_initials": "GDD",
                      "pulse_width": 350,
                      "initial_intensity": 17,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17,
                          17
                      ]
                  },
                  {
                      "id": 17,
                      "channel": 5,
                      "muscle_name": "Tibial Anterior Direito",
                      "muscle_initials": "TAE",
                      "pulse_width": 350,
                      "initial_intensity": 21,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21,
                          21
                      ]
                  },
                  {
                      "id": 18,
                      "channel": 6,
                      "muscle_name": "Reto Abdominal Esquerdo",
                      "muscle_initials": "RAD",
                      "pulse_width": 350,
                      "initial_intensity": 12,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12,
                          12
                      ]
                  },
                  {
                      "id": 19,
                      "channel": 7,
                      "muscle_name": "Isquiotibiais Direito",
                      "muscle_initials": "ISE",
                      "pulse_width": 350,
                      "initial_intensity": 32,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32
                      ]
                  },
                  {
                      "id": 20,
                      "channel": 8,
                      "muscle_name": "Tríceps Braquial Esquerdo",
                      "muscle_initials": "TBD",
                      "pulse_width": 350,
                      "initial_intensity": 15,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15,
                          15
                      ]
                  },
                  {
                      "id": 21,
                      "channel": 9,
                      "muscle_name": "Tríceps Sural Direito",
                      "muscle_initials": "TSE",
                      "pulse_width": 350,
                      "initial_intensity": 29,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29,
                          29
                      ]
                  },
                  {
                      "id": 22,
                      "channel": 10,
                      "muscle_name": "Deltóide Esquerdo",
                      "muscle_initials": "DTD",
                      "pulse_width": 350,
                      "initial_intensity": 14,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14,
                          14
                      ]
                  },
                  {
                      "id": 23,
                      "channel": 11,
                      "muscle_name": "Glúteos Direito",
                      "muscle_initials": "GLE",
                      "pulse_width": 350,
                      "initial_intensity": 32,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32,
                          32
                      ]
                  },
                  {
                      "id": 24,
                      "channel": 12,
                      "muscle_name": "Extensores Do Punho Esquerdo",
                      "muscle_initials": "EPD",
                      "pulse_width": 350,
                      "initial_intensity": 19,
                      "initial_impedance": 0,
                      "initial_contraction_level": 0,
                      "electrode_area": 0,
                      "max_intensity": 0,
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": "",
                      "stimulus": [
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19,
                          19
                      ]
                  }
              ]
          },
          "frequency": 50,
          "mode": null,
          "treatment_stim_number": null,
          "treatment_stim_number_done": 50,
          "off_time": null,
          "rise_time": 5000,
          "lift_time": 2000,
          "falling_time": 5000,
          "on_time": 12000,
          "duty_cycle": "14,00",
          "status": null,
          "warmup_stim_number": null,
          "warmup_duration": null,
          "treatment_duration": null,
          "treatment_duration_done": null,
          "stim_time": null,
          "stim_time_done": 200000,
          "pulse_width": 350,
          "app_version": null,
          "states": null,
          "warnings": {
              "pressao": null,
              "frequencia": null,
              "temperatura": null,
              "cpk": null,
              "plaquetas": null,
              "hemoglobina": null,
              "hipotireoidismo": null,
              "trombose": null,
              "fio": null,
              "pao": null,
              "peep": null,
              "ph": null,
              "lactato": null,
              "noradrenalina": null,
              "vasopressina": null,
              "dobutamina": null,
              "milrinona": null,
              "levosimendan": null
          },
          "protocol": {
              "protocol_id": null,
              "protocol_info": null,
              "protocol_name": null,
              "protocol_warmup_duration": null,
              "protocol_recovery_duration": null,
              "protocol_duration": null,
              "protocol_interval": null,
              "off_duration": null,
              "protocol_type": null,
              "protocol_stimulus": null,
              "protocol_warmup_parameters": null,
              "protocol_recovery_parameters": null,
              "protocol_phase": null
          },
          "recovery": {
              "activity": null,
              "minutes": null,
              "recovery_date": null,
              "pse": null,
              "psr": null,
              "dmt": null,
              "psr_feedback": null,
              "dmt_feedback": null,
              "recovery_type": null
          }
      }
    ];

    const arrayExampleDiagnostic = [
      {
          "id": "8ee3ec2b-2612-4d78-9dd1-ee0f7c2b527c",
          "date_start": "19/05/2021 22:20",
          "date_end": "19/05/2021 22:59",
          "muscles": {
              "muscles": [
                  {
                      "id": 2,
                      "muscle_name": "Bíceps Braquial Direito",
                      "muscle_initials": "1",
                      "alert": 2,
                      "channel": "1",
                      "chronaxie": 1000,
                      "rheobase": 4,
                      "accommodation": 22,
                      "accommodation_index": 5,
                      "electrode_area": 45,
                      "max_intensity": 240,
                      "electrode_format": "Retangular",
                      "electrode_size": "9cm x 5cm"
                  },
                  {
                      "id": 3,
                      "muscle_name": "Bíceps Braquial Esquerdo",
                      "muscle_initials": "2",
                      "alert": 0,
                      "channel": "2",
                      "chronaxie": 800,
                      "rheobase": 7,
                      "accommodation": 7,
                      "accommodation_index": 1,
                      "electrode_area": 45,
                      "max_intensity": 240,
                      "electrode_format": "Retangular",
                      "electrode_size": "9cm x 5cm"
                  },
                  {
                      "id": 4,
                      "muscle_name": "Tríceps Braquial Direito",
                      "muscle_initials": "7",
                      "alert": 0,
                      "channel": "7",
                      "chronaxie": 500,
                      "rheobase": 8,
                      "accommodation": 8,
                      "accommodation_index": 1,
                      "electrode_area": 25,
                      "max_intensity": 240,
                      "electrode_material": "Autoadesivo",
                      "electrode_format": "Retangular",
                      "electrode_size": "5cm x 5cm"
                  },
                  {
                      "id": 5,
                      "muscle_name": "Tríceps Braquial Esquerdo",
                      "muscle_initials": "8",
                      "alert": 0,
                      "channel": "8",
                      "chronaxie": 700,
                      "rheobase": 5,
                      "accommodation": 5,
                      "accommodation_index": 1,
                      "electrode_area": 25,
                      "max_intensity": 240,
                      "electrode_format": "Retangular",
                      "electrode_size": "5cm x 5cm"
                  }
              ]
          },
          "patientId": "1bc718ee-7646-4736-9d8b-0529f95f2cf0",
          "patientName": "Patrick melo",
          "type": 1,
          "instrument": 1
      },
      {
          "id": "d2aaa158-41e9-4ce3-a82c-c862f00140c1",
          "date_start": "10/05/2021 15:29",
          "date_end": "10/05/2021 15:32",
          "muscles": {
              "muscles": [
                  {
                      "id": 3,
                      "muscle_name": "Cgf Direito",
                      "muscle_initials": "VLE",
                      "alert": 2,
                      "channel": "1",
                      "chronaxie": 65000,
                      "rheobase": 40,
                      "accommodation": 56,
                      "accommodation_index": 1,
                      "electrode_area": 65,
                      "max_intensity": 240,
                      "electrode_format": "Retangular",
                      "electrode_size": "13cm x 5cm"
                  }
              ]
          },
          "patientId": "59dd24f0-1a2e-419d-9b4d-d35950164cb8",
          "patientName": "Visuri Teste",
          "type": 1,
          "instrument": 1
      },
      {
          "id": "4824c7cd-917c-49b1-be8e-64ea7ef07477",
          "date_start": "10/05/2021 14:04",
          "date_end": "10/05/2021 14:05",
          "muscles": {
              "muscles": [
                  {
                      "id": 1,
                      "muscle_name": "Vasto Lateral Direito",
                      "muscle_initials": "VLE",
                      "alert": 0,
                      "channel": "1",
                      "chronaxie": 350,
                      "rheobase": 28,
                      "accommodation": 78,
                      "accommodation_index": 2,
                      "electrode_area": 65,
                      "max_intensity": 240,
                      "electrode_format": "Retangular",
                      "electrode_size": "13cm x 5cm"
                  }
              ]
          },
          "patientId": "59dd24f0-1a2e-419d-9b4d-d35950164cb8",
          "patientName": "Visuri Teste",
          "type": 1,
          "instrument": 1
      },
      {
          "id": "1dce0e0b-de2b-4d86-83a7-9d51a24e306a",
          "date_start": "29/04/2021 22:20",
          "date_end": "29/04/2021 23:45",
          "muscles": {
              "muscles": [
                  {
                      "id": 83,
                      "muscle_name": "Vasto Lateral Direito",
                      "muscle_initials": "VLE",
                      "alert": 0,
                      "channel": "1",
                      "chronaxie": 600,
                      "rheobase": 13,
                      "accommodation": 27,
                      "accommodation_index": 2,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  },
                  {
                      "id": 84,
                      "muscle_name": "Vasto Lateral Esquerdo",
                      "muscle_initials": "VLD",
                      "alert": 0,
                      "channel": "2",
                      "chronaxie": 700,
                      "rheobase": 23,
                      "accommodation": 39,
                      "accommodation_index": 1,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  },
                  {
                      "id": 85,
                      "muscle_name": "Vasto Medial Direito",
                      "muscle_initials": "VME",
                      "alert": 0,
                      "channel": "3",
                      "chronaxie": 800,
                      "rheobase": 18,
                      "accommodation": 31,
                      "accommodation_index": 1,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  },
                  {
                      "id": 86,
                      "muscle_name": "Vasto Medial Esquerdo",
                      "muscle_initials": "VMD",
                      "alert": 2,
                      "channel": "4",
                      "chronaxie": 1250,
                      "rheobase": 16,
                      "accommodation": 28,
                      "accommodation_index": 1,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  },
                  {
                      "id": 87,
                      "muscle_name": "Tibial Anterior Direito",
                      "muscle_initials": "TAE",
                      "alert": 0,
                      "channel": "5",
                      "chronaxie": 600,
                      "rheobase": 14,
                      "accommodation": 36,
                      "accommodation_index": 2,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  },
                  {
                      "id": 88,
                      "muscle_name": "Tibial Anterior Esquerdo",
                      "muscle_initials": "TAD",
                      "alert": 0,
                      "channel": "6",
                      "chronaxie": 500,
                      "rheobase": 15,
                      "accommodation": 39,
                      "accommodation_index": 2,
                      "electrode_area": "",
                      "max_intensity": "",
                      "electrode_material": "",
                      "electrode_format": "",
                      "electrode_size": ""
                  }
              ]
          },
          "patientId": "105e4714-da66-45c8-b11e-65657e675368",
          "patientName": "Teste Tede",
          "type": 1,
          "instrument": 1
      },
    ];

    const arrayExampleIDCurve = [
      {
        "id": "6d716fc6-aa25-4d9c-8270-7328e0f12c0d",
        "date_start": "21/11/2023 13:23",
        "date_end": "21/11/2023 13:23",
        "muscles": [
          {
            "id":509,
            "muscle_name":"Bíceps Braquial Esquerdo",
            "muscle_initials":"BBE",
            "alert":0,
            "channel":"13",
            "electrode_material":"Caneta",
            "max_intensity":0,
            "points":[
              {
                "intensity":8,
                "pulse_width":200
              }
            ]
          },
        ],
        "patientId": "b99ed524-21e8-4637-b691-4854728243b1",
        "patientName": "Teste Paciente Curva ID",
        "type": 0,
        "instrument": null
      }
    ]


    return (
      <div className={classes.root}>
        {this.breadcrumb()}

        <div className={classes.container}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
          >
            APIs externas
          </Typography>
          <Code style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />

          <MuiThemeProvider theme={this.getMuiTheme()}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.title}>Dados utilizados para autenticação nas APIs</Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>Id do Cliente: {profile.id}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>Token de autenticação: {profile.token}</Paper>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.title}>Como deve ser utilizado?</Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>É necessário adicionar ao header da requisição as variáveis <b>client-id</b> e <b>x-access-token</b>.</Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>Exemplo de curl: <br />
                    curl --location 'http://vision.visuri.com.br/api/v2/client/diagnostic' \
                    --header 'x-access-token: {profile.token}' \
                    --header 'client-id: {profile.id}'
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.title}>Serviços disponíveis</Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={value}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="standard"
                          scrollButtons="auto"
                        >
                          <Tab label="Paciente" />
                          <Tab label="Tratamento" />
                          <Tab label="Diagnóstico" />
                          <Tab label="Curva ID" />
                        </Tabs>
                      </AppBar>
                      {value === 0 && (
                        <TabContainer>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Endpoint: http://vision.visuri.com.br/api/v2/client/patient</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/patient' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Response: 
                                <pre>{ JSON.stringify(arrayExamplePatient, null, 4)}</pre>
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Filtro opcional: patient_id</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/patient?patient_id=59dd24f0-1a2e-419d-9b4d-d35950164ca0' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                          </Grid>
                        </TabContainer>
                      )}
                      {value === 1 && (
                        <TabContainer>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Endpoint: http://vision.visuri.com.br/api/v2/client/treatment</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/treatment' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Response: 
                                <pre>{ JSON.stringify(arrayExampleTreatment, null, 4)}</pre>
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Filtro opcional: patient_id, date_initial, date_final</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/treatment?patient_id=26194ec4-944d-44af-b7bb-fc175bf2e8e1&date_initial=02%2F05%2F2021&date_final=02%2F05%2F2023' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                          </Grid>
                        </TabContainer>
                      )}
                      {value === 2 && (
                        <TabContainer>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Endpoint: http://vision.visuri.com.br/api/v2/client/diagnostic</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/diagnostic' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Response: 
                                <pre>{ JSON.stringify(arrayExampleDiagnostic, null, 4)}</pre>
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Filtro opcional: patient_id, date_initial, date_final</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/diagnostic?patient_id=26194ec4-944d-44af-b7bb-fc175bf2e8e1&date_initial=02%2F05%2F2021&date_final=02%2F05%2F2023' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                          </Grid>
                        </TabContainer>
                      )}
                      {value === 3 && (
                        <TabContainer>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Endpoint: http://vision.visuri.com.br/api/v2/client/id-curve</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/id-curve' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Response: 
                                <pre>{ JSON.stringify(arrayExampleIDCurve, null, 4)}</pre>
                              </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Filtro opcional: patient_id, date_initial, date_final</Paper>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Paper className={classes.paper}>Exemplo: <br />
                                curl --location 'http://vision.visuri.com.br/api/v2/client/id-curve?patient_id=26194ec4-944d-44af-b7bb-fc175bf2e8e1&date_initial=02%2F05%2F2021&date_final=02%2F05%2F2023' \
                                --header 'x-access-token: {profile.token}' \
                                --header 'client-id: {profile.id}'
                              </Paper>
                            </Grid>
                          </Grid>
                        </TabContainer>
                      )}
                  </Grid>
                </Grid>
              </>
            )}
          </MuiThemeProvider>
        </div>
      </div>
    );
  };
}

ExternalAPIs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(ExternalAPIs));
