import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import withStyles from '@material-ui/core/styles/withStyles';

import { calculateGraphOperation } from '../../helpers/formatters';
import visuriLogo from '../../imgs/logo-side.png';

class ExamReportPrint extends React.PureComponent {
  GRAPH_WIDTH = 650;
  GRAPH_HEIGHT = 250;
  GRAPH_MARGINS = { top: 5, left: 5, right: 20, bottom: 55 };

  CHRONAXIE_GRAPH_COLOR = '#00ff00';
  REOBASE_GRAPH_COLOR = '#ff0000';
  ACCOMODATION_GRAPH_COLOR = '#0000ff';
  FREQUENCY_GRAPH_COLOR = '#ff00ff';

  render() {
    const { classes } = this.props;
    const { result, musclesList, reobaseGraph, chronaxieGraph, accomodationGraph } =
      this.props.data;

    const shouldBreakPage = (index) => index > 0;
    const getPageNumberFromIndex = (index) => (index !== null ? index + 1 : 1);
    const getTotalNumberOfPages = () => musclesList.length;

    const PageHeader = ({ style, index = null }) => {
      const pageNumber = getPageNumberFromIndex(index);
      const totalNumberOfPages = getTotalNumberOfPages();

      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Relatório de Diagnósticos Horizontais</p>
              <p className={classes.rightText}>Paciente: {result.patient_name}</p>
              <p className={classes.rightText}>
                Página: {pageNumber} / {totalNumberOfPages}
              </p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    const CustomizedXAxisTick = ({ x, y, payload }) => (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
          style={{ fontSize: '10px' }}
        >
          {payload.value}
        </text>
      </g>
    );

    return (
      <div className={classes.page}>
        <PageHeader />

        <h3 className={classes.infoHeader}>Dados gerais</h3>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>NOME DO PACIENTE:</p>
              <p className={classes.infoGeneralText}>{result.patient_name}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText} style={{ flex: 2 }}>
                DOCUMENTO DO PACIENTE:
              </p>
              <p className={classes.infoGeneralText}>{result.patient_document}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>DATA DE NASCIMENTO:</p>
              <p className={classes.infoGeneralText}>{result.patient_birthdate}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>TOTAL DE TRATAMENTOS:</p>
              <p className={classes.infoGeneralText}>{result.total}</p>
            </div>
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>Hospital:</p>
              <p className={classes.infoGeneralText}>{result.client_name}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>PERÍODO DE ANÁLISE:</p>
              <p className={classes.infoGeneralText}>
                {moment(result.firstDate).format('DD/MM/YYYY HH:mm')} até{' '}
                {moment(result.lastDate).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoGeneralText}>IMPRESSO EM:</p>
              <p className={classes.infoGeneralText}>
                {moment(Date.now()).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
          </div>
        </div>

        <h3 className={classes.infoHeader}>Músculos</h3>

        {musclesList.map((muscle, index) => (
          <React.Fragment key={index}>
            {shouldBreakPage(index) && (
              <PageHeader
                style={{
                  pageBreakBefore: 'always',
                  marginTop: '20px',
                }}
                index={index}
              />
            )}

            <h3 className={classes.muscleTitle}>{muscle}</h3>

            <div className={classes.legendContainer}>
              <div className={classes.legendItem}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.CHRONAXIE_GRAPH_COLOR,
                    backgroundColor: this.CHRONAXIE_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Cronaxia
                </p>
              </div>
              <div className={classes.legendItem}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.REOBASE_GRAPH_COLOR,
                    backgroundColor: this.REOBASE_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>Reobase</p>
              </div>
              <div className={classes.legendItem}>
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: this.ACCOMODATION_GRAPH_COLOR,
                    backgroundColor: this.ACCOMODATION_GRAPH_COLOR,
                  }}
                />
                <p style={{ marginLeft: '16px', marginTop: '4px', marginBottom: '4px' }}>
                  Acomodação
                </p>
              </div>
            </div>

            {chronaxieGraph.length && chronaxieGraph[index].data && (
              <div className={classes.centerGraph}>
                <LineChart
                  data={chronaxieGraph[index].data}
                  width={this.GRAPH_WIDTH}
                  height={this.GRAPH_HEIGHT}
                  margin={this.GRAPH_MARGINS}
                  style={{ marginTop: '36px' }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                  <YAxis yAxisId="left">
                    <Label
                      angle={270}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                      offset={10}
                    >
                      Cronaxia
                    </Label>
                  </YAxis>
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="Cronaxia"
                    stroke={this.CHRONAXIE_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                </LineChart>
              </div>
            )}

            <div className={classes.rowGraphs}>
              {reobaseGraph.length && reobaseGraph[index].data && (
                <div className={classes.centerGraph}>
                  <LineChart
                    data={reobaseGraph[index].data}
                    width={this.GRAPH_WIDTH / 2}
                    height={this.GRAPH_HEIGHT}
                    margin={this.GRAPH_MARGINS}
                    style={{ marginTop: '36px' }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                    <YAxis yAxisId="left">
                      <Label
                        angle={270}
                        position="insideLeft"
                        style={{ textAnchor: 'middle' }}
                        offset={10}
                      >
                        Reobase
                      </Label>
                    </YAxis>
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="Reobase"
                      stroke={this.REOBASE_GRAPH_COLOR}
                      strokeWidth={3}
                    />
                  </LineChart>
                </div>
              )}

              {accomodationGraph.length && accomodationGraph[index].data && (
                <div className={classes.centerGraph}>
                  <LineChart
                    data={accomodationGraph[index].data}
                    width={this.GRAPH_WIDTH / 2}
                    height={this.GRAPH_HEIGHT}
                    margin={this.GRAPH_MARGINS}
                    style={{ marginTop: '36px' }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} tick={<CustomizedXAxisTick />} />
                    <YAxis yAxisId="left">
                      <Label
                        angle={270}
                        position="insideLeft"
                        style={{ textAnchor: 'middle' }}
                        offset={10}
                      >
                        Acomodação
                      </Label>
                    </YAxis>
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="Acomodação"
                      stroke={this.ACCOMODATION_GRAPH_COLOR}
                      strokeWidth={3}
                    />
                  </LineChart>
                </div>
              )}
            </div>

            <div className={classes.muscleHeaderInfo}>
              {chronaxieGraph.length && (
                <div className={classes.muscleInfo}>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Número de pontos:
                    </p>
                    <p className={classes.infoText}>{chronaxieGraph[index].data.length}</p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Data inicial:
                    </p>
                    <p className={classes.infoText}>{chronaxieGraph[index].data[0].name}</p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Data final:
                    </p>
                    <p className={classes.infoText}>
                      {chronaxieGraph[index].data[chronaxieGraph[index].data.length - 1].name}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Cronaxia máxima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(chronaxieGraph[index].data, 'max', 'Cronaxia')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Cronaxia mínima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(chronaxieGraph[index].data, 'min', 'Cronaxia')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Cronaxia média:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(chronaxieGraph[index].data, 'average', 'Cronaxia')}
                    </p>
                  </div>
                </div>
              )}

              {reobaseGraph.length && chronaxieGraph.length && accomodationGraph.length && (
                <div className={classes.muscleInfo}>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Reobase máxima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(reobaseGraph[index].data, 'max', 'Reobase')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Reobase mínima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(reobaseGraph[index].data, 'min', 'Reobase')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Reobase média:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(reobaseGraph[index].data, 'average', 'Reobase')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Acomodação máxima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(accomodationGraph[index].data, 'max', 'Acomodação')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Acomodação mínima:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(accomodationGraph[index].data, 'min', 'Acomodação')}
                    </p>
                  </div>
                  <div className={classes.infoLine}>
                    <p className={classes.infoText} style={{ flex: 2 }}>
                      Acomodação média:
                    </p>
                    <p className={classes.infoText}>
                      {calculateGraphOperation(
                        accomodationGraph[index].data,
                        'average',
                        'Acomodação'
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '2px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoGeneralText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoHeader: {
    textAlign: 'center',
  },
  centerGraph: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '-40px',
  },
  muscleTitle: {
    textAlign: 'center',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  muscleHeaderInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
  },
  muscleInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  legendContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '16px',
  },
  legendItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px',
  },
  rowGraphs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

ExamReportPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExamReportPrint);
