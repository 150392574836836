import React, { Component } from 'react';
import moment from 'moment';
import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import ReactToPrint from 'react-to-print';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { withSnackbar } from 'notistack';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import StatisticsPrint from '../../print/StatisticsPrint';
import EquipmentService from '../../../services/product/EquipmentService';
import ClientService from '../../../services/ClientService';
import { THERAPY_STATISTICS_GRAPH_COLOR, EXAM_STATISTICS_GRAPH_COLOR } from '../../../constants';

const BUTTONS_LIST_LABELS = [
  'Hoje',
  'Ontem',
  'Últimos 7 dias',
  'Últimos 30 dias',
  'Últimos 12 meses',
];

const TYPE_HOUR_MILISECONDS_THRESHOLD = 1728 * 10 ** 5; // 2 dias
const TYPE_DAY_MILISECONDS_THRESHOLD = 79488 * 10 ** 5; // 90 dias
const ONE_DAY_IN_MILISECONDS = 24 * 60 * 60 * 1000; // 1 dia

class Statics extends Component {
  id = '';
  screen = '';
  constructor() {
    super();

    this.state = {
      firstDate: {},
      lastDate: {},
      data: [],
      loading: false,

      showTreatment: true,
      showDiagnostic: true,

      BUTTONS_LIST_COLORS_STATE: Array.from(Array(6), () => true),

      list: [],
      deviceId: null,
      name: '',
      nameList: '',

      numberOfPatients: 0,
      totalPatientsTherapies: 0,
      totalPatientsExams: 0,
      clientName: '',
    };
  }

  setNameList(value) {
    this.setState({
      nameList: value,
    });
  }

  getClient() {
    ClientService.list(0, null, null, null, null)
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          var model = data.list.find((model) => model.id === Number(this.id));
          this.setDeviceId(model.id);
          this.setName(model.name);
          this.setState({
            list: data.list,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDeviceId = () => this.state.deviceId;

  getData() {
    this.setState({ loading: true });
    const id = this.getDeviceId();
    if (id !== null) {
      const queryParams = {
        firstDate: moment(this.state.firstDate).format('DD/MM/YYYY'),
        lastDate: moment(this.state.lastDate).format('DD/MM/YYYY'),
        type: this.getTypeFromDates(),
      };

      if (this.screen === 'estatisticasEquipamento') {
        EquipmentService.getStatistics(id, queryParams)
          .then((data) => this.doAfterGetData(data))
          .catch((err) => {
            console.error(err);
            this.props.enqueueSnackbar('Falha ao buscar estatísticas!', {
              variant: 'error',
            });
          })
          .finally(() => this.setState({ loading: false }));
      } else if (this.screen === 'estatisticasCliente') {
        ClientService.getStatistics(id, queryParams)
          .then((data) => this.doAfterGetData(data))
          .catch((err) => {
            console.error(err);
            this.props.enqueueSnackbar('Falha ao buscar estatísticas!', {
              variant: 'error',
            });
          })
          .finally(() => this.setState({ loading: false }));
      }
    } else {
      this.setState({ loading: false });
    }
  }

  getEquipments() {
    EquipmentService.list(0, null, null, null, null)
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          var model = data.list.find((model) => model.id === Number(this.id));
          this.setDeviceId(model.deviceId);
          this.setName(model.serial);
          this.setState({
            list: data.list,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.screen = this.props.match.params.screen;
    this.id = this.props.match.params.id;

    this.initializationOfDates();

    if (this.screen === 'estatisticasEquipamento') {
      this.setNameList('Equipamentos');
      this.getEquipments();
    } else if (this.screen === 'estatisticasCliente') {
      this.setNameList('Clientes');
      this.getClient();
    }

    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.firstDate !== this.state.firstDate ||
      prevState.lastDate !== this.state.lastDate
    ) {
      this.getData();
    }

    if (prevState.name !== this.state.name || prevState.deviceId !== this.state.deviceId) {
      this.getData();
    }
  }

  doAfterGetDate(data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getTypeFromDates() {
    const dateDifference = this.state.lastDate.valueOf() - this.state.firstDate.valueOf();
    if (dateDifference < 0) {
      return 'month';
    } else if (dateDifference <= TYPE_HOUR_MILISECONDS_THRESHOLD) {
      return 'hour';
    } else if (
      dateDifference >= TYPE_HOUR_MILISECONDS_THRESHOLD &&
      dateDifference <= TYPE_DAY_MILISECONDS_THRESHOLD
    ) {
      return 'day';
    } else if (dateDifference >= TYPE_DAY_MILISECONDS_THRESHOLD) {
      return 'month';
    } else {
      return 'month';
    }
  }

  doAfterGetData(data) {
    try {
      if (!data || !data.exams || !data.therapies) {
        throw new Error('Resposta da API deve conter data.exams e data.therapies');
      }

      const { exams, therapies } = data;

      if (!Array.isArray(exams) || !Array.isArray(therapies)) {
        throw new Error('exams e therapies devem ser arrays');
      }

      let formattedData = [];

      for (const [index, therapy] of therapies.entries()) {
        formattedData.push({
          date: this.formatDateByType(therapy[this.getTypeFromDates()]),
          therapies: Number(therapy.qtd),
          exams: Number(exams[index].qtd),
        });
      }

      this.setState({ numberOfPatients: data.totalPatients });
      this.setState({ data: formattedData });
      this.setState({ totalPatientsTherapies: data.totalPatientsTherapies });
      this.setState({ totalPatientsExams: data.totalPatientsExams });
      this.setState({ clientName: data.clientName });
    } catch (error) {
      console.error(error);
    }
  }

  initializationOfDates() {
    const now = Date.now();
    this.setFirstDate(new Date(now - 30 * ONE_DAY_IN_MILISECONDS));
    this.setLastDate(new Date(now));
    this.handleDateButtonClick('Últimos 30 dias');
  }

  formatDateByType(date) {
    switch (this.getTypeFromDates()) {
      case 'hour':
        return date + ':00';
      case 'day':
        return moment(date).format('DD/MM/YYYY');
      case 'month':
        return date;
      default:
        return date;
    }
  }

  firstDateFormat(date) {
    date.setDate(date.getDate());
    return date.toISOString();
  }

  lastDateFormat(date) {
    date.setDate(date.getDate());
    return date.toISOString();
  }

  setFirstDate = (value) => {
    this.setState({
      firstDate: value,
    });
  };

  setLastDate = (value) => {
    this.setState({
      lastDate: value,
    });
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  handleChangeFirstDate(value) {
    this.setFirstDate(value);
    this.handleDateButtonClick('Personalizado');
  }

  handleChangeLastDate(value) {
    this.setLastDate(value);
    this.handleDateButtonClick('Personalizado');
  }

  handleDateButtonClick = (period) => {
    const now = Date.now();

    let nextColors = [];
    nextColors = this.state.BUTTONS_LIST_COLORS_STATE.map((value, index) => {
      return true;
    });

    switch (period) {
      case 'Hoje':
        nextColors[0] = false;
        this.setFirstDate(new Date(now));
        this.setLastDate(new Date(now));
        break;

      case 'Ontem':
        nextColors[1] = false;
        this.setFirstDate(new Date(now - ONE_DAY_IN_MILISECONDS));
        this.setLastDate(new Date(now - ONE_DAY_IN_MILISECONDS));
        break;

      case 'Últimos 7 dias':
        nextColors[2] = false;
        this.setFirstDate(new Date(now - 7 * ONE_DAY_IN_MILISECONDS));
        this.setLastDate(new Date(now));
        break;
      case 'Últimos 30 dias':
        nextColors[3] = false;
        this.setFirstDate(new Date(now - 30 * ONE_DAY_IN_MILISECONDS));
        this.setLastDate(new Date(now));
        break;
      case 'Últimos 12 meses':
        nextColors[4] = false;
        this.setFirstDate(new Date(now - 365 * ONE_DAY_IN_MILISECONDS));
        this.setLastDate(new Date(now));
        break;
      case 'Personalizado':
        nextColors[5] = false;
        break;
      default:
        break;
    }

    this.setState({
      BUTTONS_LIST_COLORS_STATE: nextColors,
    });
  };

  handleChange = (value) => {
    if (this.screen === 'estatisticasEquipamento') {
      let model = this.state.list.find((model) => model.serial === value);
      this.setDeviceId(model.deviceId);
    } else if (this.screen === 'estatisticasCliente') {
      let model = this.state.list.find((model) => model.name === value);
      this.setDeviceId(model.id);
    }

    this.setName(value);
  };

  setDeviceId = (value) => {
    this.setState({
      deviceId: value,
    });
  };

  setName = (value) => {
    this.setState({
      name: value,
    });
  };

  getLabelFromType() {
    switch (this.getTypeFromDates()) {
      case 'hour':
        return 'Hora';
      case 'day':
        return 'Dia';
      case 'month':
        return 'Mês';
      default:
        break;
    }
  }

  breadcrumb(root) {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp;
        <span
          onClick={this.handleBreadcrumb('/' + root.toLowerCase())}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          {root}
        </span>{' '}
        {'>'}
        &nbsp;
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Estatísticas</span>
      </h6>
    );
  }

  render = () => {
    const { classes } = this.props;
    const {
      firstDate,
      lastDate,
      data,
      loading,
      showTreatment,
      showDiagnostic,
      BUTTONS_LIST_COLORS_STATE,
      list,
      name,
      nameList,
      numberOfPatients,
      totalPatientsTherapies,
      totalPatientsExams,
      clientName,
    } = this.state;

    var BUTTONS_LIST_BORDERCOLORS = [];
    var BUTTONS_LIST_COLORS = [];

    for (var i = 0; i < BUTTONS_LIST_COLORS_STATE.length; i++) {
      BUTTONS_LIST_BORDERCOLORS[i] = BUTTONS_LIST_COLORS_STATE[i] ? '#444' : '#34A60B';
      BUTTONS_LIST_COLORS[i] = BUTTONS_LIST_COLORS_STATE[i] ? 'default' : 'primary';
    }

    const pdfReportData = {
      name: name,
      firstDate: firstDate,
      lastDate: lastDate,
      diagnosticGraph: data,
      treatmentGraph: data,
      showTreatment: showTreatment,
      showDiagnostic: showDiagnostic,
      type: this.getLabelFromType(),
      numberOfPatients: numberOfPatients,
      totalPatientsExams: totalPatientsExams,
      totalPatientsTherapies: totalPatientsTherapies,
      clientName: clientName,
    };

    class LocalizedUtils extends DateFnsUtils {
      getDatePickerHeaderText(date) {
        return format(date, 'd MMM yyyy', { locale: this.locale });
      }
    }

    const CustomizedXAxisTick = ({ x, y, payload }) => (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-70)"
          style={{ fontSize: '10px' }}
        >
          {payload.value}
        </text>
      </g>
    );

    return (
      <div style={{ flexGrow: 1 }}>
        {this.breadcrumb(nameList)}

        <div className={classes.header}>
          <div>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
            >
              Estatisticas do {name}
            </Typography>
          </div>

          <ReactToPrint
            trigger={() => (
              <IconButton
                style={{
                  borderRadius: '32px',
                  marginRight: '16px',
                  padding: '0px 8px',
                  border: '1px solid #FF0000',
                }}
              >
                <PictureAsPdfIcon className={classes.iconPdf} />
                <p className={classes.textIcon}>Exportar PDF</p>
              </IconButton>
            )}
            content={() => this.componentRef}
            documentTitle={`Estatistica do ${name}`}
          />
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.container} style={{ backgroundColor: '#4caf50' }}>
              DADOS GERAIS
              <div
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  fontWeight: '400',
                  fontSize: '13px',
                }}
              >
                <p style={{ paddingTop: '15px', fontSize: '16px' }}>
                  Quantidade de pacientes ativos: {numberOfPatients}
                </p>
                <Divider />
                <p style={{ fontSize: '16px' }}>
                  Quantidade de pacientes que realizaram algum tratamento: {totalPatientsTherapies}
                </p>
                <Divider />
                <p style={{ fontSize: '16px' }}>
                  Quantidade de pacientes que realizaram algum diagnóstico: {totalPatientsExams}
                </p>
                <Divider />
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <FormControl className={classes.form}>
                <InputLabel htmlFor="equipment-label-placeholder">{nameList}</InputLabel>
                <Select
                  value={name || ''}
                  onChange={(e) => this.handleChange(e.target.value)}
                  inputProps={{
                    name: 'equipment',
                    id: 'equipment-label-placeholder',
                  }}
                >
                  {nameList === 'Clientes' &&
                    list.map((client, index) => (
                      <MenuItem key={client.id} value={client.name}>
                        {client.name}
                      </MenuItem>
                    ))}
                  {nameList === 'Equipamentos' &&
                    list.map((equipment, index) => (
                      <MenuItem key={equipment.id} value={equipment.serial}>
                        {equipment.serial}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider />
              <Grid container style={{ height: '70px', alignItems: 'center' }}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      value={firstDate}
                      onChange={(date) => this.handleChangeFirstDate(date)}
                      label="Início da Análise:"
                      maxDate={lastDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      value={lastDate}
                      onChange={(date) => this.handleChangeLastDate(date)}
                      label="Fim da Análise:"
                      minDate={firstDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <p style={{ margin: 6 }}></p>
                <Divider />
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <Paper className={classes.paperGraphs}>
            <ResponsiveContainer minHeight="340px" width="100%">
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 30,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" interval={0} tick={<CustomizedXAxisTick />} />
                <YAxis>
                  <Label
                    angle={270}
                    position="insideLeft"
                    style={{ textAnchor: 'middle' }}
                    offset={10}
                  >
                    Nº de Tratamentos / Diagnósticos
                  </Label>
                </YAxis>
                <Tooltip cursor={{ stroke: 'red', strokeWidth: 1 }} />
                <Legend verticalAlign="top" height={40} />
                {showTreatment && (
                  <Line
                    name="Tratamentos"
                    type="monotone"
                    dataKey="therapies"
                    stroke={THERAPY_STATISTICS_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                )}
                {showDiagnostic && (
                  <Line
                    name="Diagnósticos"
                    type="monotone"
                    dataKey="exams"
                    stroke={EXAM_STATISTICS_GRAPH_COLOR}
                    strokeWidth={3}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>

            <div className={classes.selection}>
              <Grid container>
                {BUTTONS_LIST_LABELS.map((label, index) => (
                  <Grid key={index} item xs={4}>
                    <Button
                      onClick={(e) => this.handleDateButtonClick(label)}
                      variant="outlined"
                      size="medium"
                      className={classes.button}
                      style={{ borderColor: BUTTONS_LIST_BORDERCOLORS[index] }}
                      color={BUTTONS_LIST_COLORS[index]}
                    >
                      {`${label}`}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <FormGroup style={{ flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => this.setState({ showTreatment: e.target.checked })}
                    />
                  }
                  label="Mostrar curva de tratamento"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => this.setState({ showDiagnostic: e.target.checked })}
                    />
                  }
                  label="Mostrar curva de diagnóstico"
                />
              </FormGroup>
            </div>
          </Paper>
        )}

        <div style={{ display: 'none' }}>
          <StatisticsPrint ref={(el) => (this.componentRef = el)} data={pdfReportData} />
        </div>
      </div>
    );
  };
}

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  iconPdf: {
    marginRight: '8px',
    fontSize: 22,
    color: '#FF0000',
  },
  textIcon: {
    fontSize: 14,
    margin: '8px 0px',
    color: '#FF0000',
  },
  container: {
    padding: '15px 0 0 0',
    color: '#fff',
    fontWeight: '500',
    margin: '0 10px 10px 0',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    fontSize: '14px',
  },
  form: {
    width: '35%',
    position: 'center',
  },
  paperGraphs: {
    display: 'flex',
    padding: '15px 0 0 0',
    fontWeight: '500',
    margin: '0 10px 10px 0',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    alignItems: 'center',
  },
  button: {
    textAlign: 'center',
    borderRadius: '6px',
    width: '200px',
    height: '50px',
  },
  selection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '32px',
  },
});

export default withStyles(styles, { withTheme: true })(withSnackbar(Statics));
