import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';

class EditDialog extends Component {
  child = null;
  state = {
    isLoading: false,
  };

  handleOpenEdit = () => {};

  handleSaveEdit = () => {
    this.setState({ isLoading: true });

    this.child
      .save()
      .then(() => {
        this.setState({ isLoading: false }, () => {
          this.props.onCloseCallBack();
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  handleCloseEdit = () => {
    this.props.onCloseCallBack();
  };

  _editComponent = null;

  getEditComponent = () => {
    if (this._editComponent == null) {
      this._editComponent = lazy(() => import('../' + this.props.edit));
    }

    return this._editComponent;
  };

  render = () => {
    if (!this.props.isOpen) return null;

    const { fullScreen, id, title } = this.props;
    const EditComponent = this.getEditComponent();
    const isLoading = this.state.isLoading;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.isOpen}
        onClose={this.handleCloseEdit}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{id ? title : 'Novo ' + title}</DialogTitle>
        <DialogContent>
          <Suspense fallback={<div>Carregando...</div>}>
            <EditComponent id={id} onRef={(ref) => (this.child = ref)} />
          </Suspense>
        </DialogContent>
        <DialogActions>
          <div style={{ margin: '8px', position: 'relative' }}>
            <Button
              variant="outlined"
              onClick={this.handleSaveEdit}
              color="primary"
              disabled={isLoading}
            >
              Salvar
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                style={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>

          <Button onClick={this.handleCloseEdit} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

EditDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(EditDialog);
