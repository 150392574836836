import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ExamService from '../../../services/ExamService';
import Button from '@material-ui/core/Button';
import { DatePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';
import CircularProgress from '@material-ui/core/CircularProgress';

import ReactToPrint from 'react-to-print';
import ExamReportPrint from '../../print/ExamReportPrint';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AuthService from '../../../services/AuthService';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

class ExamReport extends Component {
  id = '';
  role = '';

  constructor() {
    super();

    this.role = AuthService.getRole();
    this.state = {
      valueGraph1: 0,
      valueGraph2: 0,
      valueGraph3: 0,
      is_open: false,
      firstDate: new Date(),
      lastDate: new Date(),
      total: 0,
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  async getData() {
    await ExamService.getReport(this.id).then((result) => {
      if (!result.count) {
        this.setState({
          loading: false,
        });

        return;
      }

      this.setState({
        data: result.list,
        total: result.count,
        firstDate: new Date(this.formatDate(result.list[0].exam_date_full)) || new Date(),
        lastDate:
          new Date(this.formatDate(result.list[result.list.length - 1].exam_date_full)) ||
          new Date(),
        loading: false,
      });
    });
  }

  formatDate(date) {
    const data_formatada =
      date.substr(6, 4) +
      '-' +
      date.substr(3, 2) +
      '-' +
      date.substr(0, 2) +
      ' ' +
      date.substr(11, 5) +
      ':00';
    let dateFormat = new Date(data_formatada);
    dateFormat.setDate(dateFormat.getDate());
    return dateFormat.toISOString();
  }

  firstDateFormat(date) {
    date.setDate(date.getDate());
    return date.toISOString();
  }

  lastDateFormat(date) {
    date.setDate(date.getDate());
    return date.toISOString();
  }

  async getNewData() {
    await ExamService.getReport(
      this.id,
      this.firstDateFormat(this.state.firstDate),
      this.lastDateFormat(this.state.lastDate)
    ).then((result) => {
      this.setState({
        data: result.list,
        total: result.count,
      });
    });
  }

  secondsToHours(mili) {
    let sec = mili / 1000;
    let hours = sec / 3600;

    return parseFloat(hours);
  }

  secondsToHourMinutesSeconds(mili) {
    let sec = mili / 1000;
    let hours = Math.floor(sec / 3600);
    var totalSeconds = (sec %= 3600);
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    var therapy_time = '';
    if (hours) {
      therapy_time += hours + 'h ';
    }

    if (minutes) {
      therapy_time += minutes + 'm ';
    }

    if (seconds) {
      therapy_time += Math.round(seconds) + 's';
    }

    return therapy_time;
  }

  millisecondsToSeconds(millis) {
    return millis / 1000;
  }

  handleChange = (event, value, graph) => {
    switch (graph) {
      case 1:
        this.setState({ valueGraph1: value });
        break;
      case 2:
        this.setState({ valueGraph2: value });
        break;
      case 3:
        this.setState({ valueGraph3: value });
        break;
      default:
        break;
    }
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        {this.role !== 'P' ? (
          <>
            <span
              onClick={this.handleBreadcrumb('/pacientes')}
              style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
            >
              Pacientes
            </span>
            {' > '}
            <span
              onClick={this.handleBreadcrumb('/paciente/' + this.id)}
              style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
            >
              Paciente
            </span>
            {' > '}
          </>
        ) : null}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Diagnóstico - Relatório Horizontal</span>
      </h6>
    );
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          labelContainer: {
            padding: '6px 12px !important',
          },
          textColorInherit: {
            minWidth: 30,
            '&$selected': {
              color: '#4caf50',
            },
          },
        },
        MuiTabs: {
          indicator: {
            backgroundColor: '#4caf50',
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

  handleChangeText = (name) => (event) => {
    var state = this.state;
    state[name] = event.target.value;
    this.setState({ state });
  };

  handleFirstDateChange = (value) => {
    this.setState({
      firstDate: value,
    });
  };

  handleLastDateChange = (value) => {
    this.setState({
      lastDate: value,
    });
  };

  handleSearch = (event) => {
    this.getNewData();
  };

  render = () => {
    const { valueGraph1, valueGraph2, valueGraph3 } = this.state;
    const styleP = { fontSize: '16px' };
    const result = this.state.data;
    const total = this.state.total;
    const graphData = [];
    const firstDate = this.state.firstDate;
    const lastDate = this.state.lastDate;
    const { loading } = this.state;


    for (let i = 0; i < result.length; i++) {
      let exam_data = {};

      if (typeof graphData[result[i].exam_date] == 'undefined') {
        exam_data.exam_date = result[i].exam_date;
        exam_data.quantity = 1;
        exam_data.muscles = [];

        for (let j = 0; j < result[i].muscles.muscles.length; j++) {
          exam_data.muscles.push({
            muscle_initials: result[i].muscles.muscles[j].muscle_initials,
            muscle_name: result[i].muscles.muscles[j].muscle_name,
            rheobase: result[i].muscles.muscles[j].rheobase,
            accommodation: result[i].muscles.muscles[j].accommodation,
            chronaxie: result[i].muscles.muscles[j].chronaxie,
          });
        }
        graphData[result[i].exam_date] = exam_data;
      } else {
        let values = graphData[result[i].exam_date];
        values.quantity += 1;

        for (let j = 0; j < result[i].muscles.muscles.length; j++) {
          let index = values.muscles.findIndex(
            (val) => val.muscle_initials === result[i].muscles.muscles[j].muscle_initials
          );

          if (index < 0) {
            values.muscles.push({
              muscle_initials: result[i].muscles.muscles[j].muscle_initials,
              muscle_name: result[i].muscles.muscles[j].muscle_name,
              rheobase: result[i].muscles.muscles[j].rheobase,
              accommodation: result[i].muscles.muscles[j].accommodation,
              chronaxie: result[i].muscles.muscles[j].chronaxie,
            });
          } else {
            values.muscles[index].rheobase =
              (result[i].muscles.muscles[j].rheobase + values.muscles[index].rheobase) /
              values.quantity;
            values.muscles[index].accommodation =
              (result[i].muscles.muscles[j].accommodation + values.muscles[index].accommodation) /
              values.quantity;
            values.muscles[index].chronaxie =
              (result[i].muscles.muscles[j].chronaxie + values.muscles[index].chronaxie) /
              values.quantity;
          }
        }

        graphData[result[i].exam_date] = values;
      }
    }

    let exams = [];
    let muscles = [];
    Object.keys(graphData).forEach((element, index) => {
      exams[index] = graphData[element];

      for (var i = 0; i < graphData[element].muscles.length; i++) {
        let data_muscle = {};

        if (typeof muscles[graphData[element].muscles[i].muscle_initials] == 'undefined') {
          data_muscle.muscle_initials = graphData[element].muscles[i].muscle_initials;
          data_muscle.muscle_name = graphData[element].muscles[i].muscle_name;
          data_muscle.data = [];

          data_muscle.data.push({
            exam_date: graphData[element].exam_date,
            rheobase: graphData[element].muscles[i].rheobase,
            accommodation: graphData[element].muscles[i].accommodation,
            chronaxie: graphData[element].muscles[i].chronaxie,
            quantity: graphData[element].quantity,
          });

          muscles[graphData[element].muscles[i].muscle_initials] = data_muscle;
        } else {
          muscles[graphData[element].muscles[i].muscle_initials].data.push({
            exam_date: graphData[element].exam_date,
            rheobase: graphData[element].muscles[i].rheobase,
            accommodation: graphData[element].muscles[i].accommodation,
            chronaxie: graphData[element].muscles[i].chronaxie,
            quantity: graphData[element].quantity,
          });
        }
      }
    });

    let graph1 = [];
    let graph2 = [];
    let graph3 = [];

    Object.keys(muscles).forEach((element, index) => {
      let exam_data1 = {};
      let exam_data2 = {};
      let exam_data3 = {};
      exam_data1['column'] = muscles[element].muscle_initials;
      exam_data1['desc'] = muscles[element].muscle_name;
      exam_data2['column'] = muscles[element].muscle_initials;
      exam_data2['desc'] = muscles[element].muscle_name;
      exam_data3['column'] = muscles[element].muscle_initials;
      exam_data3['desc'] = muscles[element].muscle_name;
      exam_data1['data'] = [];
      exam_data2['data'] = [];
      exam_data3['data'] = [];

      for (var j = 0; j < muscles[element].data.length; j++) {
        exam_data1['data'].push({
          name: muscles[element].data[j].exam_date,
          Reobase: muscles[element].data[j].rheobase,
        });
        exam_data2['data'].push({
          name: muscles[element].data[j].exam_date,
          Cronaxia: muscles[element].data[j].chronaxie,
        });
        exam_data3['data'].push({
          name: muscles[element].data[j].exam_date,
          Acomodação: muscles[element].data[j].accommodation,
        });
      }
      graph1.push(exam_data1);
      graph2.push(exam_data2);
      graph3.push(exam_data3);
    });

    const CustomTooltip1 = ({ active, payload, label }) => {
      if (active && payload.length) {
        return (
          <div
            style={{ backgroundColor: '#fff', border: '1px solid #D3D3D3', padding: '5px 15px' }}
          >
            <span style={{ fontWeight: 600 }}>{label}</span>
            <br />
            <span className="intro">Reobase: {parseFloat(payload[0].value).toFixed(2)}</span>
          </div>
        );
      }

      return null;
    };

    const CustomTooltip2 = ({ active, payload, label }) => {
      if (active && payload.length) {
        return (
          <div
            style={{ backgroundColor: '#fff', border: '1px solid #D3D3D3', padding: '5px 15px' }}
          >
            <span style={{ fontWeight: 600 }}>{label}</span>
            <br />
            <span className="intro">Cronaxia: {parseFloat(payload[0].value).toFixed(2)}</span>
          </div>
        );
      }

      return null;
    };

    const CustomTooltip3 = ({ active, payload, label }) => {
      if (active && payload.length) {
        return (
          <div
            style={{ backgroundColor: '#fff', border: '1px solid #D3D3D3', padding: '5px 15px' }}
          >
            <span style={{ fontWeight: 600 }}>{label}</span>
            <br />
            <span className="intro">Acomodação: {parseFloat(payload[0].value).toFixed(2)}</span>
          </div>
        );
      }

      return null;
    };

    const pdfReportData = {
      result: {
        ...result[0],
        firstDate,
        lastDate,
        total,
      },
      reobaseGraph: graph1,
      chronaxieGraph: graph2,
      accomodationGraph: graph3,
      musclesList: graph1.map((column) => column.desc),
    };

    return (
      <div style={{ flexGrow: 1 }}>
        {this.breadcrumb()}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', paddingTop: 8, margin: '0 3px 0 20px' }}
            >
              Diagnóstico - Relatório Horizontal
            </Typography>
            <EnhancedEncryptionIcon
              style={{ float: 'left', margin: '6px 10px 10px 0', fontSize: 30 }}
            />
          </div>

          {loading ? (
            <CircularProgress />
          ) : (
            <ReactToPrint
              trigger={() => (
                <IconButton
                  style={{
                    borderRadius: '32px',
                    marginRight: '16px',
                    padding: '0px 8px',
                    border: '1px solid #FF0000',
                  }}
                >
                  <PictureAsPdfIcon
                    style={{ marginRight: '8px', fontSize: 22, color: '#FF0000' }}
                  />
                  <p style={{ fontSize: 14, margin: '8px 0px', color: '#FF0000' }}>Exportar PDF</p>
                </IconButton>
              )}
              content={() => this.componentRef}
              documentTitle={'Diagnósticos Horizontais - ' + pdfReportData.result.patient_name}
            />
          )}
        </div>

        {loading && <CircularProgress />}

        {!loading && (
          <Grid container>
            {result.length > 0 && (
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: '15px 0 0 0',
                    color: '#fff',
                    fontWeight: '500',
                    margin: '0 10px 10px 0',
                    textAlign: 'center',
                    backgroundColor: '#4caf50',
                    fontSize: '14px',
                  }}
                >
                  DADOS GERAIS
                  <div
                    style={{
                      backgroundColor: '#fff',
                      color: '#000',
                      fontWeight: '400',
                      fontSize: '13px',
                    }}
                  >
                    <p style={{ paddingTop: '15px', fontSize: '16px' }}>
                      Paciente: {result.length ? result[0].patient_name : ''}
                    </p>
                    <Divider />
                    <p style={styleP}>
                      Documento: {result.length ? result[0].patient_document : ''}
                    </p>
                    <Divider />
                    <p style={styleP}>
                      Data de nascimento: {result.length ? result[0].patient_birthdate : ''}
                    </p>
                    <Divider />
                    <p style={styleP}>
                      Hospital: {result.length ? result[0].client_name : ''}
                    </p>
                    <Divider />
                    <p style={styleP}>
                      Período de análise: {moment(firstDate).format('DD/MM/YYYY')} até{' '}
                      {moment(lastDate).format('DD/MM/YYYY')}
                    </p>
                    <Divider />
                    <p style={styleP}>Quantidade de diagnósticos: {total}</p>
                    <Divider />
                    <Grid container>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            value={firstDate}
                            onChange={this.handleFirstDateChange}
                            label="Início da Análise:"
                            maxDate={lastDate}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={5}>
                        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            value={lastDate}
                            onChange={this.handleLastDateChange}
                            label="Fim da Análise:"
                            minDate={firstDate}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={1}>
                        <Button size="small" color="primary" onClick={this.handleSearch}>
                          <SearchIcon style={{ fontSize: 30 }} />
                        </Button>
                      </Grid>
                      <p style={{ margin: 6 }}></p>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            )}

            {total ? (
              <>
                <Grid item xs={12}>
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Paper style={{ width: '100%' }}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={valueGraph1}
                          onChange={(e, val) => this.handleChange(e, val, 1)}
                          variant="standard"
                          scrollButtons="auto"
                          centered
                        >
                          {graph1.map((column) => (
                            <Tab key={column.column} label={column.column} />
                          ))}
                        </Tabs>
                      </AppBar>

                      {graph1.map(
                        (column, index) =>
                          valueGraph1 === index &&
                          column && (
                            <TabContainer key={index}>
                              <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                                {column.desc}&nbsp;
                              </Typography>

                              <ResponsiveContainer width="100%" minHeight="300px">
                                <LineChart
                                  data={column.data}
                                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip content={<CustomTooltip1 />} />
                                  <Legend verticalAlign="top" height={36} />
                                  <Line type="monotone" dataKey="Reobase" stroke="#82ca9d" />
                                </LineChart>
                              </ResponsiveContainer>
                            </TabContainer>
                          )
                      )}
                    </Paper>
                  </MuiThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Paper style={{ width: '100%' }}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={valueGraph2}
                          onChange={(e, val) => this.handleChange(e, val, 2)}
                          variant="standard"
                          scrollButtons="auto"
                          centered
                        >
                          {graph2.map((column) => (
                            <Tab key={column.column} label={column.column} />
                          ))}
                        </Tabs>
                      </AppBar>

                      {graph2.map(
                        (column, index) =>
                          valueGraph2 === index &&
                          column && (
                            <TabContainer key={index}>
                              <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                                {column.desc}&nbsp;
                              </Typography>

                              <ResponsiveContainer width="100%" minHeight="300px">
                                <LineChart
                                  data={column.data}
                                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip content={<CustomTooltip2 />} />
                                  <Legend verticalAlign="top" height={36} />
                                  <Line type="monotone" dataKey="Cronaxia" stroke="#82ca9d" />
                                </LineChart>
                              </ResponsiveContainer>
                            </TabContainer>
                          )
                      )}
                    </Paper>
                  </MuiThemeProvider>
                </Grid>

                <Grid item xs={12}>
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Paper style={{ width: '100%' }}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={valueGraph3}
                          onChange={(e, val) => this.handleChange(e, val, 3)}
                          variant="standard"
                          scrollButtons="auto"
                          centered
                        >
                          {graph3.map((column) => (
                            <Tab key={column.column} label={column.column} />
                          ))}
                        </Tabs>
                      </AppBar>

                      {graph3.map(
                        (column, index) =>
                          valueGraph3 === index &&
                          column && (
                            <TabContainer key={index}>
                              <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                                {column.desc}&nbsp;
                              </Typography>

                              <ResponsiveContainer width="100%" minHeight="300px">
                                <LineChart
                                  data={column.data}
                                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" />
                                  <YAxis />
                                  <Tooltip content={<CustomTooltip3 />} />
                                  <Legend verticalAlign="top" height={36} />
                                  <Line type="monotone" dataKey="Acomodação" stroke="#82ca9d" />
                                </LineChart>
                              </ResponsiveContainer>
                            </TabContainer>
                          )
                      )}
                    </Paper>
                  </MuiThemeProvider>
                </Grid>
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '16px 0px',
                }}
              >
                <FindInPageIcon
                  style={{ fontSize: '64px', margin: '16px 0px', color: '#aaaaaa' }}
                />
                <p style={{ textAlign: 'center' }}>
                  Não foi encontrado nenhum diagnóstico no período
                </p>
              </div>
            )}
          </Grid>
        )}

        {/* componente de PDF dos diagnosticos horizontais */}
        <div style={{ display: 'none' }}>
          <ExamReportPrint ref={(el) => (this.componentRef = el)} data={pdfReportData} />
        </div>
      </div>
    );
  };
}

export default ExamReport;
