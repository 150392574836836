import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import ExamService from '../../../services/ExamService';
import { withStyles } from '@material-ui/core/styles';
import QueueIcon from '@material-ui/icons/Queue';
import { formatTableDate } from '../../../helpers/formatters';
import ExamExcelService from '../../../services/excel/ExamExcelService';
import AuthService from '../../../services/AuthService';

const styles = (theme) => ({});

const getType = (value) => {
  return value === 0 ? 'Curva ID' : 'T.E.D.E'
}

class ExamList extends ListBase {
  role = AuthService.getRole();

  options = {
    description: 'Diagnóstico',
    descriptionPlural: 'Diagnósticos',
    icon: QueueIcon,
    details: '/diagnostico',
    module: 'diagnosticos',
    service: ExamService,
    addColumnAction: true,
    hasNew: false,
    hasDetails: true,
    hasEdit: false,
    hasRemove: false,
    hasExcel: true,
    excelService: ExamExcelService,
    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'patientName',
        label: 'Paciente',
        options: {
          display: this.role === 'P' ? false : true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          display: (this.role === 'P' || this.role === 'C') ? false : true,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date_start',
        label: 'Data',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value, true),
        },
      },
      {
        name: 'type',
        label: 'Tipo',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => getType(value)
        },
      },
    ],
  };
}

ExamList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ExamList);
