import React from "react";
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { MaskedCpfCnpj, MaskedPhoneNumber, MaskedBirthday } from '../../layout';
import { PRIMARY_COLOR } from "../../../constants";
import CompanionService from '../../../services/security/CompanionService';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },
  textField: {
    margin: '4px 0px'
  },
});

const COMPANION_MODEL_INITIAL_VALUE = {
  id: '',
  name: '',
  document: '',
  email: '',
  phone: '',
  birthDate: '',
  role: '7' // role de acompanhante
}

class AddCompanionContent extends React.Component {

  constructor() {
    super();

    this.state = {
      model: COMPANION_MODEL_INITIAL_VALUE,
      saving: false,
    }
  }

  handleChange = (name) => (event) => {
    const model = this.state.model;
    model[name] = event.target.value;
    this.setState({ model });
  };

  async handleSaveCompanion(event) {
    event.preventDefault();

    this.setState({ saving: true });

    const { model } = this.state;

    const {
      onEditCompanion,
      onConfirmCompanion,
      handleCloseDialog,
      getCompanions,
    } = this.props;

    try {
      if (model.id) { // editando
        const updatedCompanion = await CompanionService.edit(model.id, model);

        onEditCompanion(updatedCompanion);

        this.props.enqueueSnackbar('Acompanhante editado com sucesso!', {
          variant: 'success'
        });
      } else {
        const newCompanion = await CompanionService.save(model);

        onConfirmCompanion(newCompanion);

        this.props.enqueueSnackbar('Acompanhante cadastrado com sucesso!', {
          variant: 'success'
        });
      }

      handleCloseDialog();

      // ao final, atualiza a lista de acompanhantes com o novo cadastrado
      getCompanions();
      this.setState({ model: COMPANION_MODEL_INITIAL_VALUE })
    } catch (error) {
      console.error(error);

      if (error.hasOwnProperty('message')) {
        this.props.enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        this.props.enqueueSnackbar('Falha ao salvar dados do acompanhante!', { variant: 'error' });
      }
    } finally {
      this.setState({ saving: false });
    }
  }

  componentDidMount(prevProps, prevState) {
    if (this.props.companionToEdit) {
      this.setState({
        model: { ...this.props.companionToEdit, role: '7' },
      });
    }
  }

  render() {
    const { classes, handleCancel } = this.props;

    const { model, saving } = this.state;

    return (
      <>
        <Typography variant="h6" style={{ textAlign: 'center' }} >
          {model.id ? 'Editar' : 'Cadastrar'} Acompanhante
        </Typography>

        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={(event) => this.handleSaveCompanion(event)}
        >
          <TextField
            id="model-name"
            label="Nome"
            className={classes.textField}
            value={model.name || ''}
            onChange={this.handleChange('name')}
          />

          <TextField
            id="model-email"
            label="E-mail"
            className={classes.textField}
            value={model.email || ''}
            onChange={this.handleChange('email')}
            disabled={model.id ? true : false}
          />

          <TextField
            id="model-document"
            label="CPF / CNPJ"
            className={classes.textField}
            value={model.document || ''}
            onChange={this.handleChange('document')}
            InputProps={{ inputComponent: MaskedCpfCnpj }}
          />

          <TextField
            id="model-phone"
            label="Telefone"
            className={classes.textField}
            value={model.phone || ''}
            onChange={this.handleChange('phone')}
            InputProps={{ inputComponent: MaskedPhoneNumber }}
          />

          <TextField
            id="model-birthDate"
            label="Data de Nascimento"
            className={classes.textField}
            value={model.birthDate || ''}
            onChange={this.handleChange('birthDate')}
            InputProps={{ inputComponent: MaskedBirthday }}
          />

          <div className={classes.buttonContainer}  >
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: '16px' }}
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={saving}
            >
              Salvar
              {saving ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: PRIMARY_COLOR,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              ) : null}
            </Button>
          </div>
        </form>
      </>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withSnackbar(AddCompanionContent));

