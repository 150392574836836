import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

class InformationLinePrint extends React.Component {
  render() {
    const {
      classes,
      shouldShow = true,
      leftSide,
      rightSide,
      leftSideFlex = 1,
      rightSideFlex = 1,
    } = this.props;

    return shouldShow ? (
      <>
        <div className={classes.infoLine}>
          <p className={classes.infoText} style={{ flex: leftSideFlex }}>
            {leftSide}
          </p>
          <p className={classes.infoText} style={{ flex: rightSideFlex }}>
            {rightSide}
          </p>
        </div>
      </>
    ) : null;
  }
}

const styles = (theme) => ({
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
});

InformationLinePrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InformationLinePrint);
