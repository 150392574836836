export const DMT = Object.freeze({
  0: 'Sem Dor',
  1: 'Apenas perceptível',
  2: 'Dor leve',
  3: 'Dor meio moderada',
  4: 'Dor moderada',
  5: 'Dor moderada',
  6: 'Dor intensa',
  7: 'Dor muito intensa',
  8: 'Dor muito, muito intensa',
  9: 'Dor submáxima',
  10: 'Dor máxima',
  LABEL: 'Dor muscular tardia',
});

export const PSR = Object.freeze({
  0: 'Nenhuma recuperação',
  1: 'Muito pouca recuperação',
  2: 'Pouca recuperação',
  3: 'Recuperação moderada',
  4: 'Boa recuperação',
  5: 'Muito boa recuperação',
  6: 'Muito boa recuperação',
  7: 'Muito, muito boa recuperação',
  8: 'Muito, muito boa recuperação',
  9: 'Muito, muito boa recuperação',
  10: 'Totalmente recuperado',
});

export const PSE = Object.freeze({
  0: 'Nenhum esforço',
  1: 'Muito leve',
  2: 'Leve',
  3: 'Leve',
  4: 'Moderado',
  5: 'Moderado',
  6: 'Um pouco forte',
  7: 'Forte',
  8: 'Forte',
  9: 'Muito forte',
  10: 'Esforço máximo',
});

export const RECOVERY_LABELS = Object.freeze({
  activity: 'Atividade',
  minutes: 'Minutos',
  recovery_date: 'Data da atividade física',
  pse: 'Percepção subjetiva do esforço - Pré-Recuperação',
  psr: 'Percepção subjetiva de recuperação - Pré-Recuperação',
  dmt: 'Dor muscular tardia - Pré-Recuperação',
  psr_feedback: 'Percepção subjetiva de recuperação - Pós-Recuperação',
  dmt_feedback: 'Dor muscular tardia - Pós-Recuperação',
  recovery_type: 'Tipo da recuperação',
});

const ACTIVE_RECOVERY = 'Recuperação Ativa';
const REGENERATIVE_TRAINING = 'Treinamento Regenerativo';
const RECOVERY_PLUS = 'Recuperação Plus';

export const RECOVERY_TYPES = Object.freeze({
  0: ACTIVE_RECOVERY,
  1: REGENERATIVE_TRAINING,
  2: RECOVERY_PLUS,
});
