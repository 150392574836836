import React from 'react';
import PropTypes from 'prop-types';

import visuriLogo from '../../imgs/logo-side.png';
import { formatElectrodeSize } from '../../helpers/formatters';
import withStyles from '@material-ui/core/styles/withStyles';

import moment from 'moment';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

class ExamPrint extends React.PureComponent {
  getStyle(alert) {
    if (alert) {
      return { backgroundColor: 'rgba(187,17,5,0.5)' };
    }
  }

  render() {
    const { classes } = this.props;
    const { result, tableData, shouldShowInstrument } = this.props.data;

    const customColumnStyle = { padding: '15px 10px' };
    const customHeadStyle = { padding: '15px 10px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.54)' };
    const customLineStyle = { backgroundColor: 'rgba(223, 223, 223, 1)' };


    const PageHeader = ({ style, index = 0 }) => {
      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Paciente: {result.patientName}</p>
              <p className={classes.rightText}>Data: {result.date_end}</p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    return (
      <div className={classes.page}>
        <PageHeader />

        <h3 className={classes.infoHeader}>Dados gerais</h3>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>NOME DO PACIENTE:</p>
              <p className={classes.infoText}>{result.patientName}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                DOCUMENTO DO PACIENTE:
              </p>
              <p className={classes.infoText}>{result.patientCPF}</p>
            </div>
            {shouldShowInstrument ? (
              <div className={classes.infoLine}>
                <p className={classes.infoText} style={{ flex: 2 }}>
                  INSTRUMENTO:
                </p>
                <p className={classes.infoText}>
                  {result.instrument === 1 ? 'Eletrodo' : 'Caneta'}
                </p>
              </div>
            ) : null}
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>CLIENTE:</p>
              <p className={classes.infoText} style={{ flex: 2 }}>
                {result.clientName}
              </p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>DATA DO DIAGNÓSTICO:</p>
              <p className={classes.infoText}>{result.date_end}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>IMPRESSO EM:</p>
              <p className={classes.infoText}>{moment(Date.now()).format('DD/MM/YYYY HH:mm')}</p>
            </div>
          </div>
        </div>

        <h3 className={classes.infoHeader}>
          Dados do Teste de Eletrodiagnóstico de Estímulo (T.E.D.E.)
        </h3>

        <Table>
          <TableHead>
            <TableRow style={customLineStyle}>
              <TableCell style={customHeadStyle}>Músculo</TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Reobase(mA)
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Cronaxia(µs)
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Acomodação(mA)
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Indice de Acomodação
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Tipo de Eletrodo
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Formato do Eletrodo
              </TableCell>
              <TableCell align="center" style={customHeadStyle}>
                Tamanho do Eletrodo
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} style={this.getStyle(row.alert)}>
                <TableCell style={customColumnStyle}>{row.muscle_name}</TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.rheobase ? row.rheobase : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.chronaxie ? row.chronaxie : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.accommodation ? row.accommodation : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.accommodation_index ? row.accommodation_index : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.electrode_material ? row.electrode_material : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.electrode_format ? row.electrode_format : '-'}
                </TableCell>
                <TableCell align="center" style={customColumnStyle}>
                  {row.electrode_size ? formatElectrodeSize(row.electrode_size) : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  infoContainer: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoHeader: {
    textAlign: 'center',
  },
  centerGraph: {
    flex: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  muscleColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

ExamPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExamPrint);
