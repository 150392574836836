import moment from 'moment';

const DATE_HOUR_FORMAT = 'DD/MM/YYYY HH:mm';
const DATE_FORMAT = 'DD/MM/YYYY';

export const formatDateToString = (dateObject) => {
  const day = dateObject.getDate().toString();
  const dayF = day.length === 1 ? '0' + day : day;
  const month = (dateObject.getMonth() + 1).toString(); // +1 pois no getMonth Janeiro começa com zero
  const monthF = month.length === 1 ? '0' + month : month;
  const yearF = dateObject.getFullYear();

  const hours = dateObject.getHours().toString();
  const hoursF = hours.length === 1 ? '0' + hours : hours;
  const minutes = dateObject.getHours().toString();
  const minutesF = minutes.length === 1 ? '0' + minutes : minutes;

  return dayF + '/' + monthF + '/' + yearF + ' - ' + hoursF + ':' + minutesF;
};

export const calculateGraphOperation = (dataArray, operation, parameter) => {
  const parameterArray = dataArray.map((stimulus) => stimulus[parameter]);

  if (!parameterArray.length) {
    return 0;
  }

  switch (operation) {
    case 'max': {
      const max = Math.max(...parameterArray).toFixed(2);
      return isNaN(max) ? 0 : max;
    }
    case 'min': {
      const min = Math.min(...parameterArray).toFixed(2);
      return isNaN(min) ? 0 : min;
    }
    case 'average': {
      let sum = 0;
      for (let i = 0; i < parameterArray.length; i++) {
        sum += parameterArray[i];
      }

      const average = (sum / dataArray.length).toFixed(2);
      return isNaN(average) ? 0 : average;
    }
    default: {
      return 0;
    }
  }
};
export const formatElectrodeSize = (electrodeSize) => {
  if (typeof electrodeSize !== 'string') {
    return electrodeSize;
  }

  // se houver repetição de 'cm' no formato
  if (electrodeSize.indexOf('cm') !== electrodeSize.lastIndexOf('cm')) {
    electrodeSize = electrodeSize.replaceAll('cm', '');
    return electrodeSize + ' cm';
  } else {
    return electrodeSize;
  }
};

export const formatTableDate = (date, onlyDate = false) =>
  date ? moment(new Date(date)).format(onlyDate ? DATE_FORMAT : DATE_HOUR_FORMAT) : '';

export const milisecondsToHourMinutesSeconds = (mili) => {
  if (typeof mili !== 'number') {
    return mili;
  }

  if (!mili){
    return mili;
  }

  let sec = mili / 1000;
  let hours = Math.floor(sec / 3600);
  var totalSeconds = (sec %= 3600);
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  var therapy_time = '';
  if (hours) {
    therapy_time += hours + 'h ';
  }

  if (minutes) {
    therapy_time += minutes + 'min ';
  } else {
    therapy_time += hours + '0min ';
  }

  if (seconds) {
    therapy_time += Math.round(seconds) + 's';
  } else {
    therapy_time += hours + '0s ';
  }

  return therapy_time;
};

export const millisecondsToSeconds = (mili) => {
  if (typeof mili !== 'number') {
    return mili;
  }

  return mili / 1000;
};

export const formatStringToCpfCnpj = (string) => {
  if (typeof string !== 'string') {
    return '';
  }

  if (string.length === 11) {
    return string.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4")
  } else {
    return string.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, "$1.$2.$3/$4-$5")
  }
}

export const formatStringToPhone = (string) => {
  if (typeof string !== 'string') {
    return '';
  }

  if (string.length === 10) {
    return string.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2 - $3")
  } else {
    return string.replace(/^(\d{2})(\d{1})(\d{4})(\d{4}).*/, "($1) $2 $3 - $4")
  }
}

