import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ListAltIcon from '@material-ui/icons/ListAlt';
import BarChartIcon from '@material-ui/icons/BarChart';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthService from '../../../services/AuthService';
import Roles from '../../../helpers/roles';
import { ptBr } from '../../../helpers/MUIDataTableLabels';
import EditDialog from './EditDialog';

class ListBase extends Component {
  state = {
    page: 0,
    pageSize: 10,
    count: 100,
    data: [],
    selectedId: '',
    isEditOpen: false,
    isConfirmDeleteOpen: false,
    loading: true,
    excelLoading: false,
  };

  tableState = {
    searchText: null,
    sortColumn: null,
    sortDirection: null,
  };

  _columns = [];
  options = {
    description: '',
    descriptionPlural: '',
    icon: null,
    edit: '',
    service: null,
    columns: [],
  };

  initializeColumns = () => {
    Array.prototype.push.apply(this._columns, this.options.columns);
    if (this.options.addColumnAction) {
      this._columns.push(this.getActionColumn());
    }
    this.linkDetails = this.options.details;
    this.linkStatics = this.options.statics;
  };

  controlRole(role) {
    if (Roles[role].indexOf(this.options.module) === -1) {
      this.props.history.push('/');
      return false;
    } else {
      return true;
    }
  }

  componentWillMount() {
    this.initializeColumns();
  }

  componentDidMount() {
    if (this.controlRole(AuthService.getRole())) {
      this.refresh();
    }
  }

  refresh = () => {
    this.getData(this.state.page, this.state.pageSize);
  };

  doAfterGetDate(data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getData = (page = 0, pageSize = 10) => {
    this.options.service
      .list(
        page,
        pageSize,
        this.tableState.searchText,
        this.tableState.sortColumn,
        this.tableState.sortDirection
      )
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          this.setState({
            data: data.list,
            count: data.count,
            page,
            pageSize,
            loading: false,
          });
        });
      });
  };

  setSortColumn = (columns) => {
    let sortColumn = columns.find((column) => {
      return !!column.sortDirection;
    });

    this.tableState.sortColumn = sortColumn ? sortColumn.name : null;
    this.tableState.sortDirection = sortColumn ? sortColumn.sortDirection : null;
    this.refresh();
  };

  handleEditNew = () => {
    this.setState({
      selectedId: null,
      isEditOpen: true,
    });
  };

  handleOpenEdit = () => {
    this.setState({
      isEditOpen: true,
    });
  };

  handleCloseEdit = () => {
    this.setState({
      isEditOpen: false,
    });

    this.refresh();
  };

  handleStatisticsClick = (id) => (event) => {
    this.props.history.push('estatisticas' + this.linkStatics + '/' + id);
  };

  handleDetailsClick = (id) => (event) => {
    this.props.history.push(this.linkDetails + '/' + id);
  };

  handleHomeClick = (id) => (event) => {
    this.props.history.push('/');
  };

  handleEditClick = (id) => (event) => {
    this.setState({
      selectedId: id,
      isEditOpen: true,
    });
  };

  handleRemoveClick = (id, rowIndex) => (event) => {
    this.tableState.deleteId = id;
    this.tableState.deleteName = this.state.data[rowIndex].name;

    this.setState({ isConfirmDeleteOpen: true });
  };

  handleAcceptConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });

    this.options.service.remove(this.tableState.deleteId).then(() => {
      this.tableState.deleteId = null;
      this.tableState.deleteName = null;
      this.refresh();
    });
  };

  handleCloseConfirmDelete = () => {
    this.tableState.deleteId = null;
    this.tableState.deleteName = null;
    this.setState({ isConfirmDeleteOpen: false });
  };

  hasDetails(value) {
    if (this.options.hasDetails) {
      return (
        <IconButton
          aria-label="Detalhes"
          className={this.props.classes.button}
          onClick={this.handleDetailsClick(value)}
        >
          <FindInPageOutlinedIcon />
        </IconButton>
      );
    }
  }

  hasEdit(value) {
    if (this.options.hasEdit) {
      return (
        <IconButton
          aria-label="Editar"
          className={this.props.classes.button}
          onClick={this.handleEditClick(value)}
        >
          <EditIcon />
        </IconButton>
      );
    }
  }

  hasRemove(value, index) {
    if (this.options.hasRemove && value !== AuthService.getId()) {
      return (
        <IconButton
          aria-label="Remover"
          className={this.props.classes.button}
          onClick={this.handleRemoveClick(value, index)}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  }

  hasStatistics(value) {
    if (this.options.hasStatistics) {
      return (
        <IconButton
          aria-label="Estatisticas"
          className={this.props.classes.button}
          onClick={this.handleStatisticsClick(value)}
        >
          <BarChartIcon />
        </IconButton>
      );
    }
  }

  hasNew() {
    if (this.options.hasNew) {
      return (
        <Fab
          color="primary"
          size="medium"
          onClick={this.handleEditNew}
          style={{ float: 'right', marginRight: 10, marginTop: 8 }}
        >
          <AddIcon />
        </Fab>
      );
    }
  }

  getExcel() {
    if (!this.options.excelService) {
      throw new Error({ message: 'this.options.excelService is not defined' });
    }

    this.setState({ excelLoading: true });

    this.options.excelService
      .downloadExcel(
        this.tableState.searchText,
        this.tableState.sortColumn,
        this.tableState.sortDirection
      )
      .catch((err) => {
        console.error(err);
      })
      .finally(() => this.setState({ excelLoading: false }));
  }

  customHeader() {
    return <></>;
  }

  ExcelButton() {
    if (!this.options.hasExcel) {
      return <></>;
    }

    return this.state.loading ? (
      <React.Fragment />
    ) : (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '-32px',
          marginBottom: '8px',
        }}
      >
        {this.state.excelLoading ? (
          <CircularProgress />
        ) : (
          <IconButton
            style={{
              borderRadius: '32px',
              marginRight: '16px',
              padding: '0px 8px',
              border: '1px solid #4caf50',
            }}
            onClick={() => this.getExcel()}
            disabled={this.state.excelLoading}
          >
            <ListAltIcon style={{ marginRight: '8px', fontSize: 22, color: '#4caf50' }} />
            <p style={{ fontSize: 14, margin: '8px 0px', color: '#4caf50' }}>Exportar Excel</p>
          </IconButton>
        )}
      </div>
    );
  }

  getActionColumn = () => {
    return {
      name: 'id',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
              {this.hasDetails(value)}
              {this.hasStatistics(value)}
              {this.hasEdit(value)}
              {this.hasRemove(value, tableMeta.rowIndex)}
            </div>
          );
        },
        setCellProps: (value) => {
          return {
            width: 100,
            'text-align': 'center',
          };
        },
      },
    };
  };

  breadcrumb() {
    if (this.options.descriptionPlural) {
      return (
        <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
          <span
            onClick={this.handleHomeClick()}
            style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
          >
            Home
          </span>{' '}
          {'>'}
          &nbsp;{' '}
          <span style={{ color: 'rgba(152, 149, 149, 1)' }}>{this.options.descriptionPlural} </span>
        </h6>
      );
    }
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            minHeight: '650px',
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: 'rgba(223, 223, 223, 1)',
          },
        },
        MuiTableCell: {
          head: {
            fontWeight: 600,
          },
          root: {
            '&:last-child': {
              textAlign: 'center',
            },
          },
        },
        MUIDataTableBodyRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#f5f5f5',
            },
          },
        },
        MUIDataTableToolbar: {
          iconActive: {
            color: '#4caf50',
          },
          icon: {
            '&:hover': {
              color: '#4caf50',
            },
          },
        },
        MuiIconButton: {
          label: {
            '&:hover': {
              color: '#4caf50',
            },
          },
        },
      },
    });

  render = () => {
    const { data, page, pageSize, count, loading } = this.state;

    const columns = this._columns;
    const Icon = this.options.icon;

    const options = {
      filter: false,
      filterType: 'dropdown',
      responsive: 'scroll',
      serverSide: true,
      selectableRows: false,
      count,
      page,
      rowsPerPage: pageSize,
      textLabels: ptBr,
      onTableChange: (action, tableState) => {
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case 'changePage':
          case 'search':
            this.tableState.searchText = tableState.searchText;
            this.getData(tableState.page, this.state.pageSize);
            break;
          case 'changeRowsPerPage':
            this.getData(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            this.setSortColumn(tableState.columns);
            break;
          default:
            break;
        }
      },
    };

    return (
      <div>
        {this.breadcrumb()}
        {this.ExcelButton()}
        {this.customHeader()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 8px 0 20px' }}
        >
          {this.options.descriptionPlural}
        </Typography>
        <Icon style={{ float: 'left', margin: '16px 10px 10px 0', fontSize: 30 }} />

        {this.hasNew()}

        {loading && <CircularProgress />}
        {!loading && (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable title={''} data={data} columns={columns} options={options} />
          </MuiThemeProvider>
        )}

        <EditDialog
          edit={this.options.edit}
          title={this.options.description}
          id={this.state.selectedId}
          isOpen={this.state.isEditOpen}
          onCloseCallBack={this.handleCloseEdit}
        />

        <Dialog
          open={this.state.isConfirmDeleteOpen}
          onClose={this.handleCloseConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Remover ' + this.options.description}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja realmente remover o {this.options.description}{' '}
              <b>{this.tableState.deleteName}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAcceptConfirmDelete} color="primary">
              Sim
            </Button>
            <Button onClick={this.handleCloseConfirmDelete} color="primary" autoFocus>
              Não
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

export default ListBase;
