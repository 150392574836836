import React from 'react';
import history from '../../history';

const Breadcrumb = ({ pagesList = [] }) => {
  const handleBreadcrumb = (page) => {
    history.push(page);
  };

  return (
    <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
      <span
        onClick={() => handleBreadcrumb('/')}
        style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
      >
        Home
      </span>
      {' > '}

      {pagesList.map(({ page, route }, index, array) =>
        index !== array.length - 1 ? (
          <React.Fragment key={index}>
            <span
              onClick={() => handleBreadcrumb(route)}
              style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
            >
              {page}
            </span>
            {' > '}
          </React.Fragment>
        ) : (
          <span key={index} style={{ color: 'rgba(152, 149, 149, 1)' }}>
            {page}
          </span>
        )
      )}
    </h6>
  );
};

export default Breadcrumb;
