import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

import pacientes from './../imgs/pacientes.jpeg';
import exames from './../imgs/exames.jpg';
import tratamentos from './../imgs/tratamentos.jpg';
import financeiro from './../imgs/financeiro.jpg';
import diagnosticos from './../imgs/diagnosticos.jpg';
import equipamentos from './../imgs/equipamentos.jpg';
import contratos from './../imgs/contratos.jpg';
import clientes from './../imgs/hospital.jpg';
import logs from './../imgs/logs.jpg';
import graficos from './../imgs/graficos.png';
import report from './../imgs/report.jpg';
import distributor from './../imgs/distribuidores.jpg';
import protocolos from './../imgs/protocolos.jpg';
import apis from './../imgs/apis.png';
import AuthService from '../services/AuthService';
import Subtitles from '../helpers/subtitles';
//import Notification from './layout/Notification';

class Home extends Component {
  state = {};

  role = AuthService.getRole();

  render = () => {
    return (
      <div>
        <Grid
          container
          spacing={24}
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          
          {this.role !== 'L' && this.role !== 'D' && this.role !== 'P' && this.role !== 'AC' &&(
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={pacientes} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Pacientes
                  </Typography>
                  <Typography component="p">Visualizar e gerenciar a lista de pacientes</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/pacientes">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'L' && this.role !== 'D' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={diagnosticos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Diagnósticos
                  </Typography>
                  <Typography component="p">
                    {this.role === 'P' ? Subtitles['diagnosticos'][this.role] : Subtitles['diagnosticos']['default']}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button size="small" color="primary" href="#" component={Link} to="/diagnosticos">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'L' && this.role !== 'D' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={protocolos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Protocolos
                  </Typography>
                  <Typography component="p">
                    {this.role === 'P' ? Subtitles['protocolos'][this.role] : Subtitles['protocolos']['default']}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button size="small" color="primary" href="#" component={Link} to="/protocolos">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'L' && this.role !== 'D' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={tratamentos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Tratamentos
                  </Typography>
                  <Typography component="p">
                  {this.role === 'P' ? Subtitles['tratamentos'][this.role] : Subtitles['tratamentos']['default']}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/terapias">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'L' && this.role !== 'D' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={graficos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Relatórios Horizontais
                  </Typography>
                  <Typography component="p">
                  {this.role === 'P' ? Subtitles['relatorios'][this.role] : Subtitles['relatorios']['default']}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to="/relatorios-horizontais"
                  >
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {false && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={financeiro} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Financeiro
                  </Typography>
                  <Typography component="p">Veja como está o faturamento atual.</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/financeiro">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'C' && this.role !== 'P' && this.role !== 'AC' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={equipamentos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Equipamentos
                  </Typography>
                  <Typography component="p">Visualizar e gerenciar os equipamentos.</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/equipamentos">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'L' && this.role !== 'P' && this.role !== 'AC' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={exames} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Usuários
                  </Typography>
                  <Typography component="p">Visualizar e gerenciar a lista de usuários</Typography>
                </CardContent>

                <CardActions>
                  <Button size="small" color="primary" href="#" component={Link} to="/usuarios">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role !== 'C' && this.role !== 'L' && this.role !== 'P' && this.role !== 'AC' &&(
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={contratos} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Contratos
                  </Typography>
                  <Typography component="p">
                    Visualizar e gerenciar os contratos dos clientes.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/contratos">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {(this.role === 'V' || this.role === 'D') && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={clientes} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Clientes
                  </Typography>
                  <Typography component="p">Veja a lista de clientes.</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/clientes">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role === 'V' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={logs} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Logs
                  </Typography>
                  <Typography component="p">Veja os logs da aplicação</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/logs">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role === 'V'  && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={report} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Relatórios
                  </Typography>
                  <Typography component="p">
                    Veja relatórios sobre equipamentos e pacientes.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/relatorios">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {this.role === 'V' && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={distributor} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Distribuidores
                  </Typography>
                  <Typography component="p">
                    Visualizar e gerenciar a lista de distribuidores.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/distribuidores">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {(this.role === 'V' || this.role === 'P' || this.role === 'C' || this.role === 'AC') && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={report} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Agendamento
                  </Typography>
                  <Typography component="p">
                  {this.role === 'P' ? Subtitles['agendamentos'][this.role] : Subtitles['agendamentos']['default']}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/agendamento">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {(this.role === 'V' || this.role === 'A' || this.role === 'D' || this.role === 'C') && (
            <Grid item xs={12} sm={4} lg={4} xl={3}>
              <Card>
                <CardMedia style={{ height: 0, paddingTop: '26%' }} image={apis} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    APIs Externas
                  </Typography>
                  <Typography component="p">
                    Crie os seus próprios dashboards com as nossas APIs.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to="/apis">
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };
}

export default Home;
