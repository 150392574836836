import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import AppointmentService from '../../../services/AppointmentService';
import AuthService from '../../../services/AuthService';
import PatientService from '../../../services/PatientService';

import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DateTimePicker } from 'material-ui-pickers';

import Grid from '@material-ui/core/Grid';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { FormLabel, RadioGroup, FormControlLabel, Radio, TextField, MenuItem, Select, InputLabel } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';


import { InputMaskCustomCEP } from './event-utils';
class SchedulingEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patients: [],
      isLoading: false,
      model: {
        date_start: null,
        date_end: null,
        type: '',
        model: '',
        assessment: '',
        patient_id: '',
        complement: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        zipcode: '',
        emailPatient: this.props.emailPatient,
        emailCompanion: this.props.emailCompanion
      }
    };

    this.role = AuthService.getRole();

  }

  get = (id) => {
    AppointmentService.get(id).then((data) => {
      // this.doAfterLoadData(data);
      this.setState({
        model: data,
      });
    });
  };


  componentDidMount() {
    this.getPatients()
  }

  doAfterGetDate(data) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getPatients() {
    PatientService.list(0, null, null, null, null)
      .then((data) => {
        this.doAfterGetDate(data).then(() => {
          this.setState({
            patients: data.list,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setInitialModel() {
    var model = this.state.model

    if (model.type) model['type'] = ''
    if (model.model) model['model'] = ''
    if (model.assessment) model['assessment'] = ''
    if (model.patient_id) model['patient_id'] = ''
    if (model.street) model['street'] = ''
    if (model.number) model['number'] = ''
    if (model.neighborhood) model['neighborhood'] = ''
    if (model.city) model['city'] = ''
    if (model.state) model['state'] = ''
    if (model.zipcode) model['zipcode'] = ''

    this.setState({ model });
  }

  doBeforeSave() {
    return new Promise((resolve, reject) => {
      resolve(this.state.model);
    });
  }

  save = () => {
    var id = this.props.id
    return new Promise((resolve, reject) => {
      this.setState({
        isLoading: true,
      });

      this.doBeforeSave()
        .then((model) => {
          if (id) {
            AppointmentService
              .edit(id, model)
              .then((data) => {
                this.setState({
                  model: data,
                  isLoading: false,
                });

                this.doAfterLoadData('Alterado com sucesso...', 'success');
                this.setInitialModel();

                resolve();
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                });

                if (error.hasOwnProperty('message')) {
                  this.doAfterLoadData(error.message, 'error');
                } else {
                  this.doAfterLoadData('Erro ao alterar os dados!', 'error');
                }

                if (this.props.history && this.props.history.goBack) {
                  this.props.history.goBack();
                }

                reject(error);
              });
          } else {
            AppointmentService
              .save(model)
              .then((data) => {
                this.setState({
                  model: data,
                  isLoading: false,
                });

                this.doAfterLoadData('Salvo com sucesso...', 'success');
                this.setInitialModel();

                resolve();
              })
              .catch((error) => {

                this.setState({
                  isLoading: false,
                });

                if (error.hasOwnProperty('message')) {
                  this.doAfterLoadData(error.message, 'error');
                } else {
                  this.doAfterLoadData('Erro ao salvar os dados!', 'error');
                }

                reject(error);
              });
          }
        })
        .catch(reject)
        .finally(() => this.setState({ isLoading: false }));
    });
  };


  handleSaveEdit = () => {
    this.save();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dateFirst !== this.props.dateFirst) {
      let model = this.state.model
      let date_start_ms = Date.parse(this.props.dateFirst)
      model['date_start'] = date_start_ms
      this.setState({ model });
    }

    if (prevProps.dateLast !== this.props.dateLast) {
      let model = this.state.model
      let date_end_ms = Date.parse(this.props.dateLast)
      model['date_end'] = date_end_ms
      this.setState({ model });
    }

    if (prevProps.emailCompanion !== this.props.emailCompanion) {
      let model = this.state.model
      model['emailCompanion'] = this.props.emailCompanion
      this.setState({ model });
    }

    if (prevProps.emailPatient !== this.props.emailPatient) {
      let model = this.state.model
      model['emailPatient'] = this.props.emailPatient
      this.setState({ model });
    }

    if (prevState.model !== this.state.model) {
      if (!this.props.id && this.props.isOpen) {
        let model = this.state.model
        this.props.onHandleSubmit(model)
        console.log('Criando Agendamento..')
      }
      else if (this.props.id && this.props.isOpen) {
        console.log('Alterando Agendamento..')
        let model = this.state.model
        this.props.onHandleEdit(model)
      }

    }

    if (prevProps.id !== this.props.id){
      var id = this.props.id
      if (id) this.get(id)
      else this.setInitialModel()
    }

  }


  handleCloseEdit = () => {
    this.props.onCloseCallBack();
  };

  handleChangeDateFirst = (value) => {
    this.props.onHandleChangeDateFirst(value)
  }

  handleChangeDateLast = (value) => {
    this.props.onHandleChangeDateLast(value)
  }


  handleChange = (name) => (event) => {
    var model = this.state.model;
    if (name === 'model') {
      model[name] = event.target.value === 'Presencial' ? 1 : 2
    }
    else
      model[name] = event.target.value;
    this.setState({ model });

  };

  doAfterLoadData = (message, type) => {
    this.props.enqueueSnackbar(message, {
      variant: type,
    });
  };

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MuiInputBase: {
          root: {
            width: '100%',
          },
        },
        MuiFormControl: {
          root: {
            padding: 5,
            width: '100%',
          },
        },
        MuiSwitch: {
          bar: {
            '$checked$checked + &': {
              opacity: 0.5,
              backgroundColor: '#4caf50',
            },
          },
          iconChecked: {
            color: '#4caf50',
          },
        },
      },
    });

  render = () => {
    const { classes } = this.props;
    const model = this.state.model;
    const {
      patients,
      isLoading,
    } = this.state

    class LocalizedUtils extends DateFnsUtils {
      getDatePickerHeaderText(date) {
        return format(date, 'd MMM yyyy hh mm', { locale: this.locale });
      }
    }

    return (
      <div>
        <Dialog
          fullScreen={false}
          open={this.props.isOpen}
          onClose={this.handleCloseEdit}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{this.props.id ? 'Editando Agendamento' : 'Novo Agendamento'}</DialogTitle>
          <DialogContent>
            <form className={classes.container} noValidate autoComplete="off">
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor="hospital-label-placeholder">
                        Paciente
                      </InputLabel>
                      <Select
                        value={model.patient_id || ''}
                        onChange={this.handleChange('patient_id')}
                        inputProps={{
                          name: 'patient_id',
                          id: 'patient_id-label-placeholder',

                        }}
                        className={classes.selectEmpty}
                      >
                        {patients.map((patient, index) => (
                          <MenuItem key={patient.id} value={patient.id}>
                            {patient.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor="hospital-label-placeholder">
                        Tipo do Agendamento
                      </InputLabel>
                      <Select
                        value={model.type || ''}
                        onChange={this.handleChange('type')}
                        inputProps={{
                          name: 'type',
                          id: 'type-label-placeholder',

                        }}
                        className={classes.selectEmpty}
                      >
                        <MenuItem key={1} value={1}>Consulta</MenuItem>
                        <MenuItem key={2} value={2}>Avaliacao</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                      <DateTimePicker
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        label="Data/Hora Inicial"
                        value={model.date_start}
                        onChange={(date) => this.handleChangeDateFirst(date)}
                        className={classes.textField}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
                      <DateTimePicker
                        ampm={false}
                        format="dd/MM/yyyy HH:mm"
                        label="Data/Hora Final"
                        value={model.date_end}
                        onChange={(date) => this.handleChangeDateLast(date)}
                        className={classes.textField}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {model.type === 1 && (<Grid item xs={12}>
                    <TextField
                      id="model-assessment"
                      label="Observações"
                      className={classes.textField}
                      value={model.assessment || ''}
                      onChange={this.handleChange('assessment')}
                      margin="normal"
                    />
                  </Grid>)}

                  {model.type === 2 && (<Grid item xs={12}>
                    <TextField
                      id="model-assessment"
                      label="Tipo de Avaliação"
                      className={classes.textField}
                      value={model.assessment || ''}
                      onChange={this.handleChange('assessment')}
                      margin="normal"
                    />
                  </Grid>)}

                  <Grid item xs={12}>
                    {model.model === 1 && (
                      <TextField
                        id="model-street"
                        label="Rua"
                        className={classes.textField}
                        value={model.street || ''}
                        onChange={this.handleChange('street')}
                        margin="normal"
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-number"
                        label="Número"
                        className={classes.textField}
                        value={model.number || ''}
                        onChange={this.handleChange('number')}
                        margin="normal"
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-complement"
                        label="Complemento"
                        className={classes.textField}
                        value={model.complement || ''}
                        onChange={this.handleChange('complement')}
                        margin="normal"
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-neighborhood"
                        label="Bairro"
                        className={classes.textField}
                        value={model.neighborhood || ''}
                        onChange={this.handleChange('neighborhood')}
                        margin="normal"
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-city"
                        label="Cidade"
                        className={classes.textField}
                        value={model.city || ''}
                        onChange={this.handleChange('city')}
                        margin="normal"
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-state"
                        label="Estado"
                        className={classes.textField}
                        value={model.state || ''}
                        onChange={this.handleChange('state')}
                        margin="normal"
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {model.model === 1 && (
                      <TextField
                        id="model-zipcode"
                        label="CEP"
                        className={classes.textField}
                        value={model.zipcode || ''}
                        onChange={this.handleChange('zipcode')}
                        margin="normal"
                        InputProps={{
                          inputComponent: InputMaskCustomCEP,
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Tipo</FormLabel>
                      <RadioGroup
                        aria-label="Papel"
                        name="model"
                        className={classes.group}
                        value={model.model || ''}
                        onChange={this.handleChange('model')}
                      >
                        <FormControlLabel value={'Presencial'} control={<Radio checked={model.model === 1} />} label="Presencial" />
                        <FormControlLabel value={'Online'} control={<Radio checked={model.model === 2} />} label="Online" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </MuiThemeProvider>
            </form>
          </DialogContent>
          <DialogActions>
            <div style={{ margin: '8px', position: 'relative' }}>
              {this.role !== 'P' && (<Button
                variant="outlined"
                onClick={this.handleSaveEdit}
                color="primary"
                disabled={isLoading}
              >
                Salvar
              </Button>)}
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
            <Button onClick={this.handleCloseEdit} color="primary" autoFocus>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textFieldName: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 7,
    width: '100%',
    display: 'block',
  },
  formControl: {
    marginTop: 20,
    marginLeft: 10,
  },
  selectEmpty: {
    marginTop: 10,
    minWidth: 250,
  },
});

SchedulingEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(SchedulingEdit));
