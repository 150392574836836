import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { withSnackbar } from 'notistack';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import PrivateRoute from './PrivateRoute';
import Main from './components/Main';
import Login from './components/Login';
import PasswordDefinition from './components/password/PasswordDefinition';
import PasswordRequest from './components/password/PasswordRequest';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: {
    useNextVariants: true,
  },
});

const MyLogin = withSnackbar(Login);
const MyMain = withSnackbar(Main);

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path="/login" component={MyLogin} />
            <Route path="/password-definition" component={PasswordDefinition} />
            <Route path="/password-request" component={PasswordRequest} />
            <PrivateRoute path="*" component={MyMain} />
          </Switch>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
