import React from 'react'
import MaskedInput from 'react-text-mask';
import { formatStringToCpfCnpj } from '../../helpers/formatters';

const CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const CNPJ_MASK = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export function MaskedCpfCnpj(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => rawValue.length > CPF_MASK.length ? CNPJ_MASK : CPF_MASK}
      pipe={(conformedValue, config) => {
        if (config.previousConformedValue === undefined) {
          return formatStringToCpfCnpj(config.rawValue)
        }
        
        return conformedValue
      }}
      guide={false}
    />
  );
}
