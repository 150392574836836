import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { MaskedCpfCnpj, MaskedBirthday, MaskedPhoneNumber } from '../../layout';
import EditBase from '../base/EditBase';
import AuthService from '../../../services/AuthService';
import PatientService from '../../../services/PatientService';
import { formTheme } from '../../../theme';
import CompanionEdit from '../companions/CompanionEdit.';
import { PRIMARY_COLOR } from '../../../constants';
import CompanionService from '../../../services/security/CompanionService';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  formControl: {
    margin: '4px 0px'
  },
  companionsHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  companionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '16px 0px',
    paddingBottom: '4px',
    borderBottom: '1px solid #00000044'
  },
  roundedIcon: {
    borderRadius: '32px',
    cursor: 'pointer',
    fontSize: '24px',
    '&:hover': {
      backgroundColor: `${PRIMARY_COLOR}55`,
      transition: 'background-color 0.2s ease'
    }
  }
});

class PatientEdit extends EditBase {
  constructor() {
    super();

    this.state = {
      openCompanionDialog: false,
      companionToEdit: null,
      companions: [],
      isRemove: false,
      model: {
        id: '',
        name: '',
        identifier: '',
        birthDate: '',
        hospital: '',
        sex: '',
        pregnant: false,
        pacemaker: false,
        epileptic: false,
        healthy_plan: '',
        ventilation_start: '',
        ventilation_end: '',
        uti_entry: '',
        uti_reason: '',
        record: '',
        notes: '',
        phone: '',
        email: '',
        document: '',
      }
    }

    this.role = AuthService.getRole();

    if (this.role === 'V') {
      this.state.getClients = true;
    }
  }

  options = {
    description: 'Paciente',
    service: PatientService,
    module: 'pacientes',
  };

  handleChange = (name) => (event) => {
    var model = this.state.model;
    model[name] = event.target.value;
    this.setState({ model });

    if (name === 'sex' && event.target.value === 'M') {
      this.state.model['pregnant'] = false;
      this.setState({ model });
    }
  };

  handleChangeCheckbox = (name) => (event) => {
    var model = this.state.model;
    model[name] = event.target.checked;
    this.setState({ model });
  };

  doAfterLoadData = (message, type) => {
    this.props.enqueueSnackbar(message, {
      variant: type,
    });
  };

  doAfterGet(data) {
    this.setState({
      companions: data.companions
    })
  }

  doBeforeSave() {
    return new Promise((resolve, reject) => {
      resolve({
        ...this.state.model,
        role: '6', // paciente é usuario com role = 6
        companions: this.state.companions
      });
    });
  }

  openCompanionDialog() {
    this.setState({
      openCompanionDialog: true,
      companionToEdit: null
    })
  }

  closeCompanionDialog() {
    this.setState({
      openCompanionDialog: false
    })
  }

  async onConfirmCompanion(companionData) {
    // verifica se ele já esta selecionado
    if (this.state.companions.find((companion) => companion.id === companionData.id)) {
      this.props.enqueueSnackbar('Acompanhante já selecionado!', {
        variant: 'info',
      });

      return;
    }

    if (this.state.model.id) {
      // esta editando: precisa criar a relação manualmente mesmo
      await CompanionService.createCompanionPatientRelation(companionData.id, this.state.model.id)
      this.get(this.state.model.id)
    } else {
      this.setState({
        companions: [...this.state.companions, companionData]
      })
    }
  }

  onEditCompanion(companionData) {
    if (this.state.model.id) {
      this.get(this.state.model.id)
    } else {
      this.setState({
        companions: this.state.companions.map(
          (companion) => companion.id === companionData.id ? companionData : companion
        )
      })
    }
  }

  onRemoveCompanion(companionData) {
    if (this.state.model.id) {
      this.get(this.state.model.id)
    } else {
      this.setState({
        companions: this.state.companions.filter(
          (companion) => companion.id !== companionData.id
        )
      })
    }
  }

  doRender = () => {
    const { classes } = this.props;
    const { model, clients, companions, openCompanionDialog, companionToEdit, isRemove } = this.state;
    const role = this.role;

    return (
      <div>
        <MuiThemeProvider theme={formTheme}>

          {!this.state.isDialog && (
            <Typography variant="h4" gutterBottom>
              {this.is ? 'Novo Paciente' : 'Editando Paciente'}
            </Typography>
          )}

          <form className={classes.container} noValidate autoComplete="off">
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id="model-name"
                  label="Nome"
                  className={classes.textField}
                  value={model.name || ''}
                  onChange={this.handleChange('name')}
                  style={{ marginBottom: '16px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="sex-label-placeholder">
                    Sexo
                  </InputLabel>
                  <Select
                    value={model.sex || ''}
                    onChange={this.handleChange('sex')}
                    inputProps={{
                      name: 'sex',
                      id: 'sex-label-placeholder',
                    }}
                    className={classes.formControl}
                  >
                    <MenuItem value="M">Masculino</MenuItem>
                    <MenuItem value="F">Feminino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {role === 'V' && (
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor="hospital-label-placeholder">
                      Cliente
                    </InputLabel>
                    <Select
                      value={model.hospital || ''}
                      onChange={this.handleChange('hospital')}
                      inputProps={{
                        name: 'hospital',
                        id: 'hospital-label-placeholder',
                      }}
                      disabled={model.id ? true : false}
                      className={classes.formControl}
                    >
                      {clients.map((client, index) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  id="model-document"
                  label="CPF / CNPJ"
                  className={classes.textField}
                  value={model.document || ''}
                  onChange={this.handleChange('document')}
                  InputProps={{ inputComponent: MaskedCpfCnpj }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="model-identifier"
                  label="Identificador"
                  className={classes.textField}
                  value={model.identifier || ''}
                  onChange={this.handleChange('identifier')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="model-birthDate"
                  label="Data de Nascimento"
                  value={model.birthDate || ''}
                  onChange={this.handleChange('birthDate')}
                  className={classes.textField}
                  InputProps={{ inputComponent: MaskedBirthday }}
                />
              </Grid>


              <Grid item xs={6}>
                <TextField
                  id="model-phone"
                  label="Número de Telefone"
                  className={classes.textField}
                  value={model.phone || ''}
                  onChange={this.handleChange('phone')}
                  InputProps={{ inputComponent: MaskedPhoneNumber }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-email"
                  label="Email"
                  className={classes.textField}
                  value={model.email || ''}
                  onChange={this.handleChange('email')}
                  disabled={model.id ? true : false}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-healthy_plan"
                  label="Plano de saúde"
                  className={classes.textField}
                  value={model.healthy_plan || ''}
                  onChange={this.handleChange('healthy_plan')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-ventilation_start"
                  label="Data início da ventilação"
                  value={model.ventilation_start || ''}
                  onChange={this.handleChange('ventilation_start')}
                  className={classes.textField}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-ventilation_end"
                  label="Data fim da ventilação"
                  value={model.ventilation_end || ''}
                  onChange={this.handleChange('ventilation_end')}
                  className={classes.textField}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-uti_entry"
                  label="Data entrada da UTI"
                  value={model.uti_entry || ''}
                  onChange={this.handleChange('uti_entry')}
                  className={classes.textField}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-uti_reason"
                  label="Razão de entrada na UTI"
                  className={classes.textField}
                  value={model.uti_reason || ''}
                  onChange={this.handleChange('uti_reason')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-record"
                  label="Prontuário"
                  className={classes.textField}
                  value={model.record || ''}
                  onChange={this.handleChange('record')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="model-notes"
                  label="Observação"
                  className={classes.textField}
                  value={model.notes || ''}
                  onChange={this.handleChange('notes')}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="default"
                          checked={model.pacemaker}
                          onChange={this.handleChangeCheckbox('pacemaker')}
                          value="pacemaker"
                        />
                      }
                      label="Possui marca-passo"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="default"
                          checked={model.epileptic}
                          onChange={this.handleChangeCheckbox('epileptic')}
                          value="epileptic"
                        />
                      }
                      label="Epilético(a)"
                    />
                    {model.sex === 'F' && (
                      <FormControlLabel
                        control={
                          <Switch
                            color="default"
                            checked={model.pregnant}
                            onChange={this.handleChangeCheckbox('pregnant')}
                            value="pregnant"
                          />
                        }
                        label="Grávida"
                      />
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>

          <Divider style={{ height: '2px' }} />

          <div className={classes.companionsHeader} >
            <Typography
              variant="h6"
              gutterBottom
              style={{ margin: '16px 0px' }}
            >
              Acompanhantes
            </Typography>
            <Fab
              color="primary"
              size="small"
              onClick={() => this.openCompanionDialog()}
            >
              <AddIcon />
            </Fab>
          </div>

          {companions.map((companion, index) => (
            <div key={index} className={classes.companionContainer} >
              <div style={{ flex: 1 }}>
                <PersonIcon color="primary" />
              </div>
              <div style={{ flex: 10, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" >
                  {companion.name}
                </Typography>
                <Typography variant="body2" >
                  {companion.email}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <EditIcon color="primary" className={classes.roundedIcon} onClick={() => this.setState({
                  openCompanionDialog: true,
                  companionToEdit: companion
                })} />
              </div>
              <div style={{ flex: 1 }}>
                <DeleteIcon color="primary" className={classes.roundedIcon} onClick={() => this.setState({
                  openCompanionDialog: true,
                  companionToEdit: companion,
                  isRemove: true,
                })} />
              </div>
            </div>
          ))}

          <CompanionEdit
            open={openCompanionDialog}
            onClose={() => this.closeCompanionDialog()}
            onConfirmCompanion={(data) => this.onConfirmCompanion(data)}
            onEditCompanion={(data) => this.onEditCompanion(data)}
            onRemoveCompanion={(data) => this.onRemoveCompanion(data)}
            companionToEdit={companionToEdit}
            isRemove={isRemove}
            patientData={model}
          />
        </MuiThemeProvider>
      </div>
    );
  };
}

PatientEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(PatientEdit));
