import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Person from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { formatStringToCpfCnpj, formatStringToPhone } from '../../../helpers/formatters';
import EditDialog from '../base/EditDialog';
import UserService from '../../../services/security/UserService';
import ExamService from '../../../services/ExamService';
import AuthService from '../../../services/AuthService';
import TherapyService from '../../../services/TherapyService';
import { ptBr } from '../../../helpers/MUIDataTableLabels';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class User extends Component {
  options = '';
  id = '';
  role = '';

  state = {
    value: 0,
    isConfirmDeleteOpen: false,
    isEditOpen: false,
    selectedId: 0,
    data: '',
    exames: '',
    terapias: '',
    loading: true,
  };

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.role = AuthService.getRole();
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleEdit = (id) => (event) => {
    this.setState({
      selectedId: id,
      isEditOpen: true,
    });
  };

  handleCloseEdit = () => {
    this.setState({ isEditOpen: false });
    this.getDataUser();
  };

  handleDetailsClick = (id, type) => (event) => {
    this.props.history.push(type + '/' + id);
  };

  handleRemove = () => {
    this.setState({ isConfirmDeleteOpen: true });
  };

  handleAcceptConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });

    UserService.remove(this.id).then(() => {
      this.props.history.goBack();
    });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });
  };

  getActionColumn = (id, type) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label="Detalhes" onClick={this.handleDetailsClick(id, type)}>
          <FindInPageOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  getDataUser = () => {
    UserService.get(this.id).then((data) => {
      this.setState({
        data: data,
      });
    });
  };

  getDataExamUser = () => {
    ExamService.getListUser(this.id).then((data) => {
      this.setState({
        exames: data,
      });
    });
  };

  getDataTherapyUser = () => {
    TherapyService.getListUser(this.id).then((data) => {
      this.setState({
        terapias: data,
        loading: false,
      });
    });
  };

  getData = () => {
    this.getDataUser();
    if (this.role === 'D') {
      this.setState({ loading: false });
      return;
    }

    this.getDataExamUser();
    this.getDataTherapyUser();

    this.tblExames = [
      {
        name: 'date_start',
        label: 'Data Início',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date_end',
        label: 'Data Fim',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'patientName',
        label: 'Paciente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'acoes',
        label: 'Ações',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    this.tblTerapias = [
      {
        name: 'date',
        label: 'Data de Realização',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'patientName',
        label: 'Paciente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'acoes',
        label: 'Ações',
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    this.options = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
    };
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>{' '}
        {'>'}
        &nbsp;{' '}
        <span
          onClick={this.handleBreadcrumb('/usuarios')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Usuários
        </span>{' '}
        {'>'}
        &nbsp;<span style={{ color: 'rgba(152, 149, 149, 1)' }}>Usuário</span>
      </h6>
    );
  }

  addAcoes(arr, type) {
    if (arr.count) {
      for (var i = 0; i < arr.list.length; i++) {
        arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
      }
    }
    return arr;
  }

  render() {
    const { classes } = this.props;
    const { value, loading } = this.state;
    const data = this.state.data;
    const exames = this.addAcoes(this.state.exames, '/diagnostico');
    const terapias = this.addAcoes(this.state.terapias, '/terapia');

    return (
      <div>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
        >
          Usuário
        </Typography>
        <Person style={{ float: 'left', margin: '13px 10px 10px 0', fontSize: 30 }} />
        <Fab
          color="default"
          size="small"
          onClick={this.handleRemove}
          style={{ float: 'right', marginRight: 10, marginTop: 8 }}
        >
          <DeleteIcon />
        </Fab>
        <Fab
          color="default"
          size="small"
          onClick={this.handleEdit(this.id)}
          style={{ float: 'right', marginRight: 10, marginTop: 8 }}
        >
          <EditIcon />
        </Fab>

        {loading && <CircularProgress />}

        {!loading && (
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
              >
                <Tab label="Dados do Usuário" />
                {data.role === 2 && this.role !== 'D' && <Tab label="Tratamentos" />}
                {data.role === 2 && this.role !== 'D' && <Tab label="Diagnósticos" />}
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Nome: {data.name}</Paper>
                  </Grid>
                  {data.document ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Documento: {formatStringToCpfCnpj(data.document)}</Paper>
                    </Grid>
                  ) : null}
                  {data.phone ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Telefone: {formatStringToPhone(data.phone)}</Paper>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Email: {data.email}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Data do cadastro: {data.registerDate}</Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Perfil: {data.formattedRole}</Paper>
                  </Grid>
                  {data.role !== 0 && data.role !== 5 && (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Cliente: {data.hospital_name}</Paper>
                    </Grid>
                  )}
                  {data.role === 5 && (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Distribuidor: {data.distributorName}</Paper>
                    </Grid>
                  )}
                </Grid>
              </TabContainer>
            )}
            {value === 1 && data.role === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={'Tratamentos'}
                  data={terapias.list}
                  columns={this.tblTerapias}
                  options={this.options}
                />
              </TabContainer>
            )}
            {value === 2 && data.role === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={'Diagnósticos'}
                  data={exames.list}
                  columns={this.tblExames}
                  options={this.options}
                />
              </TabContainer>
            )}
          </div>
        )}

        <EditDialog
          edit="security/UserEdit"
          title="Editar Usuário"
          id={this.state.selectedId}
          isOpen={this.state.isEditOpen}
          onCloseCallBack={this.handleCloseEdit}
        />

        <Dialog
          open={this.state.isConfirmDeleteOpen}
          onClose={this.handleCloseConfirmDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Remover o usuário'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deseja realmente remover o usuário <b>{this.state.data.name}</b> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAcceptConfirmDelete} color="primary">
              Sim
            </Button>
            <Button onClick={this.handleCloseConfirmDelete} color="primary" autoFocus>
              Não
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(User);
