import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import DeviceOtherIcon from '@material-ui/icons/DevicesOther';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

import { formatTableDate } from '../../../helpers/formatters';
import EquipmentService from '../../../services/product/EquipmentService';
import AuthService from '../../../services/AuthService';
import ExamService from '../../../services/ExamService';
import TherapyService from '../../../services/TherapyService';
import { ptBr } from '../../../helpers/MUIDataTableLabels';
import EditDialog from '../base/EditDialog';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: '10px',
    'box-shadow': 'none',
    'border-radius': 'none',
  },
});

class Equipment extends Component {
  id = '';
  role = AuthService.getRole();

  state = {
    value: 0,
    equipmentData: {},
    exams: [],
    therapies: [],
    loading: true,
    isConfirmDeleteOpen: false,
    isEditOpen: false,
    selectedId: 0,

    // exams table control
    examsTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },

    // therapy table control
    therapyTable: {
      page: 0,
      pageSize: 10,
      searchText: null,
      sortColumn: null,
      sortDirection: null,
    },
  };

  tblExames = [
    {
      name: 'date_start',
      label: 'Data Início',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'date_end',
      label: 'Data Fim',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'patientName',
      label: 'Paciente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientName',
      label: 'Hospital',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  tblTerapias = [
    {
      name: 'date',
      label: 'Data de Realização',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => formatTableDate(value),
      },
    },
    {
      name: 'patientName',
      label: 'Paciente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientName',
      label: 'Hospital',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'therapyType',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'acoes',
      label: 'Ações',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleDetailsClick = (id, type) => (event) => {
    this.props.history.push(type + '/' + id);
  };

  getActionColumn = (id, type) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label="Detalhes" onClick={this.handleDetailsClick(id, type)}>
          <FindInPageOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  // para evitar repetição do tratamento de erro
  handleRequestError(error) {
    console.log(error);
    this.props.enqueueSnackbar('Falha ao buscar dados do equipamento!', {
      variant: 'error',
    });
  }

  getDataEquipment = () => {
    return new Promise((resolve, reject) => {
      EquipmentService.get(this.id)
        .then((equipment) => {
          this.setState({
            equipmentData: equipment,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getDataExamEquipment = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.examsTable;

      ExamService.getListEquipment(this.id, page, pageSize, searchText, sortColumn, sortDirection)
        .then((data) => {
          this.setState({
            exams: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getDataTherapyEquipment = () => {
    return new Promise((resolve, reject) => {
      const { page, pageSize, searchText, sortColumn, sortDirection } = this.state.therapyTable;

      TherapyService.getListEquipment(
        this.id,
        page,
        pageSize,
        searchText,
        sortColumn,
        sortDirection
      )
        .then((data) => {
          this.setState({
            therapies: data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  getData = async () => {
    try {
      this.setState({ loading: true });

      await this.getDataEquipment();

      // distribuidor não mostra os dados de tratamento e diagnostico
      if (this.role !== 'D') {
        await this.getDataExamEquipment();
        await this.getDataTherapyEquipment();
      }
    } catch (error) {
      this.handleRequestError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  setSortColumn = (columns, type) => {
    let sortColumn = columns.find((column) => {
      return !!column.sortDirection;
    });

    if (type === 'therapy') {
      this.setState({
        therapyTable: {
          ...this.state.therapyTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    } else if (type === 'exam') {
      this.setState({
        examsTable: {
          ...this.state.examsTable,
          sortColumn: sortColumn ? sortColumn.name : null,
          sortDirection: sortColumn ? sortColumn.sortDirection : null,
        },
      });
    }
  };

  toggleClicked = () => {
    const status = this.state.equipmentData.status ? 0 : 1;

    this.props.enqueueSnackbar(
      status === 1 ? 'Ativando equipamento...' : 'Desativando equipamento...',
      {
        variant: 'info',
      }
    );

    EquipmentService.editStatus(this.state.equipmentData.id, status).then((data) => {
      this.setState((previousState) => {
        previousState.equipmentData.status = status;
        return previousState;
      });

      this.props.enqueueSnackbar(
        status === 1 ? 'Equipamento ativado!' : 'Equipamento desativado!',
        {
          variant: 'success',
        }
      );
    });
  };

  toggleBlockClicked = () => {
    const shouldBlockEquipment = this.state.equipmentData.blocked ? false : true;

    this.props.enqueueSnackbar(
      shouldBlockEquipment ? 'Bloqueando equipamento...' : 'Desbloqueando equipamento...',
      {
        variant: 'info',
      }
    );

    EquipmentService.editBlocked(this.state.equipmentData.id, shouldBlockEquipment)
      .then((data) => {
        this.setState((previousState) => {
          previousState.equipmentData.blocked = shouldBlockEquipment;
          return previousState;
        });

        this.props.enqueueSnackbar(
          shouldBlockEquipment ? 'Equipamento bloqueado!' : 'Equipamento desbloqueado!',
          {
            variant: 'success',
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.props.enqueueSnackbar('Falha ao alterar o status de bloqueado do equipamento!', {
          variant: 'error',
        });
      });
  };

  handleBreadcrumb = (page) => (event) => {
    this.props.history.push(page);
  };

  breadcrumb() {
    return (
      <h6 style={{ marginTop: '10px', marginBottom: '10px' }}>
        <span
          onClick={this.handleBreadcrumb('/')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Home
        </span>
        {' > '}
        <span
          onClick={this.handleBreadcrumb('/equipamentos')}
          style={{ cursor: 'pointer', textDecoration: 'none', color: '#000' }}
        >
          Equipamentos
        </span>
        {' > '}
        <span style={{ color: 'rgba(152, 149, 149, 1)' }}>Equipamento</span>
      </h6>
    );
  }

  addAcoes(arr, type) {
    if (arr.count) {
      for (var i = 0; i < arr.list.length; i++) {
        arr.list[i].acoes = this.getActionColumn(arr.list[i].id, type);
      }
    }
    return arr;
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.examsTable !== this.state.examsTable) {
        this.getDataExamEquipment();
      }
      if (prevState.therapyTable !== this.state.therapyTable) {
        this.getDataTherapyEquipment();
      }
    } catch (error) {
      this.handleRequestError(error);
    }
  }

  handleEdit = (id) => (event) => {
    this.setState({
      selectedId: id,
      isEditOpen: true,
    });
  };

  handleCloseEdit = () => {
    this.setState({ isEditOpen: false });
    try {
      this.getDataEquipment();
    } catch (error) {
      this.handleRequestError(error);
    }
  };

  handleRemove = () => {
    this.setState({ isConfirmDeleteOpen: true });
  };

  handleAcceptConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });

    EquipmentService.remove(this.id).then(() => {
      this.props.history.goBack();
    });
  };

  handleCloseConfirmDelete = () => {
    this.setState({ isConfirmDeleteOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { value, loading, equipmentData, examsTable, therapyTable } = this.state;
    const exames = this.addAcoes(this.state.exams, '/diagnostico');
    const terapias = this.addAcoes(this.state.therapies, '/terapia');

    const optionsExam = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.exams.count,
      page: examsTable.page,
      rowsPerPage: examsTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              examsTable: {
                ...this.state.examsTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              examsTable: {
                ...this.state.examsTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'exam');
            break;
          default:
            break;
        }
      },
    };

    const optionsTherapy = {
      filter: false,
      filterType: 'dropdown',
      textLabels: ptBr,
      selectableRows: false,
      count: this.state.therapies.count,
      page: therapyTable.page,
      rowsPerPage: therapyTable.pageSize,
      serverSide: true,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'search':
            this.setState({
              therapyTable: {
                ...this.state.therapyTable,
                searchText: tableState.searchText,
              },
            });
            break;
          case 'changePage':
          case 'changeRowsPerPage':
            this.setState({
              therapyTable: {
                ...this.state.therapyTable,
                page: tableState.page,
                pageSize: tableState.rowsPerPage,
              },
            });
            break;
          case 'sort':
            this.setSortColumn(tableState.columns, 'therapy');
            break;
          default:
            break;
        }
      },
    };

    return (
      <div>
        {this.breadcrumb()}

        <Typography
          variant="h5"
          gutterBottom
          style={{ float: 'left', paddingTop: 15, margin: '0 3px 0 20px' }}
        >
          Equipamento - {equipmentData.serial}
        </Typography>
        <DeviceOtherIcon style={{ float: 'left', margin: '13px 10px 10px 5px', fontSize: 30 }} />
        {this.role === 'V' ? (
          <>
            <Fab
              color="default"
              size="small"
              onClick={this.handleRemove}
              style={{ float: 'right', marginRight: 10, marginTop: 8 }}
            >
              <DeleteIcon />
            </Fab>
            <Fab
              color="default"
              size="small"
              onClick={this.handleEdit(this.id)}
              style={{ float: 'right', marginRight: 10, marginTop: 8 }}
            >
              <EditIcon />
            </Fab>
          </>
        ) : null}

        {loading && <CircularProgress />}

        {!loading && (
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
              >
                <Tab label="Dados do Equipamento" />
                {this.role !== 'D' && <Tab label="Tratamentos" />}
                {this.role !== 'D' && <Tab label="Diagnósticos" />}
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Modelo: {equipmentData.equipmentTypeName}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>Serial: {equipmentData.serial}</Paper>
                  </Grid>
                  {equipmentData.distributorName ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Distribuidor: {equipmentData.distributorName}
                      </Paper>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper}>
                      Versão do Software: {equipmentData.app_version}
                    </Paper>
                  </Grid>
                  {equipmentData.firmware_version ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Versão do Firmware: {equipmentData.firmware_version}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.hardware_version ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Versão do Hardware: {equipmentData.hardware_version}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.equipmentInstallationDate ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Data de Instalação: {equipmentData.equipmentInstallationDate}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.equipmentUpdateDate ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Última atualização do equipamento: {equipmentData.equipmentUpdateDate}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.appUpdateDate ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Última atualização de software: {equipmentData.appUpdateDate}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.pcbPrincipal ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        PCB Principal: {equipmentData.pcbPrincipal}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.fonteMedica ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Fonte médica: {equipmentData.fonteMedica}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.pcb7 ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        PCB 7 Conectores Gabinete: {equipmentData.pcb7}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.pcb6 ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        PCB 6 Conectores Gabinete: {equipmentData.pcb6}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.caneta ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Caneta: {equipmentData.caneta}</Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.tela ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>Tela Android: {equipmentData.tela}</Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.date ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Data de fabricação: {equipmentData.date}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.bluetooth ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Endereço bluetooth: {equipmentData.bluetooth}
                      </Paper>
                    </Grid>
                  ) : null}
                  {equipmentData.numberOfChannels ? (
                    <Grid item xs={12} sm={6}>
                      <Paper className={classes.paper}>
                        Número de canais: {equipmentData.numberOfChannels}
                      </Paper>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Paper className={classes.paper} style={{ marginRight: 8 }}>
                      Desativado / Ativado:{' '}
                    </Paper>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={equipmentData.status === 1 ? true : false}
                          onClick={this.toggleClicked}
                          name="status"
                        ></Switch>
                      }
                      label={equipmentData.status === 1 ? 'Ativado' : 'Desativado'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Paper className={classes.paper} style={{ marginRight: 8 }}>
                      Desbloqueado / Bloqueado:{' '}
                    </Paper>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={equipmentData.blocked ? true : false}
                          onClick={this.toggleBlockClicked}
                          name="status"
                        ></Switch>
                      }
                      label={equipmentData.blocked ? 'Bloqueado' : 'Desbloqueado'}
                    />
                  </Grid>
                </Grid>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <MUIDataTable
                  title={'Tratamentos'}
                  data={terapias.list}
                  columns={this.tblTerapias}
                  options={optionsTherapy}
                />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <MUIDataTable
                  title={'Diagnósticos'}
                  data={exames.list}
                  columns={this.tblExames}
                  options={optionsExam}
                />
              </TabContainer>
            )}
          </div>
        )}

        {this.role === 'V' ? (
          <>
            <EditDialog
              edit="product/EquipmentEdit"
              title="Editar Equipamento"
              id={this.state.selectedId}
              isOpen={this.state.isEditOpen}
              onCloseCallBack={this.handleCloseEdit}
            />

            <Dialog open={this.state.isConfirmDeleteOpen} onClose={this.handleCloseConfirmDelete}>
              <DialogTitle id="alert-dialog-title">{'Remover o equipamento'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Deseja realmente remover o equipamento <b>{equipmentData.serial}</b>?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleAcceptConfirmDelete} color="primary">
                  Sim
                </Button>
                <Button onClick={this.handleCloseConfirmDelete} color="primary" autoFocus>
                  Não
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </div>
    );
  }
}

Equipment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withSnackbar(Equipment));
