const CYCLIC = 'Cíclico';
const NON_CYCLIC = 'Não Cíclico';
const ON_OFF = 'ON - OFF';

export const PROTOCOL_TYPES = Object.freeze({
  0: CYCLIC,
  1: NON_CYCLIC,
  2: ON_OFF,
});

const SYNCHRONOUS = 'Síncrono';
const ALTERNATE = 'Alternado';

export const PROTOCOL_INTERVALS = Object.freeze({
  0: SYNCHRONOUS,
  1: ALTERNATE,
});
