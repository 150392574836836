import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import AppointmentService from '../../../services/AppointmentService';

class SchedulingDelete extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
    }
  }
  handleClose = () => {
    this.props.handleClose()
  }

  handleDelete = () => {
    this.remove(this.props.id)
    this.props.handleDelete()
  }

  doAfterLoadData = (message, type) => {
    this.props.enqueueSnackbar(message, {
      variant: type,
    });
  };

  remove = (id) => {
    AppointmentService.remove(id).then((data) => {
      this.doAfterLoadData('Agendamento removido com sucesso...', 'success');
    })
  }

  render() {
    return (
      <Dialog fullScreen={false}
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você deseja excluir o agendamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDelete}>SIM</Button>
          <Button onClick={this.handleClose}>NAO</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
const styles = (theme) => ({

});
export default withStyles(styles, { withTheme: true })(withSnackbar(SchedulingDelete));
