import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import ReactToPrint from 'react-to-print';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import TherapyPrint from '../../print/TherapyPrint';
import TherapyService from '../../../services/TherapyService';
import { TREATMENT_TYPES, EXPERT_LABELS, RECOVERY_LABELS } from '../../../constants';
import { Breadcrumb, InformationLine, HtmlTooltip, CustomTooltip } from '../../layout';
import {
  THERAPY_DATA_INITIAL_VALUE,
  parseTherapyDataFromBackendToScreen,
} from '../../../helpers/parsers';

const EXPERT = 0;
const PROFESSIONAL = 1;
const RECOVERY = 2;

const TabContainer = (props) => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {props.children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Therapy extends Component {
  id = '';

  constructor() {
    super();

    this.state = {
      value: 0,
      is_open: false,
      data: THERAPY_DATA_INITIAL_VALUE,
      loading: true,
    };
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  getData() {
    TherapyService.get(this.id).then((data) => {
      this.setState({
        data: parseTherapyDataFromBackendToScreen(data),
        loading: false,
      });
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          textColorInherit: {
            minWidth: 100,
            '&$selected': {
              color: '#4caf50',
            },
          },
        },
        MuiTabs: {
          indicator: {
            backgroundColor: '#4caf50',
          },
        },
      },
      typography: {
        useNextVariants: true,
      },
    });

  tableCellStyle = { padding: '4px', textAlign: 'center', border: '1px solid #AAAAAA77' };

  getLabelFromParameterKey = (key) => {
    switch (key) {
      case 'frequency':
        return 'Frequência (Hz)';
      case 'rising':
        return 'Tempo de subida (s)';
      case 'falling':
        return 'Tempo de descida (s)';
      case 'phase':
        return 'Tempo de sustentação (s)';
      default:
        return '';
    }
  };

  render = () => {
    const { value, loading, data } = this.state;

    const pdfTherapyData = {
      data,
      muscles: data.data.muscles,
    };

    const getCardTitleFromType = () => {
      switch (data.type) {
        case EXPERT:
          return 'Critérios de Segurança - Expert';
        case PROFESSIONAL:
          return 'Dados do Protocolo';
        case RECOVERY:
          return 'Dados da Recuperação';
        default:
          return '';
      }
    };

    const getCardContentFromType = () => {
      switch (data.type) {
        case EXPERT:
          return <ExpertCardContent />;
        case PROFESSIONAL:
          return <ProfessionalCardContent />;
        case RECOVERY:
          return <RecoveryCardContent />;
        default:
          return '';
      }
    };

    const ExpertCardContent = () => (
      <>
        {Object.keys(data.warnings).map((key, index, array) => (
          <InformationLine
            key={index}
            leftSide={EXPERT_LABELS[key]}
            rightSide={
              key === 'pressao' || key === 'frequencia' ? (
                data.warnings[key] === 0 ? (
                  'Não Informado'
                ) : (
                  data.warnings[key]
                )
              ) : (
                <Checkbox
                  color="primary"
                  checked={data.warnings[key] ? true : false}
                  style={{ padding: 0, marginLeft: '8px' }}
                />
              )
            }
            divider={index !== array.length - 1}
            noMargin
          />
        ))}
      </>
    );

    const ProfessionalCardContent = () => (
      <>
        {data.protocol.protocol_name ? (<InformationLine
          leftSide={'Nome'}
          rightSide={data.protocol.protocol_name}
          predominantRightSide
        />): null}

        {data.protocol.protocol_info ? (<InformationLine
          leftSide={'Descrição'}
          rightSide={data.protocol.protocol_info}
          predominantRightSide
        />): null}

        <InformationLine
          leftSide={'Duração do aquecimento do protocolo'}
          rightSide={data.protocol.protocol_warmup_duration ? data.protocol.protocol_warmup_duration : 'Não se Aplica' }
        />
        <InformationLine
          leftSide={'Duração da recuperação do protocolo'}
          rightSide={data.protocol.protocol_recovery_duration ? data.protocol.protocol_recovery_duration : 'Não se Aplica'}
        />
        <InformationLine
          leftSide={'Duração do protocolo'}
          rightSide={data.protocol.protocol_duration}
        />
        <InformationLine leftSide={'Duração OFF'} rightSide={data.protocol.off_duration ? data.protocol.off_duration : 'Não se Aplica'} />
        { data.protocol.protocol_type ? (<InformationLine leftSide={'Tipo do protocolo'} rightSide={data.protocol.protocol_type} />): null}

        { data.protocol.protocol_stimulus ? (<InformationLine
          leftSide={'Número de estímulos recomendados para este protocolo'}
          rightSide={data.protocol.protocol_stimulus}
          divider={false}
        />): null}

        <Typography
          variant="body1"
          gutterBottom
          style={{ margin: '16px 0px 4px 0px', textAlign: 'center' }}
        >
          Parâmetros do Protocolo
        </Typography>
        <Divider />

        <table
          style={{
            width: '100%',
            marginTop: '16px',
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            <tr>
              <th style={this.tableCellStyle}>{''}</th>
              <th style={this.tableCellStyle}>Aquecimento</th>
              <th style={this.tableCellStyle}>Recuperação</th>
              <th style={this.tableCellStyle}>Fase ON</th>
              <th style={this.tableCellStyle}>Fase OFF</th>
            </tr>
            {Object.keys(data.protocol.protocol_warmup_parameters).map((key, index) =>
              key !== 'position' ? (
                <tr key={index}>
                  <td style={{ ...this.tableCellStyle, textAlign: 'left' }}>
                    {this.getLabelFromParameterKey(key)}
                  </td>
                  <td style={this.tableCellStyle}>
                    {data.protocol.protocol_warmup_parameters[key] ? data.protocol.protocol_warmup_parameters[key] : '-'}
                  </td>
                  <td style={this.tableCellStyle}>
                    {data.protocol.protocol_recovery_parameters[key] ? data.protocol.protocol_recovery_parameters[key] : '-'}
                  </td>
                  {data.protocol.protocol_phase[0] ? (
                    <td style={this.tableCellStyle}>{data.protocol.protocol_phase[0][key] ? data.protocol.protocol_phase[0][key] : '-' }</td>
                  ) : null}
                  {data.protocol.protocol_phase[1] ? (
                    <td style={this.tableCellStyle}>{data.protocol.protocol_phase[1][key] ? data.protocol.protocol_phase[1][key] : '-' }</td>
                  ) : null}
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </>
    );

    const RecoveryCardContent = () => (
      <>
        {Object.keys(data.recovery).map((key, index, array) => (
          <InformationLine
            key={index}
            leftSide={RECOVERY_LABELS[key]}
            rightSide={data.recovery[key] ? data.recovery[key] : "Não informado"}
            divider={index !== array.length - 1}
          />
        ))}
      </>
    );

    const TreatmentDataCard = () => (
      <Paper
        style={{
          backgroundColor: '#4caf50',
          color: 'white',
          paddingTop: '12px',
          margin: '0px 12px 12px 12px',
        }}
      >
        <div style={{ width: '100%', textAlign: 'center', marginBottom: '12px' }}>
          DADOS DO TRATAMENTO
        </div>

        <div
          style={{
            fontWeight: '400',
            fontSize: '13px',

            width: '100%',
            padding: '0px 16px 5px 16px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          <InformationLine leftSide={'Modo'} rightSide={data.mode} />
          <InformationLine
            leftSide={'Número de estímulos do aquecimento'}
            rightSide={data.warmup_stim_number}
            shouldShow={data.warmup_stim_number}
          />
          <InformationLine
            leftSide={'Duração do aquecimento'}
            rightSide={data.warmup_duration}
            shouldShow={data.warmup_duration}
          />
          <InformationLine
            leftSide={'Duração do aquecimento'}
            rightSide={data.warmup_duration}
            shouldShow={data.warmup_duration}
          />
          <InformationLine
            leftSide={'Duração do Tratamento programado'}
            rightSide={data.treatment_duration}
            shouldShow={data.treatment_duration}
          />
          <InformationLine
            leftSide={'Duração do Tratamento realizado'}
            rightSide={data.treatment_duration_done}
            shouldShow={data.treatment_duration_done}
          />
          <InformationLine
            leftSide={'Número de estímulos programado'}
            rightSide={data.treatment_stim_number}
            shouldShow={data.treatment_stim_number}
          />
          <InformationLine
            leftSide={'Número de estímulos realizado'}
            rightSide={data.treatment_stim_number_done}
            shouldShow={data.treatment_stim_number_done}
            divider={false}
          />
        </div>
      </Paper>
    );

    const isTypeValid = data.app_version ? true : false;

    return (
      <div style={{ flexGrow: 1 }}>
        <Breadcrumb
          pagesList={[{ page: 'Tratamentos', route: '/terapias' }, { page: 'Tratamento' }]}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', paddingTop: 8, margin: '0 3px 0 20px' }}
            >
              Tratamento {TREATMENT_TYPES[data.type]}
            </Typography>
            <EnhancedEncryptionIcon
              style={{ float: 'left', margin: '6px 10px 10px 0', fontSize: 30 }}
            />
          </div>

          {loading ? (
            <CircularProgress />
          ) : (
            <ReactToPrint
              trigger={() => (
                <IconButton
                  style={{
                    borderRadius: '32px',
                    marginRight: '16px',
                    padding: '0px 8px',
                    border: '1px solid #FF0000',
                  }}
                >
                  <PictureAsPdfIcon
                    style={{ marginRight: '8px', fontSize: 22, color: '#FF0000' }}
                  />
                  <p style={{ fontSize: 14, margin: '8px 0px', color: '#FF0000' }}>Exportar PDF</p>
                </IconButton>
              )}
              content={() => this.componentRef}
              documentTitle={'Tratamento - ' + pdfTherapyData.data.patientName}
            />
          )}
        </div>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Paper
                  style={{
                    backgroundColor: '#4caf50',
                    color: 'white',
                    paddingTop: '12px',
                    margin: '0px 12px 12px 12px',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center', marginBottom: '12px' }}>
                    DADOS GERAIS
                  </div>

                  <div
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',

                      width: '100%',
                      padding: '0px 16px 5px 16px',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    <InformationLine leftSide={'Paciente'} rightSide={data.patientName} />
                    <InformationLine leftSide={'Documento'} rightSide={data.patientCPF} />
                    <InformationLine leftSide={'Hospital'} rightSide={data.clientName} />
                    <InformationLine leftSide={'Data de Realização'} rightSide={data.date} />
                    <InformationLine
                      leftSide={'Tratamento'}
                      rightSide={data.status}
                      divider={false}
                    />
                  </div>
                </Paper>

                {isTypeValid ? <TreatmentDataCard /> : null}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper
                  style={{
                    backgroundColor: '#4caf50',
                    color: 'white',
                    paddingTop: '12px',
                    margin: '0px 12px 12px 12px',
                  }}
                >
                  <div style={{ width: '100%', textAlign: 'center', marginBottom: '12px' }}>
                    PARÂMETROS DE ESTIMULAÇÃO
                  </div>

                  <div
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',

                      width: '100%',
                      padding: '0px 16px 5px 16px',
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    <InformationLine
                      leftSide={'Largura de pulso'}
                      rightSide={data.pulse_width + ' µs'}
                    />
                    <InformationLine leftSide={'Frequência'} rightSide={data.frequency + ' Hz'} />
                    {(data.type !== RECOVERY && data.on_time) ? (<InformationLine leftSide={'Tempo ON'} rightSide={data.on_time + ' s'} />) : null}

                    {(data.type !== RECOVERY  && data.rise_time) ? (<InformationLine
                      leftSide={'Tempo de estímulo de subida'}
                      rightSide={data.rise_time + ' s'}
                    />): null}

                    {data.type !== RECOVERY ? (<InformationLine
                      leftSide={'Tempo de sustentação'}
                      rightSide={data.lift_time + ' s'}
                    />) : null}

                    {(data.type !== RECOVERY && data.falling_time) ? (<InformationLine
                      leftSide={'Tempo de estímulo de descida'}
                      rightSide={data.falling_time + ' s'}
                    />): null}

                    {(data.type !== RECOVERY && data.off_time) ? (<InformationLine leftSide={'Tempo OFF'} rightSide={data.off_time + ' s'} />): null}
                    
                    <InformationLine
                      leftSide={'Taxa de trabalho'}
                      rightSide={data.duty_cycle + ' %'}
                      divider={false}
                      shouldShow={data.duty_cycle}
                    />
                  </div>
                </Paper>
                {data.states.length ? (
                  <Paper
                    style={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      paddingTop: '12px',
                      margin: '0px 12px 12px 12px',
                    }}
                  >
                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '12px' }}>
                      OCORRÊNCIAS
                    </div>

                    <div
                      style={{
                        fontWeight: '400',
                        fontSize: '13px',

                        width: '100%',
                        padding: '0px 16px 5px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                    >
                      {data.states.map((state, index, array) => (
                        <InformationLine
                          key={index}
                          leftSide={state.date}
                          rightSide={state.state}
                          divider={index !== array.length - 1}
                        />
                      ))}
                    </div>
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isTypeValid ? (
                  <Paper
                    style={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      paddingTop: '12px',
                      margin: '0px 12px 12px 12px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {getCardTitleFromType()}
                    </div>

                    <div
                      style={{
                        fontWeight: '400',
                        fontSize: '13px',

                        width: '100%',
                        padding: '0px 16px 5px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                    >
                      {getCardContentFromType()}
                    </div>
                  </Paper>
                ) : (
                  <TreatmentDataCard />
                )}
              </Grid>
            </Grid>
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <Paper style={{ width: '100%' }}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    variant="standard"
                    scrollButtons="auto"
                    centered
                  >
                    {data.data.muscles.map((muscle) => (
                      <Tab key={muscle.column} label={muscle.column} />
                    ))}
                  </Tabs>
                </AppBar>

                {data.data.muscles.map(
                  (muscle, index) =>
                    value === index && (
                      <TabContainer key={index}>
                        <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
                          {muscle.desc}&nbsp;
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  <b>Eletrodo Utilizado</b>
                                </Typography>
                                {muscle.electrode_material !== 'Undefined' ? (<Typography color="inherit">
                                  Tipo: {muscle.electrode_material}
                                </Typography>): null}
                                <Typography color="inherit">
                                  Formato: {muscle.electrode_format}
                                </Typography>
                                <Typography color="inherit">
                                  Tamanho: {muscle.electrode_width}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <InfoIcon />
                          </HtmlTooltip>
                        </Typography>

                        <ResponsiveContainer width="100%" minHeight="300px">
                          <LineChart
                            data={muscle.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={36} />
                            <Line
                              type="monotone"
                              dataKey="Intensidade(mA)"
                              stroke="#82ca9d"
                              strokeWidth={2}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </TabContainer>
                    )
                )}
              </Paper>
            </MuiThemeProvider>
          </>
        )}
        {/* componente de PDF do tratamento */}
        <div style={{ display: 'none' }}>
          <TherapyPrint ref={(el) => (this.componentRef = el)} data={pdfTherapyData} />
        </div>
      </div>
    );
  };
}

export default Therapy;
