import PropTypes from 'prop-types';
import ListBase from '../base/ListBase';
import ProtocoloService from '../../../services/ProtocoloService';
import { withStyles } from '@material-ui/core/styles';
import TimelineIcon from '@material-ui/icons/Timeline';
import { formatTableDate } from '../../../helpers/formatters';
import ProtocoloExcelService from '../../../services/excel/ProtocoloExcelService';

const styles = (theme) => ({});

class ProtocoloList extends ListBase {
  options = {
    description: 'Protocolo',
    descriptionPlural: 'Protocolos',
    icon: TimelineIcon,
    details: '/protocolo',
    module: 'protocolos',
    service: ProtocoloService,
    addColumnAction: true,
    hasNew: false,
    hasDetails: true,
    hasEdit: false,
    hasRemove: false,
    hasExcel: true,
    excelService: ProtocoloExcelService,

    columns: [
      {
        name: 'id',
        label: 'Id',
        options: {
          display: false,
          filter: true,
          sort: true,
        },
      },
      {
        name: 'patientName',
        label: 'Paciente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'clientName',
        label: 'Cliente',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date_start',
        label: 'Data Início',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
      {
        name: 'date_end',
        label: 'Data Fim',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => formatTableDate(value),
        },
      },
    ],
  };
}

ProtocoloList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProtocoloList);
