import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Label } from 'recharts';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import visuriLogo from '../../imgs/logo-side.png';
import { calculateGraphOperation } from '../../helpers/formatters';

class IDCurvePrint extends React.PureComponent {
  GRAPH_WIDTH = 490;
  GRAPH_HEIGHT = 185;
  GRAPH_MARGINS = { top: 5, left: 0, right: 40, bottom: 10 };

  getStyle(alert) {
    if (alert) {
      return { backgroundColor: 'rgba(187,17,5,0.5)' };
    }
  }

  render() {
    const { classes } = this.props;
    const { result, muscles } = this.props.data;

    const formatElectrodeSize = (electrodeSize) => {
      if (typeof electrodeSize !== 'string') {
        return electrodeSize;
      }

      // se houver repetição de 'cm' no formato
      if (electrodeSize.indexOf('cm') !== electrodeSize.lastIndexOf('cm')) {
        electrodeSize = electrodeSize.replaceAll('cm', '');
        return electrodeSize + ' cm';
      } else {
        return electrodeSize;
      }
    };

    const shouldBreakPage = (index) => (index + 1) % 4 === 0;
    const getPageNumberFromIndex = (index) => Math.floor((index + 1) / 4) + 1;
    const getTotalNumberOfPages = () => Math.floor(muscles.length / 4) + 1;

    const PageHeader = ({ style, index = 0 }) => {
      const pageNumber = getPageNumberFromIndex(index);
      const totalNumberOfPages = getTotalNumberOfPages();

      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Paciente: {result.patientName}</p>
              <p className={classes.rightText}>Data: {result.date_end}</p>
              <p className={classes.rightText}>
                Página: {pageNumber} / {totalNumberOfPages}
              </p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    return (
      <div className={classes.page}>
        <PageHeader />

        <h3 className={classes.infoHeader}>Dados gerais</h3>

        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>NOME DO PACIENTE:</p>
              <p className={classes.infoText}>{result.patientName}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                DOCUMENTO DO PACIENTE:
              </p>
              <p className={classes.infoText}>{result.patientCPF}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText} style={{ flex: 2 }}>
                IDADE DO PACIENTE:
              </p>
              <p className={classes.infoText}>{result.patientAge}</p>
            </div>
          </div>

          <div className={classes.infoColumn}>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>CLIENTE:</p>
              <p className={classes.infoText} style={{ flex: 2 }}>
                {result.clientName}
              </p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>DATA DO DIAGNÓSTICO:</p>
              <p className={classes.infoText}>{result.date_end}</p>
            </div>
            <div className={classes.infoLine}>
              <p className={classes.infoText}>IMPRESSO EM:</p>
              <p className={classes.infoText}>{moment(Date.now()).format('DD/MM/YYYY HH:mm')}</p>
            </div>
          </div>
        </div>

        <h3 className={classes.infoHeader}>Resultados</h3>

        {muscles.map((muscle, index) => (
          <React.Fragment key={index}>
            {shouldBreakPage(index) && (
              <PageHeader
                style={{
                  pageBreakBefore: 'always',
                  marginTop: '40px',
                }}
                index={index}
              />
            )}

            <div className={classes.muscleColumn}>
              {muscle.data && (
                <div className={classes.centerGraph}>
                  <h4 className={classes.infoHeader}>{muscle.desc}</h4>

                  <LineChart
                    data={muscle.data}
                    width={this.GRAPH_WIDTH}
                    height={this.GRAPH_HEIGHT}
                    margin={this.GRAPH_MARGINS}
                  >
                    <XAxis dataKey="Duração (μs)">
                      <Label
                        position="insideBottom"
                        style={{ textAnchor: 'middle', marginTop: '16px' }}
                        offset={-5}
                      >
                        Duração (μs)
                      </Label>
                    </XAxis>
                    <YAxis>
                      <Label
                        angle={270}
                        position="insideLeft"
                        style={{ textAnchor: 'middle' }}
                        offset={20}
                      >
                        Intensidade (mA)
                      </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="Intensidade (mA)" stroke="#000000" dot={false} />
                  </LineChart>
                </div>
              )}

              <div className={classes.electrodeInfo}>
                <div className={classes.infoHeader}>
                  <h5 style={{ marginBottom: '4px' }}>Estatísticas</h5>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade máxima:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'max', 'Intensidade (mA)')} mA
                  </p>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade mínima:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'min', 'Intensidade (mA)')} mA
                  </p>
                </div>
                <div className={classes.infoLine} style={{ marginBottom: '8px' }}>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    Intensidade média:
                  </p>
                  <p className={classes.infoText}>
                    {calculateGraphOperation(muscle.data, 'average', 'Intensidade (mA)')} mA
                  </p>
                </div>
                <div className={classes.infoHeader}>
                  <h5 style={{ marginBottom: '4px' }}>Eletrodo</h5>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText}>Tipo:</p>
                  <p className={classes.infoText} style={{ flex: 2 }}>
                    {muscle.electrode_material}
                  </p>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText}>Formato:</p>
                  <p className={classes.infoText}>{muscle.electrode_format}</p>
                </div>
                <div className={classes.infoLine}>
                  <p className={classes.infoText}>Tamanho:</p>
                  <p className={classes.infoText}>{formatElectrodeSize(muscle.electrode_width)}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

const styles = (theme) => ({
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  infoContainer: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  infoText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  infoHeader: {
    textAlign: 'center',
  },
  centerGraph: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  muscleColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableGraphContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  muscleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px 0px',
  },
  muscleHeder: {
    margin: 0,
    padding: 0,
  },
  electrodeInfo: {
    flex: 2,
  },
});

IDCurvePrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IDCurvePrint);
