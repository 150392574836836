import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Dialog, DialogActions } from '@material-ui/core';
import { Grid, Paper, Button } from '@material-ui/core';
import moment from 'moment';

import { InformationLine } from '../../layout';

import AppointmentService from '../../../services/AppointmentService'; 

const LABELS = Object.freeze({
  type: 'Tipo do Agendamento:',
  patientName: 'Paciente:',
  date_start: 'Data inicio:',
  date_end: 'Data fim:',
  model: 'Tipo:',
  street: 'Rua:',
  number: 'Número:',
  complement: 'Complemento:',
  neighborhood: 'Bairro:',
  city: 'Cidade:',
  state: 'Estado:',
  zipcode: 'CEP:',
  assessment: 'Observações: '
})

const TYPESCHEDULING = Object.freeze({
  1: 'Consulta',
  2: 'Avaliação'
})

const TYPEMODEL = Object.freeze({
  1: 'Presencial',
  2: 'Online'
})
 
class SchedulingDetails extends Component {
  constructor() {
      super();
      this.state = {
        data: [],
      }
    }

    componentDidMount(){
      this.get(this.props.id)
    }

    get = (id) => {
      AppointmentService.get(id).then((data) => {
        // this.doAfterLoadData(data);
        this.setState({
          data: data,
        });
      });
    };

    handleClose = () => {
      this.props.handleClose()
    }

    getTypeData (label, value){
      if (label === 'Tipo do Agendamento:')
        return TYPESCHEDULING[value]
      else if (label === 'Tipo:')
        return TYPEMODEL[value]
      else if (label === 'Data inicio:' || label === 'Data fim:')
        return moment(value).format('DD/MM/YYYY HH:mm:ss');
      else
        return value
    }
    render() {
      const { classes } = this.props;
      const { data } = this.state;
      return (
        <Dialog fullScreen={false}
          open={this.props.isOpen}
          onClose={this.handleClose}>
          <Paper style={{
            backgroundColor: '#4caf50',
            paddingTop: '8px',
            margin: '8px 8px 8px 8px',
            color: 'white'
          }}>
            <div className={classes.title}>
              Detalhes do Agendamento
            </div>
            <div className={classes.container}>
              <Grid container>
                {Object.keys(data).map((key, index, array) => (
                 (data[key] && LABELS[key]) ? (<Grid item xs={12} key={index}>
                    <InformationLine
                    predominantRightSide = {true} 
                    key = {index}
                    leftSide = {LABELS[key]} 
                    rightSide = {this.getTypeData(LABELS[key], data[key])}/>
                  </Grid>) : null
                ))}
              </Grid>
            </div>
          </Paper>
          <DialogActions>
              <Button autoFocus onClick={this.handleClose} color="primary">Fechar</Button>
          </DialogActions>
        </Dialog>
    )
  }
}
const styles = (theme) => ({
  title: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '12px',
    marginTop: '8px',
    fontSize: '20px'
  },
  container: {
    padding: '10px',
    backgroundColor: 'white',
    color: 'black',
  }
});
export default withStyles(styles, { withTheme: true })(withSnackbar(SchedulingDetails));
