import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import AppointmentService from '../../../services/AppointmentService';

class SchedulingMarkOff extends Component {

  handleClose = () => {
    this.props.handleClose()
  }

  handleMarkOff = () => {
    this.uncheckedAppointment(this.props.id)
    this.props.handleMarkOff()
    this.handleClose()
  }

  doAfterLoadData = (message, type) => {
    this.props.enqueueSnackbar(message, {
      variant: type,
    });
  };

  uncheckedAppointment = (id) => {
    AppointmentService.uncheckedAppointment(id).then((data) => {
      this.doAfterLoadData('Agendamento desmarcado com sucesso...', 'success');
    })
  }

  render() {
    return (
      <Dialog fullScreen={false}
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que deseja desmarcar o agendamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleMarkOff}>SIM</Button>
          <Button onClick={this.handleClose}>NAO</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
const styles = (theme) => ({

});
export default withStyles(styles, { withTheme: true })(withSnackbar(SchedulingMarkOff));
