import {
  DMT,
  PSR,
  PSE,
  TREATMENT_STATES,
  PROTOCOL_TYPES,
  PROTOCOL_INTERVALS,
  RECOVERY_TYPES,
  treatment_status,
  TREATMENT_MODES,
} from '../constants';
import { milisecondsToHourMinutesSeconds, millisecondsToSeconds } from './formatters';
import moment from 'moment';

export const THERAPY_DATA_INITIAL_VALUE = {
  patientName: '',
  patientCPF: '',
  userName: '',
  date: '',
  clientName: '',
  frequency: '',
  mode: '',
  treatment_stim_number: '',
  treatment_stim_number_done: '',
  off_time: '',
  rise_time: '',
  lift_time: '',
  falling_time: '',
  treatment_duration: '',
  treatment_duration_done: '',
  stim_time: '',
  stim_time_done: '',
  pulse_width: '',
  on_time: '',
  status: '',
  duty_cycle: '',
  warmup_stim_number: '',
  warmup_duration: '',
  type: '',
  protocol: {
    protocol_id: 0,
    protocol_name: '',
    protocol_info: '',
    protocol_recovery_duration: 0,
    protocol_warmup_duration: 0,
    protocol_duration: 0,
    protocol_interval: '',
    off_duration: 0,
    protocol_type: 0,
    protocol_stimulus: 0,
    protocol_warmup_parameters: {},
    protocol_recovery_parameters: {},
    protocol_phase: [],
  },
  data: {
    muscles: [],
  },
  warnings: {
    pressao: 0,
    frequencia: 0, // frequencia cardiaca
    temperatura: false,
    cpk: false,
    plaquetas: false,
    hemoglobina: false,
    hipotireoidismo: false,
    trombose: false,
    fio: false,
    pao: false,
    peep: false,
    ph: false,
    lactato: false,
    noradrenalina: false,
    vasopressina: false,
    dobutamina: false,
    milrinona: false,
    levosimendan: false,
  },
  recovery: {
    activity: '',
    minutes: 0,
    date: 0,
    pse: 0,
    psr: 0,
    dmt: 0,
    psr_feedback: 0,
    dmt_feedback: 0,
    recovery_type: '',
  },
  states: [],
};

const EXPERT = 0;
/*
  - formata os dados de um tratamento que chegam do backend para exibir no frontend, retornando o objeto formatado
  - verificações ternárias (? e :) garantem compatibilidade com versões antigas do recare e protegem contra
  disparar exceção no frontend
*/
export const parseTherapyDataFromBackendToScreen = (data) => ({
  ...data,
  treatment_duration: milisecondsToHourMinutesSeconds(data.treatment_duration),
  treatment_duration_done: milisecondsToHourMinutesSeconds(data.treatment_duration_done),
  stim_time: milisecondsToHourMinutesSeconds(data.stim_time),
  stim_time_done: milisecondsToHourMinutesSeconds(data.stim_time_done),
  warmup_duration: milisecondsToHourMinutesSeconds(data.warmup_duration),
  on_time: millisecondsToSeconds(data.on_time),
  rise_time: millisecondsToSeconds(data.rise_time),
  lift_time: millisecondsToSeconds(data.lift_time),
  falling_time: millisecondsToSeconds(data.falling_time),
  off_time: millisecondsToSeconds(data.off_time),
  status: treatment_status(data.status),
  mode: data.type === EXPERT ? TREATMENT_MODES[data.mode] : PROTOCOL_INTERVALS[data.protocol.protocol_interval],

  data:
    data.data && data.data.muscles && Array.isArray(data.data.muscles)
      ? {
          muscles: data.data.muscles.map((muscle) => ({
            ...muscle,
            column: muscle.muscle_initials,
            desc: muscle.muscle_name,
            electrode_material: muscle.electrode_material,
            electrode_format: muscle.electrode_format,
            electrode_width: muscle.electrode_size,
            data:
              muscle.stimulus && Array.isArray(muscle.stimulus)
                ? muscle.stimulus.map((stimulus, index) => ({
                    name: index + 1,
                    'Intensidade(mA)': stimulus,
                  }))
                : [],
          })),
        }
      : { muscles: [] },

  protocol: data.protocol
    ? {
        ...data.protocol,
        protocol_warmup_duration: milisecondsToHourMinutesSeconds(
          data.protocol.protocol_warmup_duration
        ),
        protocol_recovery_duration: milisecondsToHourMinutesSeconds(
          data.protocol.protocol_recovery_duration
        ),
        protocol_duration: milisecondsToHourMinutesSeconds(data.protocol.protocol_duration ? data.protocol.protocol_duration : data.protocol.protocol_warmup_duration + data.protocol.protocol_recovery_duration + (data.off_time + data.on_time) *  data.treatment_stim_number_done),
        protocol_interval: PROTOCOL_INTERVALS[data.protocol.protocol_interval],
        off_duration: milisecondsToHourMinutesSeconds(data.off_duration ? data.off_duration : data.off_time * data.treatment_stim_number_done),
        protocol_type: PROTOCOL_TYPES[data.protocol.protocol_type],
        protocol_warmup_parameters: data.protocol.protocol_warmup_parameters
          ? {
              // ...data.protocol.protocol_warmup_parameters,
              rising: millisecondsToSeconds(data.protocol.protocol_warmup_parameters.rising),
              falling: millisecondsToSeconds(data.protocol.protocol_warmup_parameters.falling),
              phase: millisecondsToSeconds(data.protocol.protocol_warmup_duration - (data.protocol.protocol_warmup_parameters.rising + data.protocol.protocol_warmup_parameters.falling)),
            }
          : {},
        protocol_recovery_parameters: data.protocol.protocol_recovery_parameters
          ? {
              ...data.protocol.protocol_recovery_parameters,
              rising: millisecondsToSeconds(data.protocol.protocol_recovery_parameters.rising),
              falling: millisecondsToSeconds(data.protocol.protocol_recovery_parameters.falling),
              phase: millisecondsToSeconds(data.protocol.protocol_recovery_duration - ( data.protocol.protocol_recovery_parameters.rising + data.protocol.protocol_recovery_parameters.falling)),
            }
          : {},
        protocol_phase:
          data.protocol.protocol_phase && Array.isArray(data.protocol.protocol_phase)
            ? data.protocol.protocol_phase.map((phase) => ({
                ...phase,
                rising: millisecondsToSeconds(phase.rising),
                falling: millisecondsToSeconds(phase.falling),
                phase: millisecondsToSeconds(phase.phase),
              }))
            : [],
      }
    : {},
  recovery: data.recovery
    ? {
        ...data.recovery,
        pse: PSE[data.recovery.pse],
        psr: PSR[data.recovery.psr],
        dmt: DMT[data.recovery.dmt],
        psr_feedback: PSE[data.recovery.psr_feedback],
        dmt_feedback: DMT[data.recovery.dmt_feedback],
        recovery_type: RECOVERY_TYPES[data.recovery.recovery_type],
      }
    : {},
  states:
    data.states && Array.isArray(data.states)
      ? data.states.map((state) => ({
          state: TREATMENT_STATES[state.state],
          date: moment(state.date).format('HH:mm:ss'),
        }))
      : [],
});
