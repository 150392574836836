import { ajax } from '../../helpers/ajax';
import AuthService from '../../services/AuthService';

class BaseExcelService {
  getBaseUrl() {
    const errorMessage = { message: 'getBaseUrl is not implemented in BaseExcelService class' };
    throw errorMessage;
  }

  downloadExcel = (searchText, sortColumn, sortDirection, customFilters = {}) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: this.getBaseUrl(),
        type: 'GET',
        headers: {
          'x-access-token': AuthService.getToken(),
        },
        data: {
          searchText,
          sortColumn,
          sortDirection,
          ...customFilters,
        },
        success: (excelUrl, textStatus, jqXHR) => {
          const link = document.createElement('a');
          link.href = excelUrl;
          link.download = 'Tratamentos.xlsx';
          document.body.appendChild(link);
          link.click();

          resolve(excelUrl);
        },
        error: (jqXHR, textStatus, errorThrown, errorMessage) => {
          if (jqXHR.responseJSON && jqXHR.responseJSON.hasOwnProperty('message')) {
            errorThrown = jqXHR.responseJSON;
          }

          reject(errorThrown);
        },
        complete: () => {},
      });
    });
  };
}

export default BaseExcelService;
