import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';

import Typography from '@material-ui/core/Typography';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import ExamService from '../../../services/ExamService';
import ExamPrint from '../../print/ExamPrint';
import { Breadcrumb } from '../../layout';
import { DIAGNOSTIC_INSTRUMENTS } from '../../../constants';
import { formatElectrodeSize } from '../../../helpers/formatters';

class Exam extends Component {
  id = '';

  constructor() {
    super();

    this.state = {
      value: 0,
      data: {
        patientName: '',
        patientCPF: '',
        userName: '',
        date_start: '',
        date_end: '',
        clientName: '',
        data: {
          muscles: [],
        },
      },
      pdfLoading: true,
    };
  }

  componentDidMount() {
    this.id = this.props.match.params.id;
    this.getData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getData() {
    ExamService.get(this.id).then((data) => {
      console.log(data)
      this.setState({
        data: data,
        pdfLoading: false,
      });
    });
  }

  getStyle(alert) {
    if (alert) {
      return { backgroundColor: 'rgba(187,17,5,0.5)' };
    }
  }

  handleClick(event) {
    event.preventDefault();
  }

  render = () => {
    const { pdfLoading, data: result } = this.state;
    const customColumnStyle = { padding: '15px 10px' };
    const customHeadStyle = { padding: '15px 10px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.54)' };
    const customLineStyle = { backgroundColor: 'rgba(223, 223, 223, 1)' };

    // verifica se existe electrode_material e electrode_format nos muscles
    // se existir, nao mostra o instrumento
    let shouldShowInstrument = true;
    for (const muscle of result.data.muscles) {
      if (
        (muscle.electrode_material !== undefined && muscle.electrode_format !== undefined) ||
        muscle.insturment !== undefined
      ) {
        shouldShowInstrument = false;
        break;
      }
    }

    const pdfExamData = {
      result: result,
      tableData: result.data.muscles,
      shouldShowInstrument,
    };

    return (
      <div>
        <Breadcrumb
          pagesList={[{ page: 'Diagnósticos', route: '/diagnosticos' }, { page: 'Diagnóstico' }]}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px',
          }}
        >
          <div>
            <Typography
              variant="h5"
              gutterBottom
              style={{ float: 'left', paddingTop: 8, margin: '0 3px 0 20px' }}
            >
              Diagnóstico
            </Typography>
            <AddToQueueIcon style={{ float: 'left', margin: '6px 10px 10px 0', fontSize: 30 }} />
          </div>

          {pdfLoading ? (
            <CircularProgress />
          ) : (
            <ReactToPrint
              trigger={() => (
                <IconButton
                  style={{
                    borderRadius: '32px',
                    marginRight: '16px',
                    padding: '0px 8px',
                    border: '1px solid #FF0000',
                  }}
                >
                  <PictureAsPdfIcon
                    style={{ marginRight: '8px', fontSize: 22, color: '#FF0000' }}
                  />
                  <p style={{ fontSize: 14, margin: '8px 0px', color: '#FF0000' }}>Exportar PDF</p>
                </IconButton>
              )}
              content={() => this.componentRef}
              documentTitle={'Diagnóstico - ' + pdfExamData.result.patientName}
            />
          )}
        </div>

        <Grid container>
          <Grid item xs={6}>
            <Paper
              style={{
                padding: '15px 0 0 0',
                color: '#fff',
                fontWeight: '500',
                margin: '0 10px 10px 0',
                textAlign: 'center',
                backgroundColor: '#4caf50',
                fontSize: '14px',
              }}
            >
              DADOS GERAIS
              <div
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  fontWeight: '400',
                  fontSize: '13px',
                }}
              >
                <p style={{ paddingTop: '15px' }}>Paciente: {result.patientName}</p>
                <Divider />
                <p style={{ paddingBottom: '15px' }}>Documento: {result.patientCPF}</p>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              style={{
                padding: '15px 0 0 0',
                color: '#fff',
                fontWeight: '500',
                margin: '0 10px 10px 0',
                textAlign: 'center',
                backgroundColor: '#4caf50',
                fontSize: '14px',
                marginRight: 0,
              }}
            >
              PARÂMETROS DO DIAGNÓSTICO
              <div
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  fontWeight: '400',
                  fontSize: '13px',
                }}
              >
                <p style={{ paddingTop: '15px' }}>Data: {result.date_start}</p>
                <Divider />
                <p style={{ paddingBottom: shouldShowInstrument ? '0px' : '15px' }}>
                  Cliente: {result.clientName}
                </p>
                {shouldShowInstrument ? (
                  <>
                    <Divider />
                    <p style={{ paddingBottom: '15px' }}>
                      Instrumento: {DIAGNOSTIC_INSTRUMENTS[result.instrument]}{' '}
                    </p>
                  </>
                ) : null}
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ width: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow style={customLineStyle}>
                    <TableCell style={customHeadStyle}>Músculo</TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Reobase(mA)
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Cronaxia(µs)
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Acomodação(mA)
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Indice de Acomodação
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Tipo de Eletrodo
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Formato do Eletrodo
                    </TableCell>
                    <TableCell align="center" style={customHeadStyle}>
                      Tamanho do Eletrodo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data.muscles.map((row) => (
                    <TableRow key={row.id} style={this.getStyle(row.alert)}>
                      <TableCell style={customColumnStyle}>{row.muscle_name}</TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.rheobase ? row.rheobase : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.chronaxie ? row.chronaxie : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.accommodation ? row.accommodation : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.accommodation_index ? row.accommodation_index : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.electrode_material ? row.electrode_material : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.electrode_format ? row.electrode_format : '-'}
                      </TableCell>
                      <TableCell align="center" style={customColumnStyle}>
                        {row.electrode_size ? formatElectrodeSize(row.electrode_size) : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        {/* componente de PDF do diagnóstico */}
        <div style={{ display: 'none' }}>
          <ExamPrint ref={(el) => (this.componentRef = el)} data={pdfExamData} />
        </div>
      </div>
    );
  };
}

export default Exam;
