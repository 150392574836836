import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import withStyles from '@material-ui/core/styles/withStyles';
import CropSquareIcon from '@material-ui/icons/CropSquare';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Label } from 'recharts';

import visuriLogo from '../../imgs/logo-side.png';
import { calculateGraphOperation } from '../../helpers/formatters';
import { InformationLinePrint } from '../layout';
import { THERAPY_STATISTICS_GRAPH_COLOR, EXAM_STATISTICS_GRAPH_COLOR } from '../../constants';

class StaticsPrint extends React.PureComponent {
  GRAPH_WIDTH = 500;
  GRAPH_HEIGHT = 250;
  GRAPH_MARGINS = { top: 5, left: 5, right: 20, bottom: 55 };

  render() {
    const { classes } = this.props;

    const {
      name,
      firstDate,
      lastDate,
      diagnosticGraph,
      treatmentGraph,
      showTreatment,
      showDiagnostic,
      type,
      numberOfPatients,
      totalPatientsTherapies,
      totalPatientsExams,
      clientName,
    } = this.props.data;

    let totalNumberOfTherapies = 0;
    let totalNumberOfExams = 0;
    for (let j = 0; j < treatmentGraph.length; j++) {
      if (treatmentGraph[j].therapies > 0) totalNumberOfTherapies += treatmentGraph[j].therapies;
    }
    for (let j = 0; j < diagnosticGraph.length; j++) {
      if (diagnosticGraph[j].exams > 0) totalNumberOfExams += diagnosticGraph[j].exams;
    }

    const PageHeader = ({ style, index = null }) => {
      return (
        <div style={{ width: '100%', ...style }}>
          <div className={classes.headerContainer}>
            <img src={visuriLogo} className={classes.logo} alt="Logo Visuri" />
            <div>
              <p className={classes.rightText}>Estatísticas do {name}</p>
            </div>
          </div>
          <hr className={classes.divider} />
        </div>
      );
    };

    const CustomizedXAxisTick = ({ x, y, payload }) => (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-85)"
          style={{ fontSize: '10px' }}
        >
          {payload.value}
        </text>
      </g>
    );

    return (
      <div className={classes.page}>
        <PageHeader />
        <h3 className={classes.infoHeader}>Dados gerais</h3>
        <div className={classes.infoContainer}>
          <div className={classes.infoColumn}>
            <InformationLinePrint
              leftSide="Inicio da analise:"
              rightSide={moment(firstDate).format('DD/MM/YYYY - HH:mm')}
            />

            <InformationLinePrint
              leftSide="Total de Tratamentos:"
              rightSide={totalNumberOfTherapies}
            />

            <InformationLinePrint
              leftSide="Quantidade de pacientes que realizaram algum tratamento:"
              rightSide={totalPatientsTherapies}
              leftSideFlex={4}
            />

            <InformationLinePrint
              leftSide="Número de série:"
              rightSide={name}
              leftSideFlex={4}
            />

            <InformationLinePrint
              leftSide="Quantidade de pacientes ativos:"
              rightSide={numberOfPatients}
              leftSideFlex={3}
            />
          </div>

          <div className={classes.infoColumn}>
            <InformationLinePrint
              leftSide="Fim da analise:"
              rightSide={moment(lastDate).format('DD/MM/YYYY - HH:mm')}
            />

            <InformationLinePrint
              leftSide="Total de Diagnósticos:"
              rightSide={totalNumberOfExams}
            />

            <InformationLinePrint
              leftSide="Quantidade de pacientes que realizaram algum diagnóstico:"
              rightSide={totalPatientsExams}
              leftSideFlex={4}
            />

            <InformationLinePrint
              leftSide="Cliente:"
              rightSide={clientName}
            />

            <InformationLinePrint
              leftSide="Impresso em:"
              rightSide={moment(Date.now()).format('DD/MM/YYYY - HH:mm')}
            />
          </div>
        </div>
        <div>
          <hr className={classes.divider} />
        </div>

        {showTreatment && (
          <div className={classes.graphs}>
            <div className={classes.centerGraph}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: THERAPY_STATISTICS_GRAPH_COLOR,
                    backgroundColor: THERAPY_STATISTICS_GRAPH_COLOR,
                    marginRight: '16px',
                  }}
                />
                <h3 className={classes.infoHeader}>Número de Tratamentos / {type}</h3>
              </div>
              <LineChart
                data={treatmentGraph}
                width={this.GRAPH_WIDTH}
                height={this.GRAPH_HEIGHT}
                margin={this.GRAPH_MARGINS}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={<CustomizedXAxisTick />}>
                  <Label
                    angle={0}
                    position="insideBottomLeft"
                    style={{ textAnchor: 'middle' }}
                    offset={-10}
                  >
                    {type}
                  </Label>
                </XAxis>
                <YAxis>
                  <Label
                    angle={270}
                    position="insideLeft"
                    style={{ textAnchor: 'middle' }}
                    offset={10}
                  >
                    Número de Tratamentos
                  </Label>
                </YAxis>
                <Line type="monotone" dataKey="therapies" stroke="#82ca9d" strokeWidth={3} />
              </LineChart>
            </div>

            <div className={classes.staticsInfo}>
              <div className={classes.infoHeader}>
                <h5 style={{ marginBottom: '4px' }}>Estatísticas</h5>
              </div>
              <div className={classes.infoLine}>
                <p className={classes.infoText}>Valor máximo:</p>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  {Number(calculateGraphOperation(treatmentGraph, 'max', 'therapies')).toFixed(0)}{' '}
                  tratamentos
                </p>
              </div>
              <div className={classes.infoLine}>
                <p className={classes.infoText}>Valor mínimo:</p>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  {Number(calculateGraphOperation(treatmentGraph, 'min', 'therapies')).toFixed(0)}{' '}
                  tratamentos
                </p>
              </div>
              <div className={classes.infoLine} style={{ marginBottom: '8px' }}>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  Valor médio:
                </p>
                <p className={classes.infoText} style={{ flex: 4 }}>
                  {calculateGraphOperation(treatmentGraph, 'average', 'therapies')} tratamentos/
                  {type}
                </p>
              </div>
            </div>
          </div>
        )}

        {showDiagnostic && (
          <div className={classes.graphs}>
            <div className={classes.centerGraph}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CropSquareIcon
                  style={{
                    fontSize: 10,
                    color: EXAM_STATISTICS_GRAPH_COLOR,
                    backgroundColor: EXAM_STATISTICS_GRAPH_COLOR,
                    marginRight: '16px',
                  }}
                />
                <h3 className={classes.infoHeader}>Número de Diagnósticos / {type}</h3>
              </div>

              <LineChart
                data={diagnosticGraph}
                width={this.GRAPH_WIDTH}
                height={this.GRAPH_HEIGHT}
                margin={this.GRAPH_MARGINS}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={<CustomizedXAxisTick />}>
                  <Label
                    angle={0}
                    position="insideBottomLeft"
                    style={{ textAnchor: 'middle' }}
                    offset={-10}
                  >
                    {type}
                  </Label>
                </XAxis>
                <YAxis>
                  <Label
                    angle={270}
                    position="insideLeft"
                    style={{ textAnchor: 'middle' }}
                    offset={10}
                  >
                    Número de Diagnósticos
                  </Label>
                </YAxis>
                <Line type="monotone" dataKey="exams" stroke="#8884d8" strokeWidth={3} />
              </LineChart>
            </div>

            <div className={classes.staticsInfo}>
              <div className={classes.infoHeader}>
                <h5 style={{ marginBottom: '4px' }}>Estatísticas</h5>
              </div>
              <div className={classes.infoLine}>
                <p className={classes.infoText}>Valor máximo:</p>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  {Number(calculateGraphOperation(treatmentGraph, 'max', 'exams')).toFixed(0)}{' '}
                  diagnósticos
                </p>
              </div>
              <div className={classes.infoLine}>
                <p className={classes.infoText}>Valor mínimo:</p>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  {Number(calculateGraphOperation(treatmentGraph, 'min', 'exams')).toFixed(0)}{' '}
                  diagnósticos
                </p>
              </div>
              <div className={classes.infoLine} style={{ marginBottom: '8px' }}>
                <p className={classes.infoText} style={{ flex: 1 }}>
                  Valor médio:
                </p>
                <p className={classes.infoText} style={{ flex: 4 }}>
                  {calculateGraphOperation(treatmentGraph, 'average', 'exams')} diagnósticos/{type}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    maxHeight: '75px',
    maxWidth: '200px',
  },
  rightText: {
    margin: '4px 0px',
    flex: 1,
    textAlign: 'right',
  },
  page: {
    margin: 40,
    marginTop: 20,
    fontSize: 'small',
    fontFamily: `Verdana, sans-serif`,
    pageBreakBefore: 'auto',
  },
  centerGraph: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoLine: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    margin: '0px',
    borderBottom: '1px solid #AAAAAA77',
    '&:last-child': {
      borderBottom: '0px',
    },
  },
  qtdInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  infoHeader: {
    textAlign: 'center',
  },
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  graphs: {
    // display: 'flex',
    // padding: '15px 0 0 0',
    // margin: '0 10px 10px 0',
    // flexDirection: 'column',
    // justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
  },
  divider: {
    width: '100%',
    marginLeft: -5,
    height: 5,
    border: 0,
    borderTop: '1px solid #000',
  },
  staticsInfo: {
    flex: 2,
  },
  infoText: {
    margin: '4px 0px',
    flex: 1,
    '&:last-child': {
      textAlign: 'right',
    },
  },
});

StaticsPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StaticsPrint);
